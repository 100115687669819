<template>
    <div class="auth">
        <h1 style = "margin-top: 10%"> Welcome to Comm Tool! </h1>
        <template v-if="!user">
            <b-button class="btn-xlarge" variant="primary" @click.prevent="signInWithGoogle" style = "margin-top: 5%">Communicator Sign Up / Sign In with UMN Account</b-button>
        </template>

        <template v-if="user">
            <!--      <img :src="user.photoURL" alt="avatar" style="width: 30px; height: 30px; border-radius: 50%;">-->
            <a href @click.prevent="signOut">Sign Out</a>
        </template>

        <!-- <pre>{{ user }}</pre> -->
    </div>
</template>

<style scoped>
    .btn-xlarge {

        padding: 18px;
        font-size: 20px;
        line-height: normal;

    }
</style>

<script>
    import { auth, provider } from "@/firebaseConfig";
    import {db} from "../firebaseConfig.js"
    export default {
        components: {},
        name: "CommAuth",
        props: ['redirect'],
        data() {
            return {
                user: null
            };
        },
        beforeCreate: function() {
            auth.onAuthStateChanged(user => {
                // console.log("user state:", user);
                // uncomment above to check out which user properties are available.
                if (user) {
                    this.user = user;
                    var collection = db.collection('users');
                    var docID = this.user.uid;
                    collection.doc(docID).get().then((document) => {
                        if(document.exists){
                            if (!document.data().communicator_checked) this.$router.push("/thank_login")
                            else {
                                if (this.redirect !== undefined) this.$router.push(this.redirect);
                                else this.$router.push("/dashboard")
                            }
                        }else{
                            this.$router.push("/communicator_register");
                            // this.$router.go();
                        }
                    })
                        .catch((error) => {
                            console.error("Error writing document: ", error);
                        });
                    // db.collection("users").doc(this.user.uid).set({
                    //     displayName: this.user.displayName,
                    //     email: this.user.email
                    // })
                    // .then(() => {
                    //     console.log("Document successfully written!");
                    // })
                    // .catch((error) => {
                    //     console.error("Error writing document: ", error);
                    // });
                    // this.$router.push("/edit");
                    // this.$router.go();
                }
            });
        },
        methods: {
            signInWithGoogle: function() {
                auth.signInWithPopup(provider)
                    .then(result => {
                        this.user = result.user;
                    })
                    .catch(err => console.log(err));

            },

            //not used
            signOut: function() {
                auth.signOut()
                    .then(() => {
                        this.user = null;
                        //this. $router. push('/')
                    })
                    .catch(err => console.log(err));
            }
        }
    };
</script>