<template>
    <div ref="all">

        <div class="row" >
            <b-col sm="2" >
            </b-col>
            <b-col sm="8">
                <h1 style="margin-top: 30px">  {{this.email}} </h1>
            </b-col>
        </div>
        <b-row  >
            <b-col lg="5"  ></b-col>
            <b-col lg="2" class="align-text-bottom" style="margin-bottom: 20px; margin-top: 5px" v-if="generating_report !== 'downloading'">
                <b-link @click="share_report"><font-awesome-icon icon="fa-solid fa-share"/> Share this Page</b-link>
            </b-col>
            <b-col lg="5" v-if="this.share">Share this link: <a :href="'https://www.comm-tool.com/report_share/'+postId" target="_blank">https://www.comm-tool.com/report_share/{{this.postId}}</a><b-button @click="copy" style="margin-left: 5px" size="sm" variant="outline-primary">copy</b-button></b-col>
        </b-row>

        <div v-if="generating_report === 'begin'">
            <h4>Notes</h4>
            <div style="max-width: 70%;    display: table;margin-right: auto; margin-left: auto;">
                <editor v-model="share_text"
                        api-key="6amjl4rlxjw7zvlf91tg3iz4m9ntrvj0tk1543pxopouuq9k"
                        placeholder = "Please input any notes you want to share"
                        :init="{
                             menubar: false,
                             content_style: 'img {max-width: 600px; }' + 'div {max-width: 600px}'+ 'td {max-width: 600px}'+ 'tr {max-width: 600px}'+ 'tbody {max-width: 600px}' +  'table {max-width: 600px}',
                             plugins: [
                                 // 'autoresize',
                               'advlist autolink lists link image charmap print preview anchor',
                               'searchreplace visualblocks code fullscreen textcolor',
                               'insertdatetime media table paste code help wordcount'
                             ],
                             toolbar:
                               'undo redo | formatselect | bold italic forecolor backcolor | \
                               alignleft aligncenter alignright alignjustify | \
                               bullist numlist outdent indent | removeformat | help',
                               // autoresize_on_init: true,
                           }"
                />
            </div>
            <div>
                <b-button variant="outline-secondary" style="margin: 10px" @click="close">Close</b-button>
                <b-button variant="primary" @click="download">Share</b-button>
            </div>
        </div>

        <div class="row" >


            <b-col sm="12" v-if="!this.user || (this.html_list.length===0)" style="max-width: 700px; margin-top: 40px; margin-right: auto; margin-left: auto">
                Loading ...
            </b-col>
            <b-col sm="12" v-if="this.user && (this.html_list.length>0)" >

                <li v-for="(item, index) in this.html_list" v-bind:key= index style="max-width: 700px; margin-bottom: 40px; margin-right: auto; margin-left: auto" ref="html_list" >
                    <div v-if = item.data.data[2]>
                        <div class="observable"  >
                            <div class="maximg" v-html="item.data.data[0]" style=" text-align: left; max-width: 700px" ></div>

                        </div>
                        <div class="row" v-if = "item.data.data[1]">
                            <b-col sm="2" style="text-align: left; padding-right: 0">
                                {{item['clicked']}}/{{implicit}}&nbsp; click
                                <span v-b-tooltip.hover.righttop="'The number the implicit participants who at least clicked one link in this message / the number of implicit participants.'">

                                <font-awesome-icon
                                        icon="fa-solid fa-question-circle"/>
                            </span>
                            </b-col>
                            <b-col sm="3" style="text-align: left; padding-right: 0">
                                {{item.all_relevant}}/{{explicit}}&nbsp; relevant
                                <span v-b-tooltip.hover.righttop="'The number of the explicit participants who indicated that this message is relevant to them / the number of explicit participants.'">

                                <font-awesome-icon
                                        icon="fa-solid fa-question-circle"/>
                            </span>
                            </b-col>
                            <b-col sm="2" style="text-align: left; padding-right: 0">
                                {{parseInt(item.time)}}s
                                <span v-b-tooltip.hover.righttop="'The average number of seconds the implicit participants who opened this email spent on reading this message.'">

                                <font-awesome-icon
                                        icon="fa-solid fa-question-circle"/>
                            </span>
                            </b-col>
                            <b-col sm="2" style="text-align: left; padding-right: 0">
                                ${{parseInt(item.cost)}}
                                <span v-b-tooltip.hover.righttop="'The estimated money cost of this message, defined as the average reading time * open rate * $40 hour rate * the actual number of recipients of this newsletter.'">
                                <font-awesome-icon
                                        icon="fa-solid fa-question-circle"/>
                            </span>
                            </b-col>
                            <b-col sm="3" style="text-align: left; padding-right: 0">
                                <span v-if="!item.show_interest"  style="margin-right: auto; margin-left: auto" @click="click_interest(index)">		&#9651; <b-link>who are interested </b-link></span>
                                <span v-if="item.show_interest" style="margin-right: auto; margin-left: auto" @click="click_interest(index)">		&#9661;<b-link>who are interested </b-link></span>
                                <span v-b-tooltip.hover.righttop="'Show the categories of implicit/explicit recipients that are interested in a message --- clicked / read / indicated relevant.'">

                                <font-awesome-icon
                                        icon="fa-solid fa-question-circle"/>
                            </span>
                            </b-col>


                        </div>


                    </div>

                    <div v-if = "item.data.data[1]">
                        <div v-if="item.show_interest">
                            <b-form-group
                                    label="Filter Type / Category"
                                    label-for="filter-input"
                                    label-cols-sm="3"
                                    label-align-sm="center"
                                    label-size="sm"
                                    class="mb-0"
                            >
                                <b-input-group size="sm">
                                    <b-form-input
                                            id="filter-input"
                                            v-model="Testfilter"
                                            type="search"
                                            placeholder="Type Any Key Words"
                                    ></b-form-input>
                                </b-input-group>
                            </b-form-group>
                            <div class="row" >
                                <b-table striped hover small :items="item.detail" :fields="fields" :sticky-header="stickyHeader" :sort-by.sync="sorttestBy"
                                         :sort-desc.sync="sorttestDesc"                  :filter="Testfilter"
                                         :filter-included-fields="TestfilterOn" @filtered="onTestFiltered">
                                    <template #head(type)="data">
                                    <span v-b-tooltip.hover.righttop="'Unit / Job Category.'">
                                        {{ data.label}}
                                        <font-awesome-icon
                                                icon="fa-solid fa-question-circle"/>
                                    </span>
                                    </template>
                                    <template #head(category)="data">
                                    <span v-b-tooltip.hover.righttop="'The unit or job category the recipients belong to.'">
                                        {{ data.label}}
                                        <font-awesome-icon
                                                icon="fa-solid fa-question-circle"/>
                                    </span>
                                    </template>
                                    <template #head(n_interested)="data">
                                    <span v-b-tooltip.hover.righttop="'The number of recipients in that category interested in this message (read / clicked / indicated relevant).'">
                                        {{ data.label}}
                                        <font-awesome-icon
                                                icon="fa-solid fa-question-circle"/>
                                    </span>
                                    </template>
                                    <template #head(per_interested)="data">
                                    <span v-b-tooltip.hover.righttop="'The percentage of recipients in that category interested in this message (read / clicked / indicated relevant).'">
                                        {{ data.label}}
                                        <font-awesome-icon
                                                icon="fa-solid fa-question-circle"/>
                                    </span>
                                    </template>
                                    <template #head(n_people)="data">
                                    <span v-b-tooltip.hover.righttop="'The number of recipients in that category.'">
                                        {{ data.label}}
                                        <font-awesome-icon
                                                icon="fa-solid fa-question-circle"/>
                                    </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>
                        <div v-for="(it, ind) in item.all_comments" v-bind:key= "'A'+ind" >

                            <Comment :comment=it v-if="item.show_comment||(it.sender===true)"/>
                        </div>
                        <div style="    border: 1px;
                                     border-color: #dbd5d5;
                                     border-style: dashed;">
                            <div class="row" @click="click_comment(index)">
                                <b-col sm="12" >
                                    <font-awesome-icon icon="fa-solid fa-comment-dots" style="margin-right: 5px" />{{item.all_comments.length}}
                                    <span v-if="!item.show_comment"  style="margin-right: auto; margin-left: auto">		&#9661; show all comments</span>
                                    <span v-if="item.show_comment" style="margin-right: auto; margin-left: auto">		&#9651;</span>
                                </b-col>
                            </div>
                        </div>
                        <div class="row">
                            <b-col sm="12" >
                                <b-form-input placeholder="Type to reply " v-on:change="event=>comment_tmp=event" ></b-form-input>
                            </b-col>
                        </div>
                        <div class="row">
                            <b-col sm="12" style="text-align:right">
                                <b-button  style="margin-top: 5px" @click="submit_comment(index)" v-b-tooltip.hover.righttop="'Click here to comment. Your comment will be shown anonymously as [from sender].'">Comment</b-button>
                            </b-col>
                        </div>
                    </div>
                </li>


            </b-col>
        </div>
        <div class="row" >
            <b-col sm="2" >
            </b-col>
            <b-col sm="8" >
                <h3 style="position: center">{{submit_msg}}</h3>
                <div class="row" style="margin-top:20px;" v-if="submit_msg==='Please take this survey each time:'">
                    <form @submit.prevent="handleSubmit">
                        <label>Q1. Which of these metrics we show on this page help you make future editing / sending decisions? <span style="color: red"><strong>* </strong></span></label>
                        <b-form-checkbox-group
                                id="metrics"
                                v-model="metrics"
                                name="radio-options3"
                                :state="state"
                        >

                            <b-form-checkbox
                                    v-for="metric_option in metric_options"
                                    :value="metric_option"
                                    :key="metric_option"
                            >
                                {{ metric_option }}
                            </b-form-checkbox>
                            <b-form-invalid-feedback :state="state">Please select at least one option.</b-form-invalid-feedback>
                        </b-form-checkbox-group>

                        <label style="margin-top: 20px">Q2. Which editing/sending decisions you have made / will make based on this report? <span style="color: red"><strong>* </strong></span></label>
                        <b-form-checkbox-group
                                id="decision"
                                v-model="decisions"
                                name="radio-options3"
                                :state="decision_state"
                        >

                            <b-form-checkbox
                                    v-for="decision_option in decision_options"
                                    :value="decision_option"
                                    :key="decision_option"
                            >
                                {{ decision_option }}
                            </b-form-checkbox>
                            <b-form-invalid-feedback :state="decision_state">Please select at least one option.</b-form-invalid-feedback>
                        </b-form-checkbox-group>

                        <label style="margin-top: 20px">Q3. Why certain metrics are helpful / unhelpful to you? Could you give an example of the editing / sending decisions you made based on this report?</label>
                        <b-form-textarea
                                id="textarea"
                                v-model="feedback"
                                placeholder="Enter here..."
                                rows="3"
                                max-rows="6"
                        ></b-form-textarea>

                        <div class="button">
                            <button class="submit" type="submit">submit</button>
                        </div>
                    </form>
                </div>
            </b-col>
        </div>

    </div>
</template>

<script>
    import '../style/edit.css';
    import Comment from "../components/Comment";
    // import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    // import { db } from "../firebaseConfig.js"
    import Editor from '@tinymce/tinymce-vue'
    // import RangeSlider from 'vue-range-slider'
    // you probably need to import built-in style
    import 'vue-range-slider/dist/vue-range-slider.css'
    import { auth } from "@/firebaseConfig"
    import {db} from "../firebaseConfig";
    export default {
        name: "ReportAdmin",
        components: {
            Comment,
            editor: Editor,
            // "font-awesome-icon": FontAwesomeIcon
        },
        props: ['postId', 'userId'],
        created () {
            document.title = "Comm Tool: Report";
            var collection = db.collection('users');
            var docID = this.$options.propsData.userId;
            var opened_users = new Set();
            collection.doc(docID).get().then((document) => {
                this.user = document.data();
                this.show_survey = true;
                this.html_list = [];
                var size = 0;
                var audience = "";
                db.collection('post').doc(this.postId).get().then((document) => {

                    if (document.exists) {
                        size = document.data()['n_recipient'];
                        audience = document.data().audience;
                        this.email = document.data()['subject'];
                        this.share_text = document.data()["share_text"];
                        if (this.share_text === undefined) this.share_text = "";
                    }else{
                        return;
                    }


                    db.collection("users").where("recipient_checked", "==", true)
                        .where("demo","==",(this.demo || this.$store.state.demo)).get().then(querySnapshot => {
                        this.users = {}
                        var implicit = 0;
                        this.explicit = 0;

                        this.messages = [];
                        querySnapshot.docs.map((doc) => {
                            // console.log('LOG 1', doc.data());
                            var userId = doc.data().userId;
                            if (!audience.includes(doc.data().category)) return;
                            this.users[userId] = {"group":doc.data().group, "unit":doc.data().unit, "job":doc.data().job_family};
                            this.add_one(this.job_count, this.users[userId]["job"]);
                            this.add_one(this.unit_count, this.users[userId]["unit"]);
                            if (doc.data().group === "implicit") implicit += 1;
                            else this.explicit += 1;
                        });
                        this.implicit = implicit;

                        db.collection("messages").where("postId", "==", this.$options.propsData.postId).orderBy("index").get().then((querySnapshot) => {
                            querySnapshot.forEach((doc) => {
                                var tmp = doc.data();
                                tmp.postId = doc.id;
                                tmp.all_comments = [];
                                tmp.comments = [];
                                tmp.all_relevant = 0;
                                tmp.relevant = 0;
                                tmp.clicked = 0
                                tmp.time = 0;
                                tmp.cost = 0;
                                tmp.explicit = this.explicit;
                                tmp.implicit = implicit;
                                tmp.read_rate = 0;
                                tmp.detail_rate = 0;
                                tmp.job_interest = {};
                                tmp.unit_interest = {};
                                Object.keys(this.job_count).forEach((k)=>{
                                    this.add_key(tmp.job_interest, k);
                                })
                                Object.keys(this.unit_count).forEach((k)=>{
                                    this.add_key(tmp.unit_interest, k);
                                })


                                this.html_list.push(tmp);
                            });
                            this.html_list.sort((a, b) => (a.index > b.index) ? 1 : -1);

                            db.collection("read_message").where("postId", "==", this.postId).get().then((querySnapshot) => {
                                querySnapshot.forEach((doc) => {
                                    var tmp = doc.data();

                                    var interest_flag = 0;
                                    tmp.comments.forEach((comment) => {
                                        this.html_list[tmp.index].all_comments.push(comment);
                                        if (tmp.userId === this.userId) {
                                            this.html_list[tmp.index].comments.push(comment);
                                        }
                                    });
                                    if (this.users[tmp.userId] !== undefined) {
                                        this.html_list[tmp.index].all_relevant += tmp.relevant;
                                        if (this.users[tmp.userId].group === "implicit") {
                                            this.html_list[tmp.index].clicked += tmp.clicked;
                                            opened_users.add(tmp.userId)
                                            if (isNaN(tmp.time)) tmp.time = 0;
                                            this.html_list[tmp.index].time += tmp.time;
                                            if (tmp.read_level !== undefined) {
                                                this.html_list[tmp.index]['read rate'] += tmp.read_level > 0 ? 1 : 0;
                                                this.html_list[tmp.index]['detail rate'] += tmp.read_level > 1 ? 1 : 0;
                                                if (tmp.read_level > 0) interest_flag = 1;
                                            }
                                        }
                                        if (tmp.clicked > 0) interest_flag = 1;
                                        if (tmp.relevant > 0) interest_flag = 1;
                                        if (interest_flag === 1) {
                                            this.add_one(this.html_list[tmp.index].job_interest, this.users[tmp.userId].job);
                                            this.add_one(this.html_list[tmp.index].unit_interest, this.users[tmp.userId].unit);
                                        }
                                        // else{
                                        //     this.add_key(this.html_list[tmp.index].job_interest, this.users[tmp.userId].job);
                                        //     this.add_key(this.html_list[tmp.index].unit_interest, this.users[tmp.userId].unit);
                                        // }
                                        this.html_list[tmp.index].n_comments = this.html_list[tmp.index].all_comments.length;
                                    }

                                });

                                this.html_list.forEach((element)=>{
                                    element.all_comments = element.all_comments.sort(function(a, b) {
                                        return  new Date(a.time)- new Date(b.time);
                                    });
                                })

                                this.html_list.forEach((value, index) => {
                                    if (opened_users.size > 0) this.html_list[index].time = this.html_list[index].time / opened_users.size;
                                    if ((opened_users.size > 0)&& (this.html_list[index].time>0.5)) this.html_list[index].cost = 40 / 60 / 60 * this.html_list[index].time * size * opened_users.size / implicit;
                                    this.html_list[index].time = Math.round(this.html_list[index].time);
                                    this.html_list[index].show_comment = false;
                                    this.html_list[index].show_interest = false;
                                })


                            });
                        })
                    })
                })
            })
        },
        mounted() {
            window.setInterval(() => {
            }, 100);
            window.setInterval(() => {
                this.updateTime();
            }, 1000);

        },
        beforeCreate: function() {
            auth.onAuthStateChanged(user => {
                console.log("user state:", user);
                // uncomment above to check out which user properties are available.
                if (!user) {
                    //this.$router.push("/"); this have already been done in router
                }else{
                    this.user = user;
                    var collection = db.collection('users');
                    var docID = this.user.uid;
                    collection.doc(docID).get().then((document) => {
                        if(document.exists){
                            if (!document.data().communicator_checked) this.$router.push("/thank_login")

                            // this.$router.go();
                        }else{
                            this.$router.push("/register");
                            // this.$router.go();
                        }
                    })

                }
            });

        },
        computed: {
            state() {
                return this.metrics.length>=1
            },
            decision_state(){
                return this.decisions.length>=1
            }
        },
        data:  function(){
            return {
                fields: [
                    { key: 'type', class: 'text-center', sortable:true},
                    { key: 'category', class: 'text-center',thStyle: { width: "40%" } , sortable:true},
                    { key: "n_interested", class: 'text-center', label: "# interested", sortable:true},
                    { key: "per_interested", class: 'text-center', label: "% interested" , sortable:true},
                    { key: 'n_people', class: 'text-center', label: "# people", sortable:true},
                ],
                comment_tmp: "",
                index:0,
                componentKey: 0,
                html_list: [],
                user: null,
                users: {},
                job_count: {},
                unit_count: {},
                time: 0,
                email: "",
                share: false,
                docid: "",
                metric_options: [ "Click Rate", "Relevance Rate", "Comments", "Reading Time", "Est Cost",  "Who are interested?", "None of those are helpful."],
                decision_options: ["Send to more people", "Send to fewer people", "Share this report with my clients", "Add more contents", "Remove or shorten some contents", "Move some contents to other newsletter", "None of those"],
                feedback: '',
                metrics: [],
                decisions: [],
                submit_msg: "Please take this survey each time:",
                generating_report: "",
                share_text: "",
                sorttestBy: 'per_interested',
                sorttestDesc: true,
                Testfilter: '',
                TestfilterOn: [],
                currenttestPage: 1,
                stickyHeader: true,
            }
        },

        firestore: function () {
            return {
                // post: db.collection("post").doc(this.postId)
            }
        },

        methods: {
            onTestFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                // if (filteredItems.length>0 && filteredItems[0].postId !== ''){
                //     if (this.messages.length>0){
                //         filteredItems.unshift(this.messages[0]);
                //         this.$emit('filtered', filteredItems)
                //     }
                // }

                this.totaltestRows = filteredItems.length

                this.currenttestPage = 1
            },
            add_one(d, key){
                if (d[key] === undefined) d[key] = 1;
                else d[key] += 1;
            },
            add_key(d, key){
                if (d[key] === undefined) d[key] = 0;
            },
            close () {
                this.generating_report = '';
            },
            download () {
                this.generateReport()
            },
            handleSubmit() {
                if (this.decisions.length === 0 || this.metrics.length === 0) return;
                var data = [];
                this.html_list.forEach((row)=>{
                        var tmp = {};
                        Object.keys(row).forEach(function(key) {
                            if (key!=="data" && key!=='all_comments' && key!=='comments'){
                                tmp[key] = row[key]
                            }
                        });
                        data.push(tmp);
                    }
                )
            },
            updateTime(){
                this.time += 1;
                if (this.time === 30){
                    var data = [];
                    this.html_list.forEach((row)=>{
                            var tmp = {};
                            Object.keys(row).forEach(function(key) {
                                if (key!=="data"&&key!=='all_comments' && key!=='comments'){
                                    tmp[key] = row[key]
                                }
                            });
                            data.push(tmp);
                        }
                    )

                }
            },
            async copy(){
                try {
                    navigator.clipboard.writeText('https://www.comm-tool.com/report_share/'+this.postId).then(()=>{
                        this.share = true;
                        this.generating_report = '';
                    });
                } catch($e) {
                    alert('Cannot copy');
                }
            },
            async generateReport () {
                    try {
                        navigator.clipboard.writeText('https://www.comm-tool.com/report_share/'+this.postId).then(()=>{
                            this.share = true;
                            this.generating_report = '';
                        });
                    } catch($e) {
                        alert('Cannot copy');
                    }

                var data = [];
                this.html_list.forEach((row)=>{
                        var tmp = {};
                        Object.keys(row).forEach(function(key) {
                            if (key!=="data"&&key!=='all_comments' && key!=='comments'){
                                tmp[key] = row[key]
                            }
                        });
                        data.push(tmp);
                    }
                )


            },
            update_read_post(id, field, value){
                var docRef = db.collection("read_post").doc(id);
                docRef.get().then((doc) => {
                    if (doc.exists) {
                        db.collection("read_post").doc(id).update({[field]: value});
                    } else {
                        db.collection("read_post").doc(id).set({
                            userId: this.userId,
                            postId: this.postId,
                            comments: 0,
                            relevant: 0,
                            clicked: 0,
                            time: 0,
                            timestamp: new Date()
                        }).then(
                            ()=>{db.collection("read_post").doc(id).update({[field]: value});}
                        )
                    }
                }).catch((error) => {
                    console.log("Error getting document:", error);
                });
            },
            update_read_message(id, field, value, index){
                var docRef = db.collection("read_message").doc(id);
                docRef.get().then((doc) => {
                    if (doc.exists) {
                        db.collection("read_message").doc(id).update({[field]: value});
                    } else {
                        db.collection("read_message").doc(id).set({
                            userId: this.userId,
                            postId: this.postId,
                            comments: [],
                            relevant: 0,
                            clicked: 0,
                            time: 0,
                            index: index,
                            timestamp: new Date()
                        }).then(
                            ()=>{db.collection("read_message").doc(id).update({[field]: value});}
                        )
                    }
                }).catch((error) => {
                    console.log("Error getting document:", error);
                });
            },

            submit_comment: function(index){
                if (this.comment_tmp.length>0) {
                    this.html_list[index].comments.push({text:this.comment_tmp, time: new Date().toLocaleString(), userId: this.userId, sender: true});
                    this.html_list[index].all_comments.push({text:this.comment_tmp, time:new Date().toLocaleString(), userId: this.userId, sender: true});
                    this.html_list[index].show_comment = true;
                    this.comment_tmp = "";
                    this.$nextTick(() => {
                        this.html_list = [...this.html_list];
                    });
                }
            },

            click_comment: function(index){
                this.html_list[index].show_comment = !this.html_list[index].show_comment;
                if (this.html_list[index].show_comment) this.html_list[index].show_bug = false;
                this.$nextTick(() => {
                    this.html_list = [...this.html_list];
                });
            },
            click_interest: function(index) {
                this.html_list[index].show_interest = !this.html_list[index].show_interest;
                if (this.html_list[index].show_interest && (this.html_list[index].detail === undefined || this.html_list[index].detail.length === 0)){
                    this.html_list[index].detail = [];
                    Object.keys(this.html_list[index].job_interest).forEach((k)=>{
                        this.html_list[index].detail.push({"type": "job", "category": k,
                            "n_interested": this.html_list[index].job_interest[k],
                            "per_interested": Math.round(this.html_list[index].job_interest[k]/this.job_count[k]*100).toString() + "%",
                            "n_people": this.job_count[k]})
                    })
                    Object.keys(this.html_list[index].unit_interest).forEach((k)=>{
                        this.html_list[index].detail.push({"type": "unit", "category": k,
                            "n_interested": this.html_list[index].unit_interest[k],
                            "per_interested": Math.round(this.html_list[index].unit_interest[k]/this.unit_count[k]*100).toString() + "%",
                            "n_people": this.unit_count[k]})
                    })
                }else if (!this.html_list[index].show_interest){
                    this.html_list[index].detail = [];
                }
                this.$nextTick(() => {
                    this.html_list = [...this.html_list];
                });
            },
            share_report(){
                this.generating_report = 'begin';
            },

        }
    }
</script>

<style scoped>
    .maximg ::v-deep img { max-width: 700px; }
</style>