import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)


const store = new Vuex.Store({
    state: {
        idleVue: {isIdle: false},
        demo: false
    },

});

export default store;