<template>
    <div
            class="overlay"
    >
        <div class="modal">
            <div class="modal__title">
                <span>Session Expired</span>
            </div>
            <div class="p-3">
                <p>You have left this browser idle for 3 minutes.</p>
                <p>{{ time }} second left</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                time: 60*3*1000
            }
        },
        created() {
            let timerId = setInterval(() => {
                this.time -= 1000;
                if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);
                if (this.time < 1) {
                    clearInterval(timerId);
                    // Your logout function should be over here
                    this.$router.push("/logout");
                }
            }, 1000);
        }
    };
</script>