<template>
    <div id="app">
        <img :src="'https://storage.googleapis.com/commtool-6d611.appspot.com/image/edit_example.png'"/>
    </div>
    
</template>

<script>
    export default {
        name: "EditExample"
    }
</script>

<style scoped>

</style>