<template>
    <div style="    border: 1px;
         border-color: #dbd5d5;
         border-style: dashed;">
    <div class="row" >
<!--        <b-col sm="1" >-->

<!--        </b-col>-->
        <b-col sm="12" style="text-align: left; margin-left: 10px">
            <span v-if="this.comment.sender === true " ><b>From sender:   </b> </span> {{this.comment.text}}
        </b-col>
    </div>
    <div class="row" >
        <b-col sm="1" >
        </b-col>
        <b-col sm="4" style="text-align: left">
<!--            {{this.comment[1]}}-->
        </b-col>
        <b-col sm="6" style="text-align: right">
            {{this.comment.time}}
        </b-col>
    </div>
    </div>

</template>

<script>
    // import '../style/edit.css';
    // import Editor from '@tinymce/tinymce-vue';
    export default {
        name: "Comment",
        components: {
            // editor: Editor,
            // "font-awesome-icon": FontAwesomeIcon
        },
        created() {
            // props are exposed on `this`
            // console.log(this.comment)
        },
        props: ['comment']
    }
</script>

<style scoped>

</style>