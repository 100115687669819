<template>
    <div class="row" >
        <b-col sm="2" >
        </b-col>
        <b-col sm="8" v-if=" (this.html_list.length===0)" >
            Loading ...
        </b-col>
        <b-col sm="8" v-if="(this.html_list.length>0)" >

            <li v-for="(item, index) in this.html_list" v-bind:key= index style="max-width: 700px; margin-top: 40px" ref="html_list" >
                <div v-if = item.data.data[2]>
                    <div class="observable" >
                        <editor
                                v-model = "item.data.data[0]"
                                api-key="6amjl4rlxjw7zvlf91tg3iz4m9ntrvj0tk1543pxopouuq9k"
                                branding = false
                                :init="{
                                        init_instance_callback: ed => {
                                            ed.on('click', function (e) {
                                              click_link(index, ed, e);
                                            });
                                            ed.on('tap', function (e) {
                                              click_tap(index, ed);
                                            });

                                         },
                                         menubar: false,
                                         plugins: ['autoresize'
                                         ],
                                         content_style: '.mce-content-body audio[data-mce-selected], .mce-content-body embed[data-mce-selected], .mce-content-body img[data-mce-selected], .mce-content-body object[data-mce-selected], .mce-content-body table[data-mce-selected], .mce-content-body video[data-mce-selected]{\n'+
                                            '    outline: none;\n'+
                                            '    outline-width: 0px;\n'+
                                            '}',
                                         toolbar:
                                           '',
                                         content_css: '../style/editor.css',
                                         autoresize_on_init: true,
                                         visual: false,
                                         autoresize_bottom_margin: 0,
                                         branding: false,
                                         statusbar: false,
                                       }"
                        />
                    </div>


                </div>
            </li>



        </b-col>
        <b-col sm="2" >

            <div class="sticky">
                <p style="color: #aaaaaa">{{this.timestamp}}</p>
            </div>
        </b-col>
    </div>

</template>

<script>
    import '../style/edit.css';
    // import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import dateFormat from "dateformat/lib/dateformat"
    // import { db } from "../firebaseConfig.js"
    import Editor from '@tinymce/tinymce-vue'
    // import RangeSlider from 'vue-range-slider'
    // you probably need to import built-in style
    import 'vue-range-slider/dist/vue-range-slider.css'
    import {db} from "../firebaseConfig";
    import  * as tf from '@tensorflow/tfjs';
    export default {
        name: "EyeTrackingTestView",
        components: {
            editor: Editor,
            // "font-awesome-icon": FontAwesomeIcon
        },
        props: ['postId', 'userId'],
        async created() {
            document.title = "Comm Tool: EyeTrackingTestView";
            this.second_model = await tf.loadLayersModel('https://storage.googleapis.com/commtool-6d611.appspot.com/json/second-model.json');
            // for (let i = 0; i < this.second_model.getWeights().length; i++) {
            //     console.log(this.second_model.getWeights()[i].dataSync());
            // }
            this.second_model = await tf.loadLayersModel('https://storage.googleapis.com/commtool-6d611.appspot.com/json/session-model.json');
            this.html_list = [];
            db.collection("messages").where("postId", "==", this.$options.propsData.postId).orderBy("index").get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    var tmp = doc.data();
                    tmp.postId = doc.id;
                    tmp.all_comments = [];
                    tmp.comments = [];
                    tmp.all_relevant = 0;
                    tmp.relevant = 0;
                    tmp.clicked = 0
                    tmp.time = 0;
                    tmp.visibleHeight = {};
                    tmp.MSG_svt = 0;
                    tmp.MSG_sy = 0;
                    tmp.MSG_sh = 0;
                    tmp.MSG_tmy = 0;
                    tmp.MSG_tsclk = 0;
                    tmp.last_click = 0;
                    tmp.MSG_y = -1;
                    tmp.MSG_h = 0;

                    this.html_list.push(tmp);
                });
                this.html_list.sort((a, b) => (a.index > b.index) ? 1 : -1);

            })

        },
        mounted() {
            window.setInterval(() => {
                this.updateVis();
            }, 100);
            window.setInterval(() => {
                this.updateTime();
            }, 1000);

        },
        beforeCreate: function() {
            document.addEventListener("visibilitychange", () => {

                this.isVisible = document.visibilityState === "visible";
            });
            document.addEventListener('mousemove', (e)=>this.onMouseUpdate(e), false);


        },
        data:  function(){
            return {
                comment_tmp: "",
                index:0,
                mouseY: 0,
                mouseX: 0,
                mousePageX: 0,
                mousePageY: 0,
                isVisible: true,
                componentKey: 0,
                html_list: [],
                show_survey: false,
                time: 0,
                entry: {},
                start_time: new Date(),
                MMF_x: [],
                MMF_y: [],
                mouseXs: [],
                mouseYs: [],
                MMF_x_inf: 0,
                MMF_y_inf: 0,
                MSF_y: [],
                pageYs: [],
                MSF_y_inf: 0,
                timestamp: "",
                user_pattern_cols : ['MMF_y_2',  'MMF_y_5', 'MMF_y_10', 'MMF_y_inf',  'MMF_x_2', 'MMF_x_5', 'MMF_x_10', 'MMF_x_inf',
                    'MSF_y_2', 'MSF_y_5', 'MSF_y_10', 'MSF_y_inf','MSF_clk'],
                user_session_cols : ['MMF_y_inf', 'MMF_x_inf','MSF_y_inf','MSF_clk'],
                user_tmp_cols : ['isVisible', "mouseX", "mouseY",
                    'M_tclk', 'S_cy', 'S_h'],
                msg_tmp_cols : ['MSG_y', "MSG_h", "MSG_tclk"],
                msg_session_cols : ['time_1', 'time_2','time_3','MSG_tmy', 'MSG_sh', 'MSG_sy','MSG_clk','MSG_svt'],
            }
        },

        firestore: function () {
            return {
                // post: db.collection("post").doc(this.postId)
            }
        },

        methods: {

            update_read_post(value){
                value["userId"] = this.userId;
                value["postId"] = this.postId;
                db.collection("eye_tracking_read_post_test").add(value)
                // .then((docRef) => {
                //     console.log("Document written with ID: ", docRef.id);
                // })
                // .catch((error) => {
                //     console.error("Error adding document: ", error);
                // });
            },

            update_click_post(value){
                value["userId"] = this.userId;
                value["postId"] = this.postId;
                db.collection("eye_tracking_click_post_test").add(value)
                // .then((docRef) => {
                //     // console.log("Document written with ID: ", docRef.id);
                // })
                // .catch((error) => {
                //     // console.error("Error adding document: ", error);
                // });
            },

            click: function (e){
                var el = window;//.elementFromPoint(x,y);
                var ev = new MouseEvent('click', {
                    'screenX': e.screenX,
                    'screenY': e.screenY
                });
                el.dispatchEvent(ev);
            },

            click_link: async function (index, ed, e) {
                var now = new Date().getTime();
                function delay(time) {
                    return new Promise(resolve => setTimeout(resolve, time));
                }
                if (now - this.html_list[index].last_click <500) {
                    ed.setMode('readonly');
                    this.html_list[index].last_click = now;

                    this.click(e);
                    return;
                }
                this.html_list[index].last_click = now;
                ed.setMode('readonly');
                console.log("click");

                await delay(50);
                this.isVisible = document.visibilityState === "visible";


                if (!this.isVisible) {
                    if (this.html_list[index].clicked === 0){
                        this.html_list[index].clicked = 1;
                        var value = {
                            timestamp: new Date(), index: index, time_length: now-this.start_time.getTime(),
                            time: this.time,
                            start_time: this.start_time,
                            length: this.html_list.length
                        };
                        this.update_click_post(value);
                        this.html_list[index].MSG_tsclk = now;
                    } else {
                        this.html_list[index].clicked = 0;
                    }
                }else{
                    ed.setMode('design');
                    ed.on('PostRender', function(){
                        ed.getBody().setAttribute('contenteditable', false);
                    });
                    ed.on('KeyPress', function(e){
                        e.preventDefault();
                        e.stopPropagation();
                    });
                }

            },

            click_tap: async function (index, ed) {
                var now = new Date().getTime();
                this.html_list[index].last_click = now;
                ed.setMode('readonly');
                console.log("click");
                function delay(time) {
                    return new Promise(resolve => setTimeout(resolve, time));
                }
                await delay(1000);
                this.isVisible = document.visibilityState === "visible";


                if (!this.isVisible) {
                    if (this.html_list[index].clicked === 0){
                        this.html_list[index].clicked = 1;
                        var value = {
                            timestamp: new Date(), index: index, time_length: now-this.start_time.getTime(),
                            time: this.time,
                            start_time: this.start_time,
                            length: this.html_list.length
                        };
                        this.update_click_post(value);
                        this.html_list[index].MSG_tsclk = now;
                    } else {
                        this.html_list[index].clicked = 0;
                    }
                }else{
                    ed.setMode('design');
                    ed.on('PostRender', function(){
                        ed.getBody().setAttribute('contenteditable', false);
                    });
                    ed.on('KeyPress', function(e){
                        e.preventDefault();
                        e.stopPropagation();
                    });
                }

            },
            onMouseUpdate: function(e){
                // console.log(e.y);
                this.mouseX = e.x;
                this.mouseY = e.y;
                this.mousePageX = e.pageX;
                this.mousePageY = e.pageY;
            },
            click_comment: function(index){
                this.html_list[index].show_comment = !this.html_list[index].show_comment;
                if (this.html_list[index].show_comment) this.html_list[index].show_bug = false;
                this.$nextTick(() => {
                    this.html_list = [...this.html_list];
                });
            },
            sum: function(array){
                return array.reduce((partialSum, a) => partialSum + a, 0);
            },
            updateVis: function(){
                const callback = (entries, observer) =>{
                    var entry = entries[0];
                    var mode;
                    var index = observer.index;
                    if ( (entry.intersectionRatio>0)) {
                        this.html_list[index].isVisible = true;
                        mode = 3;
                        this.html_list[index].MSG_y = (entry.intersectionRect.bottom+entry.intersectionRect.top)/2/entry.rootBounds.height;
                        this.html_list[index].MSG_h = entry.intersectionRect.height/entry.rootBounds.height;

                        if ((this.mouseY <= entry.intersectionRect.bottom) && (this.mouseY >= entry.intersectionRect.top)) {
                            this.html_list[index].visibleHeight[mode] = 10000;
                        } else {
                            this.html_list[index].visibleHeight[mode] = 1/Math.min(Math.abs(entry.intersectionRect.bottom-this.mouseY), Math.abs(entry.intersectionRect.top-this.mouseY));
                        }
                        if ((this.mouseY <= entry.intersectionRect.bottom) && (this.mouseY >= entry.intersectionRect.top)
                        &&(this.mouseX>=entry.intersectionRect.left)&&(this.mouseX<=entry.intersectionRect.right)) {
                            this.html_list[index].visibleHeight["m_hover"] = 1;
                        }else{
                            this.html_list[index].visibleHeight["m_hover"] = 0;
                        }
                        mode = 2;
                        this.html_list[index].visibleHeight[mode] = 0.5 - Math.abs(this.html_list[index].MSG_y - 0.5);

                        // this.html_list[index].visibleHeight[mode] =
                        //     1/Math.min(Math.abs((entry.intersectionRect.bottom+entry.intersectionRect.top)/2-entry.rootBounds.height/2)/entry.rootBounds.height,
                        //     0.1);
                        this.html_list[index].visibleHeight["d_to_c"] = 1- Math.abs((entry.intersectionRect.bottom+entry.intersectionRect.top)/2-entry.rootBounds.height/2)/entry.rootBounds.height;

                        mode = 1;
                        this.html_list[index].visibleHeight[mode] =entry.intersectionRect.height;

                        // console.log(index, this.html_list[index].visibleHeight);
                    }else{
                        this.html_list[index].isVisible = false;
                        this.html_list[index].visibleHeight[1] = 0;
                        this.html_list[index].visibleHeight[2] = 0;
                        this.html_list[index].visibleHeight[3] = 0;
                        this.html_list[index].visibleHeight["d_to_c"] = 0;
                        this.html_list[index].visibleHeight["m_hover"] = 0;
                        this.html_list[index].MSG_y = -1;
                        this.html_list[index].MSG_h = 0;
                    }
                    this.entry.bottom = entry.intersectionRect.bottom;
                    this.entry.top = entry.intersectionRect.top;
                    this.entry.height = entry.intersectionRect.height;
                };
                const observer = new IntersectionObserver(callback, {
                    root: null,
                    threshold: 0
                });
                var elements = document.querySelectorAll(".observable");
                var index = 0;
                function delay(time) {
                    return new Promise(resolve => setTimeout(resolve, time));
                }

                async function myLoop(l, index, delay) {
                    if (index<l) {
                        observer.index = index;
                        observer.observe(elements[index]);
                    }
                    await delay(50);
                    index += 1;
                    if (index<l) myLoop(l, index,delay);
                    else index = 0
                }


                myLoop(this.html_list.length, index,delay);

            },
            updateTime: function(){
                if (window.pageYOffset>0){
                    this.pageYs.push(window.pageYOffset);
                    while(this.pageYs.length>2) this.pageYs.shift();
                    if (this.pageYs.length>1) this.MSF_y.push(Math.abs(window.pageYOffset-this.pageYs[this.pageYs.length-2])/window.innerHeight);
                    while (this.MSF_y.length>10) this.MSF_y.shift();

                }
                var MSF_y_2, MSF_y_5, MSF_y_10;
                if (this.MSF_y.length>0){
                    MSF_y_2 = this.sum(this.MSF_y.slice(-2))/(this.MSF_y.slice(-2).length);
                    MSF_y_5 = this.sum(this.MSF_y.slice(-5))/(this.MSF_y.slice(-5).length);
                    MSF_y_10 = this.sum(this.MSF_y.slice(-10))/(this.MSF_y.slice(-10).length);
                    this.MSF_y_inf = (this.MSF_y_inf*this.time+this.MSF_y[this.MSF_y.length-1])/(this.time+1);
                }else{
                    MSF_y_2 = 0;
                    MSF_y_5 = 0;
                    MSF_y_10 = 0;
                    this.MSF_y_inf = 0;
                }

                if (this.mouseX>0){
                    this.mouseXs.push(
                        this.mouseX
                    );
                    while (this.mouseXs.length>2) this.mouseXs.shift();
                    if (this.mouseXs.length>1) this.MMF_x.push(Math.abs(this.mouseX-this.mouseXs[this.mouseXs.length-2])/window.innerWidth);
                    while (this.MMF_x.length>10) this.MMF_x.shift();
                }
                if (this.mouseY>0){
                    this.mouseYs.push(
                        this.mouseY
                    );
                    while (this.mouseYs.length>2) this.mouseYs.shift();
                    if (this.mouseYs.length>1) this.MMF_y.push(Math.abs(this.mouseY-this.mouseYs[this.mouseYs.length-2])/window.innerHeight);
                    while (this.MMF_y.length>10) this.MMF_y.shift();
                }
                var MMF_x_2, MMF_x_5, MMF_x_10, MMF_y_2, MMF_y_5, MMF_y_10;

                if (this.MMF_x.length>0){
                    MMF_x_2 = this.sum(this.MMF_x.slice(-2))/(this.MMF_x.slice(-2).length);
                    MMF_x_5 = this.sum(this.MMF_x.slice(-5))/(this.MMF_x.slice(-5).length);
                    MMF_x_10 = this.sum(this.MMF_x.slice(-10))/(this.MMF_x.slice(-10).length);
                    this.MMF_x_inf = (this.MMF_x_inf*this.time+this.MMF_x[this.MMF_x.length-1])/(this.time+1);
                }else {
                    MMF_x_2 = 0;    
                    MMF_x_5 = 0;
                    MMF_x_10 = 0;
                    this.MMF_x_inf = 0;
                }
                if (this.MMF_y.length>0){
                    MMF_y_2 = this.sum(this.MMF_y.slice(-2))/(this.MMF_y.slice(-2).length);
                    MMF_y_5 = this.sum(this.MMF_y.slice(-5))/(this.MMF_y.slice(-5).length);
                    MMF_y_10 = this.sum(this.MMF_y.slice(-10))/(this.MMF_y.slice(-10).length);
                    this.MMF_y_inf = (this.MMF_y_inf*this.time+this.MMF_y[this.MMF_y.length-1])/(this.time+1);
                }else{
                    MMF_y_2 = 0;
                    MMF_y_5 = 0;
                    MMF_y_10 = 0;
                    this.MMF_y_inf = 0;
                }


                var now = new Date();
                this.timestamp = dateFormat(now, "yyyy-mm-dd h:MM:ss");
                var value = {timestamp: now,
                    time_length: now.getTime()-this.start_time,
                    time: this.time,
                    start_time: this.start_time,
                    isVisible: this.isVisible,
                    entry: this.entry,
                    mouseY: this.mouseY/window.innerHeight,
                    mouseX: this.mouseX/window.innerWidth,
                    length: this.html_list.length,
                    S_cy: 0.5,
                    height: window.innerHeight,
                    MMF_x_2: MMF_x_2,
                    MMF_x_5: MMF_x_5,
                    MMF_x_10: MMF_x_10,
                    MMF_x_inf: this.MMF_x_inf,
                    MMF_y_2: MMF_y_2,
                    MMF_y_5: MMF_y_5,
                    MMF_y_10: MMF_y_10,
                    MMF_y_inf: this.MMF_y_inf,
                    MSF_y_2: MSF_y_2,
                    MSF_y_5: MSF_y_5,
                    MSF_y_10: MSF_y_10,
                    MSF_y_inf: this.MSF_y_inf,
                };
                var MSF_clk = 0;
                var M_tclk = 0;
                value["MSG_svt"] = {}
                value["MSG_sh"] = {}
                value["MSG_sy"] = {}
                value["MSG_tmy"] = {}
                value["MSG_tclk"] = {}
                value["MSG_clk"] = {}
                value["MSG_y"] = {}
                value["MSG_h"] = {}
                for (let mode =1; mode <= 3; mode++) {
                    var s = 0;
                    value["height_"+mode.toString()] = {}
                    value["time_"+mode.toString()] = {}
                    if (this.isVisible) {
                        for (var i = 0; i < this.html_list.length; i++) {
                            if (this.html_list[i].isVisible){
                                if (mode === 1) {
                                    this.html_list[i].MSG_svt += 1;
                                    this.html_list[i].MSG_sh += this.html_list[i].visibleHeight[mode]/window.innerHeight;
                                    this.html_list[i].MSG_sy += this.html_list[i].visibleHeight["d_to_c"];
                                    this.html_list[i].MSG_tmy += this.html_list[i].visibleHeight["m_hover"];
                                    value["MSG_svt"][i] = this.html_list[i].MSG_svt;
                                    value["MSG_sh"][i] = this.html_list[i].MSG_sh;
                                    value["MSG_sy"][i] = this.html_list[i].MSG_sy;
                                    value["MSG_tmy"][i] = this.html_list[i].MSG_tmy;
                                    value["MSG_y"][i] = this.html_list[i].MSG_y;
                                    value["MSG_h"][i] = this.html_list[i].MSG_h;
                                }
                                s += this.html_list[i].visibleHeight[mode];
                                value["height_"+mode.toString()][i] = this.html_list[i].visibleHeight[mode];
                            }else{
                                value["MSG_svt"][i] = this.html_list[i].MSG_svt;
                                value["MSG_sh"][i] = this.html_list[i].MSG_sh;
                                value["MSG_sy"][i] = this.html_list[i].MSG_sy;
                                value["height_"+mode.toString()][i] = 0 ;
                                value["MSG_tmy"][i] = this.html_list[i].MSG_tmy;
                                value["MSG_y"][i] = this.html_list[i].MSG_y;
                                value["MSG_h"][i] = this.html_list[i].MSG_h;
                            }

                        }
                        if (s === 0) s = 1;
                        for (i = 0; i < this.html_list.length; i++) {
                            if (this.html_list[i].isVisible) {
                                this.html_list[i].time += this.html_list[i].visibleHeight[mode] / s;
                                value["time_"+mode.toString()][i] = this.html_list[i].visibleHeight[mode] / s;
                            }else{
                                value["time_"+mode.toString()][i] = 0;
                            }
                            if (mode===1) {
                                if (this.html_list[i].MSG_tsclk !== 0) {
                                    value["MSG_tclk"][i] = 1 - (now.getTime() - this.html_list[i].MSG_tsclk)/(30 * 60 * 1000);
                                    value["MSG_clk"][i] = 1;
                                    MSF_clk += 1/this.html_list.length;
                                } else {
                                    value["MSG_tclk"][i] = 0;
                                    value["MSG_clk"][i] = 0;
                                }
                                M_tclk = Math.max(M_tclk, value["MSG_tclk"][i]);
                            }

                        }
                    }else{
                        for (let i = 0; i < this.html_list.length; i++) {
                            value["height_"+mode.toString()][i] = 0 ;
                            value["time_"+mode.toString()][i] = 0;
                            if (mode === 1) {
                                value["MSG_svt"][i] = this.html_list[i].MSG_svt;
                                value["MSG_sh"][i] = this.html_list[i].MSG_sh;
                                value["MSG_sy"][i] = this.html_list[i].MSG_sy;
                                value["MSG_tmy"][i] = this.html_list[i].MSG_tmy;
                                value["MSG_y"][i] = this.html_list[i].MSG_y;
                                value["MSG_h"][i] = this.html_list[i].MSG_h;
                                if (this.html_list[i].MSG_tsclk !== 0) {
                                    value["MSG_clk"][i] = 1;
                                    MSF_clk += 1/this.html_list.length;
                                } else {
                                    value["MSG_clk"][i] = 0;
                                }
                                if (this.html_list[i].MSG_tsclk !== 0) value["MSG_tclk"][i] = 1 - (now.getTime() - this.html_list[i].MSG_tsclk)/(30 * 60 * 1000);//now.getTime() - this.html_list[i].MSG_tsclk;
                                else value["MSG_tclk"][i] = 0;
                                M_tclk = Math.max(M_tclk, value["MSG_tclk"][i]);
                            }
                        }

                    }
                    value["s_"+mode.toString()] = s;
                }
                for (let mode =1; mode <= 3; mode++) {
                    for (let i = 0; i < this.html_list.length; i++) {
                        if (value["s_" + mode.toString()] > 0) value["height_" + mode.toString()][i] = value["height_" + mode.toString()][i] / value["s_" + mode.toString()]
                        else value["height_" + mode.toString()][i] = 0;
                    }
                }

                value["M_tclk"] = M_tclk;

                value["S_h"] = 1;
                value["MSF_clk"] = MSF_clk;
                // console.log(value);
                // this.second_predict(value);
                // this.session_predict(value);
                //
                //
                //
                this.update_read_post(value);
                this.time += 1;
            },

            session_predict: function(value){
                var x1 = [];
                var x2 = []
                for (let i = 0; i < this.html_list.length; i++){
                    var tmp1 = [];
                    var tmp2 = [];
                    this.user_session_cols.forEach((col)=>tmp1.push(Number(value[col])));
                    this.msg_session_cols.forEach((col)=>tmp2.push(Number(value[col][i.toString()])));
                    x1.push(tmp1);
                    x2.push(tmp2);
                }
                // var preds = this.second_model.predict([tf.tensor2d(x1), tf.tensor2d(x2)]);
                // preds.print();
            },

            second_predict: function(value){
                var x1 = [];
                var x2 = []
                for (let i = 0; i < this.html_list.length; i++){
                    var tmp1 = [];
                    this.user_tmp_cols.forEach((col)=>tmp1.push(Number(value[col])));
                    this.msg_tmp_cols.forEach((col)=>tmp1.push(Number(value[col][i.toString()])));
                    x1.push(tmp1);
                    var tmp2 = [];
                    this.user_pattern_cols.forEach((col)=>tmp2.push(Number(value[col])));
                    x2.push(tmp2);
                }
                // var preds = this.second_model.predict([tf.tensor2d(x1), tf.tensor2d(x2)]);
                // preds.print();

            }
        }
    }
</script>

<style scoped>
    div.sticky {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        right: 0;
        /*padding: 40px;*/
        /*font-size: 25px;*/
    }
</style>