import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Auth from '../views/Auth'
import Error from "../views/Error.vue"
import Edit from "../views/Edit.vue"
import Dashboard from "../views/Dashboard"
import EmployeeRegister from "../views/EmployeeRegister";
import CommunicatorRegister from "../views/CommunicatorRegister";
// import Register from "../views/Register";
import CommAuth from "../views/CommAuth";
import CommHome from "../views/CommHome";
import CreateEmailTest from "../views/CreateEmailTest";
import Email from "../views/Email.vue";
import Report from "../views/Report";
import VueHead from 'vue-head'
import { library } from '@fortawesome/fontawesome-svg-core'
import TestView from "../views/TestView.vue";
import Thank from "../views/Thank.vue"
import ThankLogin from "../views/ThankLogin";
import Logout from "../views/Logout";
import EyeTrackingTest from "../views/EyeTrackingTest";
import EyeTrackingTestView from "../views/EyeTrackingTestView";
import UpdatePerf from "../views/UpdatePerf";
import UpdatePostPerf from "../views/UpdatePostPerf";
import Admin from "../views/Admin";
import Model from "../views/Model"
import Vuex from 'vuex'
import store from './store'
import ReportShare from "../views/ReportShare";
import EmailReminder from "../views/EmailReminder";
import VTooltip from 'v-tooltip';
import EditExample from "../views/EditExample";
import About from "../views/About";
import DashboardAdmin from "../views/DashboardAdmin";
import EmailAdmin from "../views/EmailAdmin";
import ReportAdmin from "../views/ReportAdmin";
import ImageApp from "../views/image_app";

Vue.use(VTooltip)

Vue.use(Vuex)
Vue.use(IdleVue, {
  store:  store,
  idleTime: 1000,
  startAtIdle: false
});



/* import specific icons */
// import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { faThumbsDown, faThumbsUp, faCommentDots, faBug, faSearch, faShare, faEdit, faUser, faUserPen, faQuestionCircle, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueObserveVisibility from 'vue-observe-visibility'

// import VIntersect from 'v-intersect'

// Vue.use(VIntersect)

/* add icons to the library */
library.add(faThumbsDown, faThumbsUp, faCommentDots, faBug, faSearch, faShare, faEdit, faUser, faUserPen, faQuestionCircle,faArrowUp, faArrowDown)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueObserveVisibility)

// fontawesome.library.add(brands, faSpinner);

//import map from "@/views/maps.vue"
//import MapLayout from "@/layouts/MapLayout"


import {auth} from '../firebaseConfig.js'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Vuesax from 'vuesax'


import 'vuesax/dist/vuesax.css' //Vuesax styles
import VueSimpleAlert from "vue-simple-alert";
import IdleVue from "idle-vue";

// Vue.use(VueGapi, {
//   apiKey: 'AIzaSyDLMzreSa3xT5no1WGgc9O_dp2Pd_H5mjY',
//   clientId: '264929924419-n9l20mdsvuigikllrfhd84regdjjlrgm.apps.googleusercontent.com',
//   discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest"],
//   scope: ['https://mail.google.com/', 'https://www.googleapis.com/auth/gmail.compose', 'https://www.googleapis.com/auth/gmail.labels',
//   'https://www.googleapis.com/auth/gmail.metadata', 'https://www.googleapis.com/auth/gmail.readonly', 'https://www.googleapis.com/auth/gmail.send'].join(" "),
// })
// const gauthOption = {
//   clientId: '264929924419-n9l20mdsvuigikllrfhd84regdjjlrgm.apps.googleusercontent.com',
//   scope: 'profile email',
//   prompt: 'select_account'
// }
// Vue.use(GAuth, gauthOption)

Vue.use(VueSimpleAlert);
Vue.use(Vuesax, {
  // options here
})


Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueRouter)
Vue.use(VueHead)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/eye_tracking_test',
    name: 'EyeTrackingTest',
    component:  EyeTrackingTest
  },
  {
    path: '/communicator',
    name: 'CommunicatorHome',
    component: CommHome
  },
  {
    path: '/edit_example',
    name: 'EditExample',
    component: EditExample
  },
  {
    path: '/comm_auth/:redirect?',
    name: 'CommAuth',
    component: CommAuth,
    props: function(route) {
      return {redirect: route.params.redirect}
    },
  },
  {
    path: '/auth/:redirect?',
    name: 'Auth',
    component: Auth,
    props: function(route) {
      return {redirect: route.params.redirect}
    },
  },
  {
    path: '/edit/:newsletterId',
    name: 'Edit',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Edit,
    meta: {
      requiresAuth: true
    },
    props: function(route) {
      return {newsletterId: route.params.newsletterId}
    },
  },
  {
    path: '/thank',
    name: 'Thank',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Thank,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/thank_login',
    name: 'ThankLogin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ThankLogin,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Logout,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: About,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/update_perf',
    name: 'UpdatePerf',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: UpdatePerf,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/email_reminder',
    name: 'EmailReminder',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: EmailReminder,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/update_post_perf',
    name: 'UpdatePostPerf',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: UpdatePostPerf,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/model',
    name: 'Model',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Model
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/dashboard_admin',
    name: 'DashboardAdmin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: DashboardAdmin,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Admin,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/create_email_test',
    name: 'CreateEmailTest',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: CreateEmailTest,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/email/:newsletterId',
    name: 'Email',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Email,
    meta: {
      requiresAuth: true
    },
    props: function(route) {
      return {newsletterId: route.params.newsletterId}
    },
  },
  {
    path: '/email_admin/:newsletterId',
    name: 'EmailAdmin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: EmailAdmin,
    meta: {
      requiresAuth: true
    },
    props: function(route) {
      return {newsletterId: route.params.newsletterId}
    },
  },
  {
    path: '/report/:userId/:postId',
    name: 'report',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Report,
    props: function(route) {
      return {postId: route.params.postId, userId: route.params.userId}
    },
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/report_admin/:userId/:postId',
    name: 'report_admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ReportAdmin,
    props: function(route) {
      return {postId: route.params.postId, userId: route.params.userId}
    },
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/report_share/:postId',
    name: 'report_share',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ReportShare,
    props: function(route) {
      return {postId: route.params.postId}
    },
  },
  {
    path: '/test_view/:userId/:postId',
    name: 'test_view',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: TestView,
    meta: {
      // requiresAuth: true
    },
    props: function(route) {
      return {postId: route.params.postId, userId: route.params.userId}
    },

  },

  {
    path: '/eye_tracking_test_view/:userId/:postId',
    name: 'eye_tracking_test_view',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: EyeTrackingTestView,
    meta: {
      // requiresAuth: true
    },
    props: function(route) {
      return {postId: route.params.postId, userId: route.params.userId}
    },

  },


  // {
  //   path: '/register',
  //   name: 'Register',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: Register,
  //   meta: {
  //     requiresAuth: true
  //   }
  // },

  {
    path: '/employee_register',
    name: 'EmployeeRegister',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: EmployeeRegister,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/communicator_register',
    name: 'CommunicatorRegister',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: CommunicatorRegister,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/image_app',
    name: 'ImageApp',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ImageApp,
  },
  {
    path: '/image_app/:sessionId',
    name: 'ImageApp',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ImageApp,
    props: function(route) {
      return {sessionId: route.params.sessionId}
    },
  },
  // {
  //   path: '/404',
  //   name: 'Error',
  //   component: Error
  // },

  /*{
    path: '/maps',
    component: MapLayout,
    children: [
      {
        path: '',
        name: 'map',
        component: map
      },
    ],
    meta: {
      requiresAuth: true
    }
  },*/


  {
    path: '*',
    name: 'Error',
    component: Error
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// this makes the router check each change in routes -- if their "meta" says it requires auth, it will check for auth.
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  if (requiresAuth && !auth.currentUser) {
    next(        {path: '/auth/'+ encodeURIComponent(to.path)})
  } else {
    next()
  }
})



export default router
