
<template>

    <b-container style="max-width: 1000px;">
        <div class="row" style="margin-top:5px;">
            <b-col md="11">
                <!-- <b-img src="https://popuppainting.com/wp-content/uploads/2018/08/blue-sky.jpg" fluid></b-img> -->
                <b-input-group prepend="Subject" >
                    <b-form-input v-model="subject" placeholder="Input the subject line of your email here."></b-form-input>
                </b-input-group>
            </b-col>
            <b-col md="1">
                <a href="\edit_example" target="_blank">Help</a>
            </b-col>
        </div>
        <div class="row" style="margin-top:5px;">
            <b-col md="12">
                <b-input-group prepend="Newsletter">
                    <b-form-select v-model="newsletterId" :options="newsletter_options" v-b-tooltip="'Select the newsletter of this email or create a newsletter if it does not exist -- usually the sender name, e.g., U of M Brief'"></b-form-select>
                </b-input-group>
            </b-col>
        </div>
        <div v-if="this.newsletterId==='create_new'">
            <b-container style="max-width: 1000px;">
                <div class="row" style="margin-top:20px;">

                    <b-col md="3">
                    </b-col>
                    <b-col md="6">
                        <b-form id="form" @submit="onSubmit"  v-if="show">
                            <b-form-group
                                    id="input-group-1"
                                    label="Newsletter Brand Name:"
                                    label-for="input-1"
                                    description="Enter the BRAND name for this newsletter (usually the sender name), e.g., U of M Brief, Office of the Vice President for Research."
                            >
                                <b-form-input
                                        id="input-1"
                                        v-model="form.email"
                                        placeholder="e.g., U of M Brief"
                                        required
                                ></b-form-input>
                            </b-form-group>

                            <b-form-group id="input-group-2"  label-for="input-2"
                                          description="Enter the actual number of the employee recipients of this newsletter.">
                                <label>Size of Mailings List:
                                    <span v-b-tooltip.hover.righttop="'Enter the actual number of the employee recipients of this newsletter. E.g., if U of M Brief is sent to 20000 employees of the university, input 20000.'">

                                                                    <font-awesome-icon
                                                                            icon="fa-solid fa-question-circle"/>
                                </span>
                                </label>

                                <b-form-input
                                        id="input-2"
                                        v-model="form.n_recipient"
                                        :type= "'number'"
                                        required
                                ></b-form-input>

                            </b-form-group>

                            <label>Select the audience of this newsletter</label>
                            <b-form-radio-group
                                    id="audience"
                                    v-model="form.audience"
                                    :options="audience_options"
                                    name="radio-options"
                                    required
                            ></b-form-radio-group>


                            <!--                            <b-button type="submit" variant="primary" style="margin: 10px;">Create</b-button>-->
                        </b-form>
                    </b-col>
                    <b-col md="3">
                    </b-col>
                </div>
            </b-container>
        </div>
        <div class="row" style="margin-top:5px;">
            <b-col sm="12">
                <editor v-model="email_html"
                        api-key="6amjl4rlxjw7zvlf91tg3iz4m9ntrvj0tk1543pxopouuq9k"
                        placeholder = "Select the email content from your umn inbox. Copy and paste here."
                        :init="{
                                 height: 700,
                                 menubar: false,
                                 content_style: 'img {max-width: 600px; }' + 'div {max-width: 600px}'+ 'td {max-width: 600px}'+ 'tr {max-width: 600px}'+ 'tbody {max-width: 600px}' +  'table {max-width: 600px}',
                                 plugins: [
                                     // 'autoresize',
                                   'advlist autolink lists link image charmap print preview anchor',
                                   'searchreplace visualblocks code fullscreen textcolor',
                                   'insertdatetime media table paste code help wordcount'
                                 ],
                                 toolbar:
                                   'undo redo | formatselect | bold italic forecolor backcolor | \
                                   alignleft aligncenter alignright alignjustify | \
                                   bullist numlist outdent indent | removeformat | help',
                                   // autoresize_on_init: true,
                               }"
                />
            </b-col>
        </div>
        <div class="row" style="margin-top:5px;">
            <b-col sm="6" class="text-left">

            </b-col>
            <b-col sm="6" class="text-right">
                <b-button variant="primary" v-if="this.newsletterId!=='create_new'" style="margin-right: 4px" @click="upload" v-b-tooltip.hover title="Don't forget to scoll to the bottom and click on 'send' after the preview is generated.">Generate Preview</b-button>
                <b-button variant="primary" v-if="this.newsletterId==='create_new'" style="margin-right: 4px" type="submit" form="form" v-b-tooltip.hover title="Don't forget to scoll to the bottom and click on 'send' after the preview is generated.">Generate Preview</b-button>
            </b-col>
        </div>

        <!--        <div class="row" style="margin-top:5px; "  v-if="reload">-->

        <div v-if="reload">
            <div class="row">
                <b-col sm="12" class="text-center">
                    <h2>Preview</h2>
                    <h4>Please check the following items of your preview (<a href="\edit_example" target="_blank">example</a>):</h4>
                    <h6>1. The email is correctly split to different messages (the contents that you want to test separately) --- use add/remove/merge to revise. </h6>
                    <h6>2. Add tracking to all the messages you want to collect metrics on and make sure their titles are correct --- use add tracking.</h6>
                    <h6>3. Remove tracking of the messages that you don't want to collect metrics on (e.g., the header, ending, section title of the email).</h6>
                    <h6>4. Remove "View this message on the web" (we can't track participants' data on other pages).</h6>
                    <h6>5. Add comments under the stories if you want, e.g., ``Will you attend this event?''.</h6>
                    <h6>6. Click on "send". Don't close this page until being redirected.</h6>
                    <h3><b>7. Remove the unsubscribe link</b> from the email <span style="font-size: 20px">--- our participants agreed to receive communications during the whole experiment.</span></h3>
                    <h6>Wrong format? Forward the email to Ruoyan at kong0135@umn.edu to debug.</h6>
                </b-col>

            </div>
            <div class="row" >
                <b-col sm="2" >
                    <b-button variant="primary" style=" position:fixed;
                                bottom: 5px;
                                left: 5px;
                                z-index:9999;" v-b-tooltip="'undo the last merge / remove / add '" @click="undo">undo</b-button>
                </b-col>
                <b-col sm="8">
                    <li v-for="(item, index) in this.html_list" v-bind:key= index style=" margin-top: 40px; margin-right: auto; margin-left: auto">
                        <!--                        max-width: 700px;-->
                        <div v-if = item.data[2]>
                            <div class="row" style="margin-bottom: 4px">
                                <b-col sm="2" style="text-align: center">
                                    <b-button variant="outline-primary" @click="remove(index)" v-b-tooltip="'remove the message underneath'">remove</b-button>
                                </b-col>
                                <b-col sm="3" style="text-align: center">
                                    <b-button variant="outline-primary" @click="add(index)" v-b-tooltip="'add a new message BELOW this one'">add below</b-button>
                                </b-col>
                                <b-col sm="3" style="text-align: center" v-if = !item.data[1]>
                                    <b-button variant="outline-primary" @click="flip_survey(index)" v-b-tooltip="'add tracking (e.g., reading time, click rate, relevance rate) to this message underneath'">add tracking</b-button>
                                </b-col>
                                <b-col sm="3" style="text-align: center" v-if = item.data[1]>
                                    <b-button variant="outline-primary" @click="flip_survey(index)" v-b-tooltip="'remove tracking on reading time / click rate ... of this message underneath'">remove tracking</b-button>
                                </b-col>
                                <b-col sm="2" style="text-align: center">
                                    <b-button variant="outline-primary" @click="merge_above(index)" v-b-tooltip="'merge the message underneath with the message ABOVE it'">merge &#8593;</b-button>
                                </b-col>
                                <b-col sm="2" style="text-align: center">
                                    <b-button variant="outline-primary" @click="merge_below(index)" v-b-tooltip="'merge the message underneath with the message BELOW it'">merge &#8595;</b-button>
                                </b-col>
                            </div>



                            <div class="row" style="margin-bottom: 0px" v-if="item.data[1]">
                                <b-col sm="11" >
                                    <b-input-group prepend="Message Title">
                                        <b-form-input v-model="item.data[3]" :placeholder="item.data[3]"></b-form-input>
                                    </b-input-group>
                                </b-col>
                                <b-col sm="1" >
                                    <span  style="font-size: 2em">&#9998;</span>
                                    <!--                            <font-awesome-icon icon="fas fa-edit" style="font-size: 2em" />-->
                                </b-col>
                            </div>

                            <div style="min-height: 10px">
                                <editor
                                        v-model = "item.data[0]"
                                        api-key="6amjl4rlxjw7zvlf91tg3iz4m9ntrvj0tk1543pxopouuq9k"
                                        branding = false
                                        :init="{
                                 menubar: false,
                                 plugins: [
                                     'autoresize',
                                      'advlist autolink lists link image charmap print preview anchor',
                                   'searchreplace visualblocks code fullscreen textcolor',
                                   'insertdatetime media table paste code help wordcount'
                                 ],
                                 content_style: 'img {max-width: 600px; }' + 'div {max-width: 600px}'+ 'td {max-width: 600px}'+ 'tr {max-width: 600px}'+ 'tbody {max-width: 600px}' +  'table {max-width: 600px}',
                                 toolbar:
                                  'undo redo | formatselect | bold italic forecolor backcolor | \
                                   alignleft aligncenter alignright alignjustify | \
                                   bullist numlist outdent indent | removeformat | help',
                                   // autoresize_on_init: true,
                                         autoresize_on_init: true,
                                         // visual: false,
                                          autoresize_bottom_margin: 0,
                                          // branding: false,
                                          //  statusbar: false
                                       }"
                                />
                            </div>
                            <div class="row" v-if = item.data[1]>
                                <b-col sm="10" style="text-align: left">
                                    <font-awesome-icon icon="fa-solid fa-thumbs-up" style="margin-left: 20px"/> relevant-to-me

                                </b-col>
                                <b-col sm="2" style="text-align: right">
                                    <font-awesome-icon icon="fa-solid fa-comment-dots" style="margin-right: 5px" @click="click_comment(index)"/>
                                </b-col>
                            </div>

                            <div  v-if = item.data[1]>
                                <div v-for="(it, ind) in item.all_comments" v-bind:key= "'A'+ind" >

                                    <Comment :comment=it v-if="item.show_comment||(it.sender===true)"/>
                                </div>

                                <div class="row">
                                    <b-col sm="12" >
                                        <b-form-textarea  max-rows="6" :ref="'comment' + index" placeholder="Type to post questions here to your recipients" v-on:change="event=>comment_tmp=event" ></b-form-textarea>
                                    </b-col>
                                </div>
                                <div class="row">
                                    <b-col sm="12" style="text-align:right">
                                        <b-button  style="margin-top: 5px" @click="submit_comment(index)" v-b-tooltip.hover.righttop="'Click here to comment. Your comment will be shown anonymously as [from sender].'">Post Question</b-button>
                                    </b-col>
                                </div>
                            </div>


                        </div>
                    </li>
                </b-col>
            </div>
            <div class="row" style="margin-top:5px;">
                <b-col sm="2" class="text-center">

                </b-col>

                <b-col sm="8" class="text-center">
                    <!--                    <input v-model="email_address" placeholder="test address">-->
                    <b-button v-if="waiting_msg===''" variant="primary" @click="send" v-b-tooltip="'Send to all participants according to this newsletter\'s audience (faculty\\staff) --- please don\'t close this  page until being redirected'">Send</b-button>
                    <div v-if="waiting_msg!==''"><b style="color: red">{{waiting_msg}} {{progress}}/151 recipients</b></div>
                    <!--                    <b-dropdown split text="Send" @click="send" variant="primary" class="m-md-2" >-->
                    <!--                        <b-dropdown-item @click="click_schedule">Send Later</b-dropdown-item>-->
                    <!--                    </b-dropdown>-->
                </b-col>
                <b-col sm="2" class="text-center">
<!--                    <label >Choose a time</label>-->
<!--                    <b-form-datepicker id="example-datepicker" v-model="date"></b-form-datepicker>-->
<!--                    <b-form-timepicker v-model="time" locale="en"></b-form-timepicker>-->
<!--                    <b-button variant="primary" style="margin-top: 10px" @click="schedule">Schedule</b-button>-->
                </b-col>
            </div>


        </div>


    </b-container>
</template>



<!--<script src="https://apis.google.com/js/api.js"></script>-->
<script>
    import '../style/edit.css';
    // import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { db } from "../firebaseConfig.js"
    import { auth } from "@/firebaseConfig"
    import Editor from '@tinymce/tinymce-vue'
    import firebase from 'firebase'
    import Comment from "../components/Comment";
    // you probably need to import built-in style
    import 'vue-range-slider/dist/vue-range-slider.css'
    export default {
        name: "Edit",
        components: {
            Comment,
            editor: Editor,
            // "font-awesome-icon": FontAwesomeIcon
        },
        props: ['newsletterId'],
        mounted() {
            // const spreadsheetApiRequest = {
            //     apiKey: 'AIzaSyDLMzreSa3xT5no1WGgc9O_dp2Pd_H5mjY',
            //     clientId: '264929924419-s8mpool0jun76o6nc8orgksp4j7b8atj.apps.googleusercontent.com',
            //     discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest"],
            //     scope: 'https://www.googleapis.com/auth/gmail.readonly',
            // };
            setTimeout(() => {
                this.isLoading = false
            },2000);
            window.setInterval(() => {
                this.getNotifications();
            }, 1000);
        },
        created () {
            document.title = "Comm Tool: Create Email";

        },
        beforeCreate: function() {
            auth.onAuthStateChanged(user => {
                console.log("user state:", user);
                // uncomment above to check out which user properties are available.
                if (!user) {
                    //this.$router.push("/"); this have already been done in router
                }else{
                    this.user = user;
                    db.collection('users').doc(this.user.uid).get().then((doc) => {
                        if (doc.exists) {
                            if (doc.data().demo === true) this.demo = true;
                        } else {
                            // doc.data() will be undefined in this case
                            console.log("No such document!");
                        }
                    })
                    var collection = db.collection('newsletter');
                    var docID = this.newsletterId;
                    if (docID === 'new'){
                        this.newsletter_options = [];
                        this.newsletterDict = {};
                        collection.where("userId", "==", this.user.uid).get().then(querySnapshot => {
                                querySnapshot.docs.map((doc) => {
                                        var tmp = doc.data();
                                        if (this.$store.state.demo !== true){
                                            if (tmp.demo === true) return;
                                        }
                                        this.newsletter_options.push({text: tmp['newsletter_brand'], value: doc.id})
                                        this.newsletterDict[doc.id] = tmp['newsletter_brand'];
                                        this.audienceDict[doc.id] = tmp['audience'];
                                    }
                                )
                                this.newsletter_options.push({text: "--- click here to create a new newsletter (if you didn't see the newsletter you need) --- ", value: "create_new"})

                            }
                        )

                    }else {
                        collection.doc(docID).get().then((document) => {
                            if (document.exists) {
                                this.audience = document.data().audience;
                                this.size = document.data().n_recipient;
                                this.users = []
                                db.collection("users").where("recipient_checked", "==", true)
                                    .where("demo","==",(this.demo || this.$store.state.demo)).get().then((querySnapshot) => {
                                    querySnapshot.forEach((doc) => {
                                        var d = doc.data();
                                        if (d.opt_out !== undefined) return;
                                        if (this.audience.includes(doc.data().category)) {
                                            this.users.push(doc.data());
                                        }
                                    });
                                })
                            } else {
                                this.alert("Newsletter does not exist. Please create a new one or contact kong0135@umn.edu")
                            }
                        })
                    }
                    // this.todos = this.$firestoreRefs['todos'].orderBy("timestamp", "desc").where("done", "==", false).where("userId", "==", this.user);
                }
            });
        },
        data : function() {
            return {
                email_address: "",
                progress: 0,
                time: 0,
                gapiLoadClientPromise: null,
                known: false,
                reload: false,
                subject: "",
                recipient: 0,
                email_html: "",
                comment_tmp: "",
                // API_KEY: "AIzaSyDLMzreSa3xT5no1WGgc9O_dp2Pd_H5mjY",
                // CLIENT_ID: "264929924419-n9l20mdsvuigikllrfhd84regdjjlrgm.apps.googleusercontent.com",
                html_list: [],
                last_html_list: [],
                schedule_pop: false,
                waiting_msg: "",
                newsletter_options: [],
                form: {
                    email: '',
                    n_recipient: 20000,
                    audience: ''
                },
                audience_options: ["staff only", "faculty only", "staff and faculty"],
                show: true,
                newsletterDict: {},
                postId: "",
                userId: "",
                demo: false,
                audience: "",
                audienceDict: {}
            }
        },
        firestore: function () {
            if (!firebase.auth().currentUser){
                return {}
            }else{
                return {
                    // mylists: db.collection("lists").where("shared_with", "array-contains", firebase.auth().currentUser.email).orderBy("created_at", "desc"),
                }
            }
        },
        methods:{
            getNotifications: function(){
                this.time += 1;
            },
            click_schedule: function(){
                this.schedule_pop=true;
            },
            flip_survey: function(index){
                this.html_list[index].data[1] = !this.html_list[index].data[1];
                this.$nextTick(() => {
                    this.html_list = [...this.html_list];
                });
            },
            remove: function(index){
                this.last_html_list = JSON.parse(JSON.stringify(this.html_list));
                this.html_list.splice(index, 1);
                this.$nextTick(() => {
                    this.html_list = [...this.html_list];
                });
            },
            add: function(index){
                this.last_html_list = JSON.parse(JSON.stringify(this.html_list));
                var new_list = [
                    ...this.html_list.slice(0, index+1),
                    // inserted item
                    {data:['', true, true, "click to add title"], show_comment: false,all_comments:[]},
                    // part of the array after the specified index
                    ...this.html_list.slice(index+1)];
                this.$nextTick(() => {
                    this.html_list = [...new_list];
                });
            },
            merge_above: function(index) {
                this.last_html_list = JSON.parse(JSON.stringify(this.html_list));
                if (index >= 1) {
                    var item = this.html_list[index - 1];
                    this.html_list[index].data[0] = item.data[0] + this.html_list[index].data[0];
                    if (this.html_list[index-1].data[3] !== "click to add title") this.html_list[index].data[3] = this.html_list[index - 1].data[3];
                    this.html_list[index].data[1] = this.html_list[index].data[1] || this.html_list[index-1].data[1];
                    this.html_list.splice(index - 1, 1);
                    this.$nextTick(() => {
                        this.html_list = [...this.html_list];
                    });
                }
            },
            merge_below: function(index) {
                this.last_html_list = JSON.parse(JSON.stringify(this.html_list));
                if (index < this.html_list.length-1) {
                    var item = this.html_list[index + 1];
                    this.html_list[index].data[0] += item.data[0];
                    if (this.html_list[index].data[3] === "click to add title") this.html_list[index].data[3] = this.html_list[index + 1].data[3];
                    this.html_list[index].data[1] = this.html_list[index].data[1] || this.html_list[index+1].data[1];
                    this.html_list.splice(index + 1, 1);
                    this.$nextTick(() => {
                        this.html_list = [...this.html_list];
                    });
                }
            },
            onSubmit(event) {
                let self=this;
                event.preventDefault()
                // alert(JSON.stringify(this.form))
                db.collection('newsletter').add({
                        userId:this.user.uid,
                        newsletter_brand:this.form.email,
                        n_recipient:this.form.n_recipient,
                        audience: this.form.audience,
                        demo: (this.$store.state.demo || this.demo),
                        timestamp: new Date()
                    }
                ).then(function(docRef) {
                        self.newsletterId = docRef.id;
                        self.newsletter_options.push({text:self.form.email, value:self.newsletterId});
                        self.newsletterDict[self.newsletterId] = self.form.email;
                        self.audienceDict[self.newsletterId] = self.form.audience;
                        self.upload();
                    }

                )

            },
            sendEmail: function(){
                // this.gapi.auth2.getAuthInstance().signIn()
                // this.gapi.client.gmail.users.labels.list({
                //     'userId': 'me'
                // }).then(function(response) {
                //     var labels = response.result.labels;
                //     console.log(labels);
                // });
            },
            send: async function(){
                this.waiting_msg = "Sending now ... please don't close this page until being redirected."
                var promises = [];
                var date = new Date();
                db.collection('post').add({
                        userId:this.user.uid,
                        newsletterId:this.newsletterId,
                        subject:this.subject,
                        email_html:this.email_html,
                        n_recipient: this.size,
                        timestamp: date,
                        delete: false,
                        demo: (this.$store.state.demo || this.demo),
                        audience: this.audience
                        // opened: {},
                        // clicked: {},
                        // relevant: {},
                        // reading_time: {},
                        // commented: {}
                    }
                ).then((docRef) => {
                    var postId = docRef.id;
                    this.userId = this.user.uid;
                    this.postId = postId;
                    this.html_list.forEach((element, index)=>{
                        promises.push(db.collection('messages').add({
                                index: index,
                                userId: this.user.uid,
                                newsletterId: this.newsletterId,
                                postId: postId,
                                data: element,
                                timestamp: date,
                                delete: false,
                                demo: (this.$store.state.demo || this.demo),
                                // comments: {},
                                // clicked: {},
                                // reading_time: {},
                                // relevant: {}
                            })
                        )
                        if (this.html_list[index].all_comments.length>0){
                            promises.push(

                                db.collection("read_message").doc(this.user.uid+"_"+postId+"_"+index).set({
                                    userId: this.user.uid,
                                    postId: postId,
                                    comments: this.html_list[index].all_comments,
                                    relevant: 0,
                                    clicked: 0,
                                    time: 0,
                                    index: index,
                                    timestamp: date,
                                    demo: (this.$store.state.demo || this.demo),
                                })
                            )
                        }
                    })
                    var comment = 0;
                    this.html_list.forEach(value=>comment+=value.all_comments.length);
                    if (comment>0){
                        promises.push(
                            db.collection("read_post").doc(this.user.uid+"_"+postId).set({
                                    userId: this.user.uid,
                                    postId: postId,
                                    comments: comment,
                                    relevant: 0,
                                    clicked: 0,
                                    time: 0,
                                    timestamp: date,
                                    demo: (this.$store.state.demo || this.demo),
                                }
                            ))
                    }
                    function delay(time) {
                        return new Promise(resolve => setTimeout(resolve, time));
                    }
                    var add_mail_promises = [];
                    async function add_mail (newsletter_name, subject, that){
                        for (const item of that.users) {
                            var link = "https://www.comm-tool.com/test_view/" + item.userId + "/" + postId;
                            var  consent_link = "https://www.comm-tool.com/about";
                            if (item.group === "explicit") {
                                promises.push(db.collection('mail').add({
                                    to: item.email,
                                    from: newsletter_name + ' <email-study@umn.edu>',
                                    message: {
                                        subject: subject,
                                        html: '<a href=' + link + ' target="_blank">click to read</a><br><br><br><br><br><br>' +
                                            '<div style="color:grey">' +
                                            'You are receiving this email because you participated in the bulk email-study: ' + '<a href=' + consent_link + ' target="_blank">study information</a>.' +
                                            'Based on your participant group, please open and read these links, check the boxes if those messages are relevant to you, and leave comments if applicable. You will receive 2-4 emails like this per week from email-study@umn.edu during Nov to Dec. Please email Ruoyan at kong0135@umn.edu if you have any questions about this study. '+
                                            '</div>',
                                    },
                                }))
                            }else{
                                promises.push(db.collection('mail').add({
                                    to: item.email,
                                    from: newsletter_name + ' <email-study@umn.edu>',
                                    message: {
                                        subject: subject,
                                        html: '<a href=' + link + ' target="_blank">click to read</a><br><br><br><br><br><br>' +
                                            '<div style="color:grey">' +
                                            'You are receiving this email because you participated in the bulk email-study: ' + '<a href=' + consent_link + ' target="_blank">study information</a>.' +
                                            'Based on your participant group, please react to those emails as naturally as you can -- like what you would normally do in decisions on opening (clicking those links) / skipping / reading these emails as if you were not in this study. You will receive 2-4 emails like this per week from email-study@umn.edu during Nov to Dec. Please email Ruoyan at kong0135@umn.edu if you have any questions about this study. '+
                                            '</div>',
                                    },
                                }))
                            }
                            await delay(1000);
                            that.progress += 1;
                        }
                        return;
                    }
                    var newsletter_name = this.newsletterDict[this.newsletterId];
                    var subject = this.subject;
                    add_mail_promises.push(add_mail(newsletter_name, subject, this))
                    Promise.all(add_mail_promises).then(()=> {
                        // this.users.forEach(function (item) {
                        //     var link = "https://www.comm-tool.com/test_view/"+item.userId+"/"+postId;
                        //     promises.push(db.collection('mail').add({
                        //         to: item.email,
                        //         from: this.newsletterDict[this.newsletterId] + ' <email-study@umn.edu>',
                        //         message: {
                        //             subject: this.subject,
                        //             html: '<a href='+link+' target="_blank">click to read</a>',
                        //         },
                        //     }))
                        // },this);
                        var link = "https://www.comm-tool.com/report/" + this.user.uid + "/" + postId;
                        var message_link = "https://www.comm-tool.com/email/" + this.newsletterId;
                        var dashboard_link = "https://www.comm-tool.com/dashboard";
                        promises.push(db.collection('mail').add({
                            to: this.user.email,
                            message: {
                                subject: "Evaluation started! " + this.subject,
                                html: '<!DOCTYPE html><html lang="en"><body>' +
                                    '<div>' +
                                    'Your evaluation of ' + this.subject + " has started successfully. We'll send you a reminder email at the first 9 am after 24 hours. Please come back to check the following reports then:" +
                                    '</div>' +
                                    '<div>' +
                                    '1. Dashboard of email performance <a href=' + dashboard_link + ' target="_blank">' + dashboard_link + '</a>' +
                                    '</div>' +
                                    '<div>' +
                                    '2. Dashboard of message performance <a href=' + message_link + ' target="_blank">' + message_link + '</a>' +
                                    '</div>' +
                                    '<div>' +
                                    '3. Sample of this email <a href=' + link + ' target="_blank">' + link + '</a>' +' (you can reply your recipients on this page; your reply will be shown anonymously as from the sender).'+
                                    '</div>' +
                                    '<div>' +
                                    'Please email Ruoyan at kong0135@umn.edu if you have any questions.' +
                                    '</div>' +
                                    '</body></html>'
                            },
                        }))
                        var items = Promise.all(promises);
                        var that = this;
                        items.then(function () {
                            that.waiting_msg = "Sent job scheduled successfully! Redirecting to dashboard now"
                            that.$router.push('/email/' + that.newsletterId);
                        })
                        }, promises
                    )
                })
                    .catch((error) => {
                        console.error("Error adding document: ", error);
                        alert("Failed to send the email. Please retry or send the following error to Ruoyan at kong0135@umn.edu "+error.toString());
                        this.waiting_msg = "";
                    });
            },
            get_head(array){
                var i = 0;
                while(i<array.length){
                    if ((array[i]!==undefined) && (array[i].length>0)){
                        array[i] = this.strip(array[i]);
                        if ((array[i][0] !== '<')&&(array[i][0] !== ' ')&&(!array[i].includes('nbsp'))) return array[i]
                    }
                    i += 1;
                }
                return array[0]
            },
            strip(html){
                let doc = new DOMParser().parseFromString(html, 'text/html');
                return doc.body.textContent || "";
            },
            word_count(html){
                let doc = new DOMParser().parseFromString(html, 'text/html');
                var cleantext = doc.body.textContent || "";
                const arr = [...cleantext.matchAll(/[\w']+/g)];
                return arr.length;
            },
            click_comment: function(index){
                this.html_list[index].show_comment = !this.html_list[index].show_comment;
                this.$nextTick(() => {
                    this.html_list = [...this.html_list];
                });
            },

            submit_comment: function(index){
                if (this.comment_tmp.length>0) {
                    this.html_list[index].all_comments.push({text:this.comment_tmp, time:new Date().toLocaleString(), userId: this.user.uid, sender: true});
                    this.comment_tmp = "";
                    this.$nextTick(() => {
                        this.html_list = [...this.html_list];
                    });
                    this.$refs["comment"+index.toString()][0].$el.value = "";
                }

            },

            undo: function() {
                this.html_list = JSON.parse(JSON.stringify(this.last_html_list));
            },

            upload: function() {
                if (this.newsletterId === 'new' || this.newsletterId === 'create_new'){
                    this.$alert("Please select or create a newsletter for this email.");
                    return;
                }
                if (this.email_html === ''){
                    this.$alert("Please copy and paste the email to the textarea.");
                    return;
                }
                if (this.subject === ''){
                    this.$alert("Please input a subject for this email.");
                    return;
                }
                this.newsletter_options = [{text: this.newsletterDict[this.newsletterId], value: this.newsletterId}];
                db.collection('newsletter').doc(this.newsletterId).get().then((document) => {
                    if (document.exists) {
                        this.audience = document.data().audience;
                        this.size = document.data().n_recipient;
                        this.users = []
                        db.collection("users").where("recipient_checked", "==", true)
                            .where("demo","==",(this.demo || this.$store.state.demo)).get().then((querySnapshot) => {
                            querySnapshot.forEach((doc) => {
                                if (this.audience.includes(doc.data().category)) {
                                    if (this.demo || this.$store.state.demo){
                                        if (doc.data().demo !== true) return;
                                    }else{
                                        if (doc.data().demo === true) return;
                                    }
                                    this.users.push(doc.data());
                                }
                            });
                            this.reload = true;
                            // const matchAll = require('string.prototype.matchall')
                            // matchAll.shim()
                            // var re = /<table role="presentation" width="100%" cellspacing="0" cellpadding="0">[^]*?<\/table>/;
                            var heading_re;
                            if (this.newsletterDict[this.newsletterId].toLowerCase().includes("brief")) {
                                heading_re = /((<h3([^]*?)>([^]*?)<\/h3>)|(<strong([^]*?)>([^]*?)<\/strong>))/;
                            }else{
                                heading_re = /((<h3([^]*?)>([^]*?)<\/h3>)|(<h2([^]*?)>([^]*?)<\/h2>)|(<strong([^]*?)>([^]*?)<\/strong>))/;
                            }

                            var table_re;
                            // var brief_head_re = /(<td valign="top">(\s*)<h2>([^]*?)<\/h2>(\s*)<\/td>)/;
                            if (!this.newsletterDict[this.newsletterId].toLowerCase().includes("research")) {
                                table_re = /(<table([^]*?) role="presentation" width="100%" cellspacing="0" cellpadding="0"([^]*?)>([^]*?)<\/table>)/;
                            }else {
                                table_re = /(<table([^]*?) role="presentation" width="100%" cellspacing="0" cellpadding="0"([^]*?)>([^]*?)<\/table>(\s*)<table)/;
                            }
                            var p_re = /(<h3([^]*?)>([^]*?)<\/h3>\n<p([^]*?)>([^]*?)<\/p>)|(<h3([^]*?)>([^]*?)<\/h3>\n<ul([^]*?)>([^]*?)<\/ul>)|(<h2([^]*?)>([^]*?)<\/h2>\n<ul([^]*?)>([^]*?)<\/ul>)|(<h2([^]*?)>([^]*?)<\/h2>\n<p([^]*?)>([^]*?)<\/p>)|(<p><strong([^]*?)>([^]*?)<\/p>)/;

                            if (this.newsletterDict[this.newsletterId].toLowerCase().includes("communication blog")){
                                this.email_html = this.email_html.replaceAll(
                                    /<a/g,
                                    '<a style="color: #7A0019;text-decoration: underline;"'
                                )
                            }
                            if (this.newsletterDict[this.newsletterId].toLowerCase().includes("research")){
                                this.email_html = this.email_html.replaceAll(
                                    /<table class="stylingblock-content-wrapper" role="presentation" width="100%" cellspacing="0" cellpadding="0">(\s*)<tbody>(\s*)<tr>(\s*)<td class="stylingblock-content-wrapper camarker-inner">(\s*)<h2>(\s*)<span style="color: #ffffff;">/g,
                                    '<table class="stylingblock-content-wrapper" role="presentation" width="100%" cellspacing="0" cellpadding="0"  style="background-color: #005581; min-width: 100%; ">' +
                                    '<tbody>' +
                                    '<tr>' +
                                    '<td class="stylingblock-content-wrapper camarker-inner">' +
                                    '<h2><span style="color: #ffffff;">'
                                )
                                this.email_html = this.email_html.replaceAll(
                                    /<table class="stylingblock-content-wrapper" role="presentation" width="100%" cellspacing="0" cellpadding="0">(\s*)<tbody>(\s*)<tr>(\s*)<td class="stylingblock-content-wrapper camarker-inner">(\s*)<h2>(\s*)<strong>News/g,
                                    '<table class="stylingblock-content-wrapper" role="presentation" width="100%" cellspacing="0" cellpadding="0" style="background-color: #005581; min-width: 100%; ">\n' +
                                    '<tbody>\n' +
                                    '<tr>\n' +
                                    '<td class="stylingblock-content-wrapper camarker-inner">\n' +
                                    '<h2><strong style="color:#ffffff;">News\n'
                                )
                                this.email_html = this.email_html.replaceAll(
                                    /<div><strong>/g,
                                    '<div><strong style="color:#005581;">'
                                )
                                this.email_html = this.email_html.replaceAll(
                                    /<p><strong>/g,
                                    '<p><strong style="color:#005581;">'
                                )
                                this.email_html = this.email_html.replaceAll(
                                    /<h3><strong>/g,
                                    '<h3><strong style="color:#005581;">'
                                )
                                this.email_html = this.email_html.replaceAll(
                                    /<td class="stylingblock-content-wrapper camarker-inner"><strong>/g,
                                    '<td class="stylingblock-content-wrapper camarker-inner"><strong style="color:#005581;">'
                                )
                                this.email_html = this.email_html.replaceAll(
                                    /<a/g,
                                    '<a style="color: #7A0019;text-decoration: underline;"'
                                )
                                this.email_html = this.email_html.replaceAll(
                                    /<img/g,
                                    '<img style="float: right"'
                                )

                            }

                            var match = this.email_html.match(table_re);
                            // if (!match) match = this.email_html.match(table_re);
                            var cur_string;
                            var tmp;
                            var head;
                            // var ind = 0;
                            this.html_list = [];
                            if (!match){
                                tmp = this.email_html;
                                head = tmp.match(heading_re);
                                tmp = "<div style='max-width: 600px'>"+tmp+"</div>"
                                if (head) {
                                    this.html_list.push({data: [tmp, true, true, this.get_head(head)], show_comment: false,all_comments:[]});
                                }else{
                                    this.html_list.push({data: [tmp, true, true, this.subject], show_comment: false,all_comments:[]});
                                }
                            }else {
                                var last_index = 0;
                                var match_index;
                                var n_word;
                                while (match) {
                                    match_index = last_index+match.index;
                                    if (match_index - last_index > 200){
                                        tmp = this.email_html.substring(last_index, match_index);
                                        head = tmp.match(heading_re);
                                        if (head) {
                                            if (this.newsletterDict[this.newsletterId].toLowerCase().includes("research")) tmp = "<div style='max-width: 600px; border-bottom: 6px dotted #FFCC33'>"+tmp+"</div>"
                                            else tmp = "<div style='max-width: 600px'>"+tmp+"</div>"
                                            n_word = this.word_count(tmp);
                                            this.html_list.push({data: [tmp, (n_word>20), true, this.get_head(head)], show_comment: false,all_comments:[]});
                                        }else{
                                            if ((this.html_list.length>=1) && !this.html_list[this.html_list.length-1].data[1]){
                                                tmp = "<div style='max-width: 600px'>"+tmp+"</div>"
                                                this.html_list[this.html_list.length-1].data[0] += tmp;
                                            }else {
                                                tmp = "<div style='max-width: 600px'>"+tmp+"</div>"
                                                this.html_list.push({data: [tmp, false, true, "click to add title"], show_comment: false,all_comments:[]});
                                            }
                                        }
                                        // ind += 1;
                                    }
                                    if (this.newsletterDict[this.newsletterId].toLowerCase().includes("research")) match[0] = match[0].substring(0,Math.max(match[0].length-6,0));
                                    tmp = match[0];
                                    head = tmp.match(heading_re);

                                    if (head) {
                                        if (this.newsletterDict[this.newsletterId].toLowerCase().includes("research")) tmp = "<div style='max-width: 600px; border-bottom: 6px dotted #FFCC33'>"+tmp+"</div>"
                                        else tmp = "<div style='max-width: 600px'>"+tmp+"</div>"
                                        n_word = this.word_count(tmp);
                                        this.html_list.push({data: [tmp, (n_word>20), true, this.get_head(head)], show_comment: false,all_comments:[]});
                                    }else{
                                        tmp = "<div style='max-width: 600px'>"+tmp+"</div>";
                                        if ((this.html_list.length>=1) && !this.html_list[this.html_list.length-1].data[1]){
                                            this.html_list[this.html_list.length-1].data[0] += tmp;
                                        }else {
                                            this.html_list.push({data: [tmp, false, true, "click to add title"], show_comment: false,all_comments:[]});
                                        }
                                    }
                                    last_index = match_index + match[0].length;
                                    cur_string = this.email_html.substring(last_index, this.email_html.length);
                                    if (this.newsletterDict[this.newsletterId].toLowerCase().includes("brief")){
                                        match = cur_string.match(table_re);
                                        if (!match) match = cur_string.match(p_re);
                                    }else if (this.newsletterDict[this.newsletterId].toLowerCase().includes("communication blog")){
                                        match = cur_string.match(table_re);
                                        if (!match) match = cur_string.match(p_re);
                                    }
                                    else if (this.newsletterDict[this.newsletterId].toLowerCase().includes("research")) {
                                        match = cur_string.match(table_re);
                                        if (!match) match = cur_string.match(p_re);
                                    }else {
                                        match = cur_string.match(p_re);
                                        if (!match) match = cur_string.match(table_re);
                                    }
                                }
                                if (last_index+200<this.email_html.length){
                                    tmp = this.email_html.substr(last_index,this.email_html.length);
                                    head = tmp.match(heading_re);
                                    tmp = "<div style='max-width: 600px'>"+tmp+"</div>"
                                    if (head) {
                                        n_word = this.word_count(tmp);
                                        this.html_list.push({data: [tmp, (n_word>20), true, this.get_head(head)], show_comment: false,all_comments:[]});
                                    }else{
                                        if ((this.html_list.length>=1) && !this.html_list[this.html_list.length-1].data[1]){
                                            this.html_list[this.html_list.length-1].data[0] += tmp;
                                        }else {
                                            this.html_list.push({data: [tmp, false, true, "click to add title"], show_comment: false,all_comments:[]});
                                        }
                                    }
                                }

                            }
                            if (this.html_list.length === 1){
                                if (this.html_list[0].data[3] === "click to add title") this.html_list[0].data[3] = this.subject;
                                this.html_list[0].data[1] = true;
                            }
                            this.last_html_list = JSON.parse(JSON.stringify(this.html_list));
                        })
                    } else {
                        this.alert("Newsletter does not exist. Please create a new one or contact kong0135@umn.edu")
                        return;
                    }
                })

                // this.email_html =
                // "<!DOCTYPE html PUBLIC \"-//W3C//DTD HTML 4.01 Transitional//EN\" \"http://www.w3.org/TR/html4/loose.dtd\">\n" +
                // "<!-- saved from url=(0218)https://view.ecommunications2.umn.edu/?qs=7d135d70c141795bb8b9282941ba4d88e05525887791438b6cb48af9650dd54a83a3530585e053032485bdbfd180765d6083f8974f1d05d26065ba5ecdf2803d449226948ba092c88516dc4997171f3ce75ec341f8c75bd0 -->\n" +
                // "<html><head><meta http-equiv=\"Content-Type\" content=\"text/html; charset=UTF-8\"><meta name=\"ROBOTS\" content=\"NOINDEX, NOFOLLOW\"><meta name=\"referrer\" content=\"no-referrer\">\n" +
                // "        \n" +
                // "        <meta http-equiv=\"X-UA-Compatible\" content=\"IE=edge\">\n" +
                // "        <meta name=\"viewport\" content=\"initial-scale=1.0\">\n" +
                // "        <title>email</title>\n" +
                // "        <style>\n" +
                // "            .ReadMsgBody{width: 100%;}\n" +
                // "            .ExternalClass {width: 100%;}\n" +
                // "            .ExternalClass, .ExternalClass p, .ExternalClass span, .ExternalClass font, .ExternalClass td, .ExternalClass div {line-height: 100%;}\n" +
                // "            body {-webkit-text-size-adjust:100%; -ms-text-size-adjust:100%;margin:0 !important; padding:0 !important;}\n" +
                // "            p {margin: 1em 0;}\n" +
                // "            table td {border-collapse: collapse;}\n" +
                // "            img {outline:0;}\n" +
                // "            a img {border:none;}\n" +
                // "            p {margin: 1em 0;}\n" +
                // "            @-ms-viewport{width: device-width;}\n" +
                // "            @media screen and (max-width: 640px) {\n" +
                // "                table.header,\n" +
                // "                table.container,\n" +
                // "                table.container table {\n" +
                // "                    width: 100% !important;\n" +
                // "                }\n" +
                // "            }\n" +
                // "        </style>\n" +
                // "    </head>\n" +
                // "<body bgcolor=\"#f2f2f2\" style=\"background-color: #f2f2f2; margin:0;padding:0;\" data-new-gr-c-s-check-loaded=\"14.1052.0\" data-gr-ext-installed=\"\"><style type=\"text/css\">\n" +
                // "div.preheader \n" +
                // "{ display: none !important; } \n" +
                // "</style>\n" +
                // "<div class=\"preheader\" style=\"font-size: 1px; display: none !important;\">Board of Regents February meeting highlights; Features: Focusing on the issues; For the love of Minnesota; Protecting ideas; Awards and Recognition: U in the News features highlights of University faculty and staff cited in the media; and more.</div>\n" +
                // "<table width=\"100%\" cellpadding=\"0\" cellspacing=\"0\" border=\"0\" bgcolor=\"#f2f2f2\" style=\"background-color: #f2f2f2;\"><tbody>"+
                // this.email_html
                // +"</tbody></table></body></html>";
            }
        },
        watch: {
        }
    }
</script>
<style scoped>
    img {
        max-width: 600px;
    }
</style>