<style scoped>

    form {
        max-width: 600px;
        margin: 30px auto;
        background: #fff;
        text-align: left;
        padding: 20px;
        border-radius: 10px;
    }

    label {
        color: #aaa;
        display:inline-block;
        margin: 25px 0 15px;
        text-transform: uppercase;
    }

    input, select {
        display: block;
        padding: 10px 6px;
        width: 100%;
        box-sizing: border-box;
        border: none;
        border-bottom: 1px solid #ddd;
        color: #555;
    }

    input[type="checkbox"] {
        display: inline-block;
        width:16px;
        margin: 0 10px 0;
        position: relative;
        top: 2px;
    }

    .pill {
        display: inline-block;
        margin: 20px 10px 0 0 ;
        padding: 6px 12px;
        border-radius: 20px;
        font-size: 12px;
        cursor: pointer;
        background: #eee;
    }

    button {
        background: rgb(7, 24, 7);
        border: 0;
        padding: 10px 20px;
        color: white;
        border-radius: 20px;
    }

    .submit {
        text-align: center;
    }

    .error {
        color: #ff0000;
        margin-top: 10px;
        font-size: 0.8em;
        font-weight: bold;
    }
</style>
<template>
    <b-container style="max-width: 1000px;">
        <div class="row" style="margin-top:20px;">
            <form @submit.prevent="handleSubmit">
                <label>Campus : <span style="color: red"><strong>* </strong></span></label>
                <select v-model="campus" required>
                    <option value="Crookston">Crookston</option>
                    <option value="Duluth">Duluth</option>
                    <option value="Morris">Morris</option>
                    <option value="Rochester">Rochester</option>
                    <option value="Twin Cities">Twin Cities</option>
                </select>

                <label >Your unit: <span style="color: red"><strong>* </strong></span></label>

                <b-form-input
                        id="input-1"
                        required
                        v-model="unit"
                        placeholder="e.g., college, department, office name"
                ></b-form-input>

                <label>Job Family : <span style="color: red"><strong>* </strong></span></label>
                <b-form-radio-group
                        id="job_family"
                        v-model="job_family"
                        name="radio-options2"
                        stacked
                        required
                >
                    <b-form-radio
                            v-for="job_family_option in job_family_options"
                            :value="job_family_option"
                            :key="job_family_option"
                    >
                        {{ job_family_option }}
                    </b-form-radio>
                </b-form-radio-group>

                <label>Which things are you mostly interested in reading and receiving (select 1 or more): <span style="color: red"><strong>* </strong></span></label>
                <b-form-checkbox-group
                        id="interest"
                        v-model="interest"
                        name="radio-options3"
                        stacked
                >

                    <b-form-checkbox
                            v-for="interest_option in interest_options"
                            :value="interest_option"
                            :key="interest_option"
                    >
                        {{ interest_option }}
                    </b-form-checkbox>
                    <b-form-invalid-feedback :state="state">Please select at least one interest.</b-form-invalid-feedback>
                </b-form-checkbox-group>

                <label>Where do you hear from us : <span style="color: red"><strong>* </strong></span></label>
                <b-form-radio-group
                        id="channel_family"
                        v-model="channel_family"
                        name="radio-options4"
                        stacked
                        required
                >
                    <b-form-radio
                            v-for="channel_family_option in channel_family_options"
                            :value="channel_family_option"
                            :key="channel_family_option"
                    >
                        {{ channel_family_option }}
                    </b-form-radio>
                </b-form-radio-group>



                <!--                <div>-->
                <!--                    <input type="checkbox" value="highschool" v-model="education" >-->
                <!--                    <label>High School</label>-->
                <!--                    <input type="checkbox" value="bechelors" v-model="education" >-->
                <!--                    <label>Bachelor's Degree</label>-->
                <!--                    <input type="checkbox"  value="masters" v-model="education" >-->
                <!--                    <label>Master's Degree</label>-->
                <!--                </div>-->
                <label><b>Your identity will be kept anonymous but your unit / job family / interests might be shown to the email senders.</b></label>
                <div>

                    <p><input type="checkbox" v-model="terms" required>Please read the <a href="https://docs.google.com/document/d/1HOZWQA9l37F3NnV3i149xXbDEu2wuwM8/edit?usp=sharing&ouid=116961651394772297273&rtpof=true&sd=true" target="_blank">consent form</a> and check here if you accept. <span style="color: red"><strong>* </strong></span></p>
                </div>
                <div class="button">
                    <button class="submit" type="submit">submit</button>
                </div>
            </form>

            <!--            <p> Email: {{ email }} </p>-->
            <!--            <p> Password: {{ password }} </p>-->
            <!--            <p> Role: {{ role }} </p>-->
            <!--            <p> Education: <span v-for="edu in education" :key="edu"> {{ edu }}</span></p>-->
            <!--            <p> Terms : {{ terms }} </p>-->
        </div>

    </b-container>
</template>

<script>
    import { db } from "../firebaseConfig.js"
    import { auth } from "@/firebaseConfig"
    // import firebase from 'firebase'
    export default {
        name: "EmployeeRegister",
        components: {
        },
        created () {
            document.title = "Comm Tool: Employee Registration";

        },
        beforeCreate: function() {
            auth.onAuthStateChanged(user => {
                console.log("user state:", user);
                // uncomment above to check out which user properties are available.
                if (!user) {
                    //this.$router.push("/"); this have already been done in router
                }else{
                    this.user = user;
                    // this.todos = this.$firestoreRefs['todos'].orderBy("timestamp", "desc").where("done", "==", false).where("userId", "==", this.user);

                }
            });


        },
        computed: {
            state() {
                return this.interest.length >=1
            }
        },

        data : function() {
            return {
                campus: '',
                unit: '',
                interest: [],
                interest_options:[
                    "Award/Recognition to University, Faculty, Staff, Students",
                    "Program & Award Applications/Announcements",
                    "University History/Celebrations",
                    "Health Wellness Resources/COVID",
                    "Youth, Children@UMN",
                    "Policies/Admin News/Governance",
                    "Health/Biology Research Stories",
                    "Engineering Science Research Stories",
                    "History/Social Science Research Stories",
                    "Climate/Eco/Agriculture",
                    "Talk/Symposium/Lectures Annoucements",
                    "Art & Museums",
                    "Operations Awareness/Facility Closures",
                    "University Program Success Stories",
                    "Community Service/Social Justice/Underserved Population",
                    "Fundraising & Development",
                    "Student/Alumni Stories",
                    "Faculty & Staff Stories",
                    "Tech Tool Updates/Workshops",
                    "Sports & Spirit"
                ],
                communicator: false,
                terms: false,
                job_family: '',
                job_family_options:["Administration & Advancement & Communication Staff", "Campus Operation Staff (e.g., facilitates maintenance, dining services, police, bookstore, athletics operations, etc.)", "Faculty, Teaching & Research Staff, Librarians, Museum Curators and Directors, etc.", "Healthcare Staff (nurses, doctors, athletic trainers, etc.)", "Human Resource & Finance Staff (e.g., accountants, HR specialists)", "Information Technology Staff", "Student Services Staff (advisors, student union staff, financial aid staff, etc.)"],
                channel_family: '',
                channel_family_options:["Email", "Poster", "Friends / Peers", "Slack, Social Media","Others"],
            }
        },

        // firestore: function () {
        //     // if (!firebase.auth().currentUser){
        //     //     return {}
        //     // }else{
        //     //     return {
        //     //         // mylists: db.collection("lists").where("shared_with", "array-contains", firebase.auth().currentUser.email).orderBy("created_at", "desc"),
        //     //     }
        //     // }
        //
        //
        //
        // },

        methods:{
            handleSubmit() {
                var category;
                if (this.interest.length === 0) return;
                if (this.job_family === "Faculty, Teaching & Research Staff, Librarians, Museum Curators and Directors, etc."){
                    category = "faculty";
                }else{
                    category = "staff";
                }
                if (this.channel_family.length === 0) return;
                if (this.terms !== true) return;
                db.collection("users").doc(this.user.uid).set({
                    displayName: this.user.displayName,
                    email: this.user.email,
                    campus: this.campus,
                    unit: this.unit,
                    interest: this.interest,
                    category: category,
                    communicator: this.communicator,
                    communicator_checked: false,
                    job_family: this.job_family,
                    channel_family: this.channel_family,
                    recipient_checked: false,
                    userId: this.user.uid,
                    group: '',
                    timestamp: new Date(),
                    demo: this.$store.state.demo
                })
                    .then(() => {
                        console.log("Document successfully written!");
                        this.$router.push("/thank");
                        this.$router.go();
                    })
                    .catch((error) => {
                        console.error("Error writing document: ", error);
                    });

                // console.log(this.education);
                // console.log(this.terms);


            }
        },
    }
</script>
