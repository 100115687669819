<template>
    <div class="row" >
        <b-col sm="12" v-if="!this.user || (this.html_list.length===0)" style="max-width: 700px; margin-top: 40px; margin-right: auto; margin-left: auto">
            Loading ...
        </b-col>
        <b-col sm="12" v-if="this.user && (this.html_list.length>0)" >
            <div class="row observable" style="max-width: 700px; margin-top: 5px; margin-right: auto; margin-left: auto; padding-left: 10px; padding-right: 10px; text-align: left; bottom: 0"> * The version of the newsletter used for the study is a replica and is not an exact match.<br>Some of the formatting and styling will be slightly different. <br>For any questions, please contact Ruoyan at kong0135@umn.edu.</div>
            <li v-for="(item, index) in this.html_list" v-bind:key= index style="max-width: 700px; margin-right: auto; margin-left: auto; padding-left: 10px; padding-right: 10px; top: 0" ref="html_list" >
                <div v-if = item.data.data[2]>
                    <div class="row observable" >
                        <div class="maximg" v-html="item.data.data[0]" @click="click_link_tap(index)" style=" text-align: left; margin-left: 10px;" ></div>
                        <!--                        <editor-->
                        <!--                                v-model = "item.data.data[0]"-->
                        <!--                                api-key="6amjl4rlxjw7zvlf91tg3iz4m9ntrvj0tk1543pxopouuq9k"-->
                        <!--                                branding = false-->
                        <!--                                :init="{-->
                        <!--                                        init_instance_callback: ed => {-->
                        <!--                                            ed.on('click', function (e) {-->
                        <!--                                              click_link(index, ed, e);-->
                        <!--                                            });-->
                        <!--                                            ed.on('tap', function (e) {-->
                        <!--                                              click_tap(index, ed);-->
                        <!--                                            });-->

                        <!--                                         },-->
                        <!--                                         menubar: false,-->
                        <!--                                         plugins: ['autoresize'-->
                        <!--                                         ],-->
                        <!--                                         content_style: '.mce-content-body audio[data-mce-selected], .mce-content-body embed[data-mce-selected], .mce-content-body img[data-mce-selected], .mce-content-body object[data-mce-selected], .mce-content-body table[data-mce-selected], .mce-content-body video[data-mce-selected]{\n'+-->
                        <!--                                            '    outline: none;\n'+-->
                        <!--                                            '    outline-width: 0px;\n'+-->
                        <!--                                            '}' +-->
                        <!--                                             '.mce-content-body div.mce-resizehandle{' +-->
                        <!--                                             'border-width: 0;\n'+-->
                        <!--                                             'width: 0;\n'+-->
                        <!--                                             'height: 0;\n'+-->
                        <!--                                              '}',-->
                        <!--                                         toolbar:-->
                        <!--                                           '',-->
                        <!--                                         content_css: '../style/editor.css',-->
                        <!--                                         autoresize_on_init: true,-->
                        <!--                                         visual: false,-->
                        <!--                                         autoresize_bottom_margin: 0,-->
                        <!--                                         branding: false,-->
                        <!--                                         statusbar: false,-->
                        <!--                                       }"-->
                        <!--                        />-->
                    </div>
                    <div class="row" v-if = "item.data.data[1] && show_survey">
                        <b-col sm="8" style="text-align: left">
                            <p v-if="item.relevant>0" @click="add_upvote(index)"><font-awesome-icon icon="fa-solid fa-thumbs-up" /> relevant to me
                                <font-awesome-icon v-b-tooltip.hover.righttop="'Clicking here if this message is relevant to you.'"
                                                   icon="fa-solid fa-question-circle"/>
                            </p>
                            <p v-if="item.relevant===0" @click="add_upvote(index)">&#9634; relevant to me
                                <font-awesome-icon v-b-tooltip.hover.righttop="'Clicking here if this message is relevant to you.'"
                                                   icon="fa-solid fa-question-circle"/>
                            </p>
                        </b-col>
                        <b-col sm="4" style="text-align: right">
                            <font-awesome-icon icon="fa-solid fa-comment-dots" style="margin-right: 5px" />
                            <span v-b-tooltip.hover.righttop="'Click here to comment. Your comment will be anonymously sent to the sender, will NOT be shown to the other participants.'">
                                <font-awesome-icon
                                        icon="fa-solid fa-question-circle"/>
                                </span>
                        </b-col>

                    </div>


                </div>

                <div v-if = "item.data.data[1] && show_survey">
                    <div v-for="(it, ind) in item.all_comments" v-bind:key= "'A'+ind" >

                        <Comment :comment=it />
                    </div>
                    <!--                    <div style="    border: 1px;-->
                    <!--                                     border-color: #dbd5d5;-->
                    <!--                                     border-style: dashed;">-->
                    <!--                        <div class="row" @click="click_comment(index)">-->
                    <!--                            <b-col sm="12" >-->
                    <!--                                <span v-if="!item.show_comment"  style="margin-right: auto; margin-left: auto">		&#9661; show all comments</span>-->
                    <!--                                <span v-if="item.show_comment"  style="margin-right: auto; margin-left: auto">		&#9651;</span>-->
                    <!--                            </b-col>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                    <div class="row">
                        <b-col sm="12" >
                            <b-form-textarea  max-rows="6" :ref="'comment' + index" placeholder="Type to post questions here to your recipients" v-on:change="event=>comment_tmp=event" ></b-form-textarea>
                        </b-col>
                    </div>
                    <div class="row">
                        <b-col sm="12" style="text-align:right">
                            <b-button  style="margin-top: 5px" @click="submit_comment(index)" v-b-tooltip.hover.righttop="'Click here to comment. Your comment will be anonymous.'">Comment</b-button>
                        </b-col>
                    </div>
                </div>
            </li>
            <b-alert style=" position:fixed;
                                top: 0px;
                                left: 0px;
                                width: 100%;
                                z-index:9999;
                                border-radius:0px"
                     :show="dismissCountDown<=10"
                     dismissible
                     fade
                     variant="warning"
                     @dismissed="dismissCountDown=600"
            >
                <p>Do you want to stay on this page ?</p>
                <b-button variant="primary" @click="dismissCountDown=600"> Yes, stay on this page.</b-button>
                <div>Log out in {{ dismissCountDown }} seconds ... </div>

            </b-alert>


            <ModalIdle v-if="isIdle"/>
        </b-col>
    </div>

</template>

<script>
    import '../style/edit.css';
    import Comment from "../components/Comment";
    import ModalIdle from "../components/ModalIdle";
    // import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    // import { db } from "../firebaseConfig.js"
    // import Editor from '@tinymce/tinymce-vue'
    // import RangeSlider from 'vue-range-slider'
    // you probably need to import built-in style
    import 'vue-range-slider/dist/vue-range-slider.css'
    import {db} from "../firebaseConfig";
    import  * as tf from '@tensorflow/tfjs';
    import dateFormat from "dateformat";
    export default {
        name: "TestView",
        components: {
            Comment,
            // editor: Editor,
            ModalIdle,
            // "font-awesome-icon": FontAwesomeIcon
        },
        watch: {
            dismissCountDown(newvalue){
                if (newvalue<0){
                    this.$router.push("/logout");
                }
            }
        },
        props: ['postId', 'userId'],
        async created () {
            setInterval(() => {
                if (this.idle_time >= 1000) this.idle_time -= 1000;
                if (!this.$store.state.idleVue.isIdle){
                    this.idle_time = 60*1000;
                }
                if (this.idle_time < 1) {
                    this.should_record = false;
                }else{
                    this.should_record = true;
                }
            }, 1000);
            document.title = "Comm Tool: TestView";
            this.second_model = await tf.loadLayersModel('https://storage.googleapis.com/commtool-6d611.appspot.com/json/log_model3/model.json');
            var collection = db.collection('users');
            var docID = this.$options.propsData.userId;
            // var re = /<.*?>|&([a-z0-9]+|#[0-9]{1,6}|#x[0-9a-f]{1,6});/;
            this.n_word = 0;

            collection.doc(docID).get().then((document) => {
                this.user = document.data();
                if (this.user.group === "explicit") this.show_survey = true;
                this.html_list = [];
                db.collection("messages").where("postId", "==", this.$options.propsData.postId).orderBy("index").get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        var tmp = doc.data();
                        tmp.postId = doc.id;
                        tmp.all_comments = [];
                        tmp.comments = [];
                        tmp.all_relevant = 0;
                        tmp.relevant = 0;
                        tmp.clicked = 0
                        tmp.time = 0;
                        tmp.visibleHeight = {};
                        tmp.MSG_svt = 0;
                        tmp.MSG_sy = 0;
                        tmp.MSG_sh = 0;
                        tmp.MSG_tmy = 0;
                        tmp.MSG_tsclk = 0;
                        tmp.last_click = 0;
                        tmp.MSG_y = -1;
                        tmp.MSG_h = 0;
                        var cleantext = this.strip(tmp.data.data[0]);//tmp.data.data[0].replace(re, '');
                        const arr = [...cleantext.matchAll(/[\w']+/g)];
                        tmp.n_word = arr.length;
                        if (tmp.data.data[1] === true) {
                            this.n_word += tmp.n_word;
                        }
                        this.html_list.push(tmp);
                    });
                    this.n_word = Math.max(this.n_word, 40);
                    this.html_list.sort((a, b) => (a.index > b.index) ? 1 : -1);
                    var id = this.userId + "_" + this.postId;
                    var docRef = db.collection("read_post").doc(id);
                    docRef.get().then((doc) => {
                        if (doc.exists) {
                            var tmp = doc.data();
                            this.read_time = tmp.time;
                            if (tmp.read_level === undefined){
                                this.read_level = 0;
                            }else{
                                this.read_level = tmp.read_level;
                            }
                        } else {
                            this.read_time = 0;
                            db.collection("read_post").doc(id).set({
                                userId: this.userId,
                                postId: this.postId,
                                comments: 0,
                                relevant: 0,
                                clicked: 0,
                                time: 0,
                                timestamp: new Date(),
                                read_level: 0
                            })
                        }

                    }).catch((error) => {
                        console.log("Error getting document:", error);
                    });
                    db.collection("read_message").where("postId", "==", this.postId).get().then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            var tmp = doc.data();
                            tmp.comments.forEach((comment) => {
                                if (comment.userId === this.userId || comment.sender === true) this.html_list[tmp.index].all_comments.push(comment);
                            });
                            if (tmp.userId === this.userId){
                                this.html_list[tmp.index].relevant = tmp.relevant;
                                this.html_list[tmp.index].clicked = tmp.clicked;
                                tmp.comments.forEach((comment) => {
                                    this.html_list[tmp.index].comments.push(comment);
                                });
                                this.html_list[tmp.index].time = tmp.time;
                                if (tmp.read_level === undefined){
                                    this.html_list[tmp.index].read_level = 0;
                                }else{
                                    this.html_list[tmp.index].read_level = tmp.read_level;
                                }
                            }
                            this.html_list[tmp.index].all_relevant += tmp.relevant;
                        });
                        this.html_list.forEach((element)=>{
                            element.all_comments = element.all_comments.sort(function(a, b) {
                                return  new Date(a.time)- new Date(b.time);
                            });
                        })
                    });
                })
            })
        },
        mounted() {
            window.setInterval(() => {
                this.updateVis();
            }, 100);
            window.setInterval(() => {
                this.updateTime();
            }, 1000);

        },
        beforeCreate: function() {
            document.addEventListener("visibilitychange", () => {

                this.isVisible = document.visibilityState === "visible";
            });
            document.addEventListener('mousemove', (e)=>this.onMouseUpdate(e), false);


        },
        data:  function(){
            return {
                mode: 1,
                comment_tmp: "",
                options: [
                    { text: 'mode1', value: 1 ,disabled: false},
                    { text: 'mode2', value: 2, disabled: false },
                    { text: 'mode3', value: 3, disabled: false },
                ],
                index:0,
                mouseY: 0,
                mouseX: 0,
                mousePageX: 0,
                mousePageY: 0,
                isVisible: true,
                componentKey: 0,
                html_list: [],
                user: null,
                show_survey: false,
                time: 0,
                n_word: 0,
                idle_time: 60*1000,
                should_record: true,
                entry: {},
                start_time: new Date(),
                MMF_x: [],
                MMF_y: [],
                mouseXs: [],
                mouseYs: [],
                MMF_x_inf: 0,
                MMF_y_inf: 0,
                MSF_y: [],
                pageYs: [],
                MSF_y_inf: 0,
                timestamp: "",
                user_pattern_cols : ['MMF_y_2',  'MMF_y_5', 'MMF_y_10', 'MMF_y_inf',  'MMF_x_2', 'MMF_x_5', 'MMF_x_10', 'MMF_x_inf',
                    'MSF_y_2', 'MSF_y_5', 'MSF_y_10', 'MSF_y_inf','MSF_clk', 'M_tclk'],
                user_session_cols : ['MMF_y_inf', 'MMF_x_inf','MSF_y_inf','MSF_clk'],
                user_tmp_cols : ['height_1', "height_2", "height_3", "MSG_tclk"],
                msg_tmp_cols : ['height_1', "height_2", "height_3", "MSG_tclk"],
                msg_session_cols : ['time_1', 'time_2','time_3','MSG_tmy', 'MSG_sh', 'MSG_sy','MSG_clk','MSG_svt'],
                dismissCountDown: 600
            }
        },

        computed: {
            isIdle() {
                return this.$store.state.idleVue.isIdle;
            }
        },
        firestore: function () {
            return {
                // post: db.collection("post").doc(this.postId)
            }
        },

        methods: {
            strip(html){
                let doc = new DOMParser().parseFromString(html, 'text/html');
                return doc.body.textContent || "";
            },
            update_read_post(id, field, value){
                if (!this.should_record) return;
                var docRef = db.collection("read_post").doc(id);
                docRef.get().then((doc) => {
                    if (doc.exists) {
                        db.collection("read_post").doc(id).update({[field]: value});
                    } else {
                        db.collection("read_post").doc(id).set({
                            userId: this.userId,
                            postId: this.postId,
                            comments: 0,
                            relevant: 0,
                            clicked: 0,
                            time: 0,
                            read: 0,
                            detail: 0,
                            timestamp: new Date()
                        }).then(
                            ()=>{db.collection("read_post").doc(id).update({[field]: value});}
                        )
                    }
                }).catch((error) => {
                    console.log("Error getting document:", error);
                });
            },
            update_read_message(id, field, value, index){
                if (!this.should_record) return;
                var docRef = db.collection("read_message").doc(id);
                docRef.get().then((doc) => {
                    if (doc.exists) {
                        db.collection("read_message").doc(id).update({[field]: value});
                    } else {
                        db.collection("read_message").doc(id).set({
                            userId: this.userId,
                            postId: this.postId,
                            comments: [],
                            relevant: 0,
                            clicked: 0,
                            time: 0,
                            index: index,
                            timestamp: new Date(),
                            read_level: 0
                        }).then(
                            ()=>{db.collection("read_message").doc(id).update({[field]: value});}
                        )
                    }
                }).catch((error) => {
                    console.log("Error getting document:", error);
                });
            },
            add_upvote: function(index){
                if (this.html_list[index].relevant === 0){
                    this.html_list[index].relevant = 1;
                    this.html_list[index].all_relevant += 1;
                } else {
                    this.html_list[index].relevant = 0;
                    this.html_list[index].all_relevant -= 1;
                }
                this.update_read_message(
                    this.userId+"_"+this.postId+"_"+this.html_list[index].index,
                    "relevant",
                    this.html_list[index].relevant,
                    index
                );
                var relevant = 0;
                this.html_list.forEach(value=>relevant+=value.relevant);
                this.update_read_post(
                    this.userId+"_"+this.postId,
                    "relevant",
                    relevant
                )
            },
            submit_comment: function(index){
                if (this.comment_tmp.length>0) {
                    this.html_list[index].comments.push({text:this.comment_tmp, time: new Date().toLocaleString(), userId: this.userId, sender: false});
                    this.html_list[index].all_comments.push({text:this.comment_tmp, time:new Date().toLocaleString(), userId: this.userId, sender: false});
                    this.html_list[index].show_comment = true;
                    this.comment_tmp = "";
                    this.update_read_message(
                        this.userId+"_"+this.postId+"_"+this.html_list[index].index,
                        "comments",
                        this.html_list[index].comments,
                        index
                    )
                    var comment = 0;
                    this.html_list.forEach(value=>comment+=value.comments.length);
                    this.update_read_post(
                        this.userId+"_"+this.postId,
                        "comments",
                        comment
                    )
                    this.$nextTick(() => {
                        this.html_list = [...this.html_list];
                    });
                    this.$refs["comment"+index.toString()][0].$el.value = "";
                }

            },
            changeMode: function(){
                for (var i = 0; i < this.html_list.length; i++) {
                    if (this.html_list[i].data.data[1]) {
                        // console.log(this.$refs["html_list"][i]);
                        this.html_list[i].time=0;
                    }
                }
            },

            click: function (e){
                var el = window;//.elementFromPoint(x,y);
                var ev = new MouseEvent('click', {
                    'screenX': e.screenX,
                    'screenY': e.screenY
                });
                el.dispatchEvent(ev);
            },

            click_link_tap: async function (index) {
                var now = new Date().getTime();
                this.html_list[index].last_click = now;

                this.isVisible = document.visibilityState === "visible";



                if (this.html_list[index].clicked === 0){
                    this.html_list[index].clicked = 1;
                    this.html_list[index].MSG_tsclk = now;
                    this.update_read_message(
                        this.userId+"_"+this.postId+"_"+this.html_list[index].index,
                        "clicked",
                        1,
                        index
                    );
                    var clicked = 0;
                    this.html_list.forEach(value=>clicked+=value.clicked);
                    this.update_read_post(
                        this.userId+"_"+this.postId,
                        "clicked",
                        clicked
                    )
                } else {
                    this.html_list[index].clicked = 0;
                }


            },


            click_link: async function (index, ed, e) {
                var now = new Date().getTime();
                function delay(time) {
                    return new Promise(resolve => setTimeout(resolve, time));
                }
                if (now - this.html_list[index].last_click <500) {
                    ed.setMode('readonly');
                    this.html_list[index].last_click = now;

                    this.click(e);
                    return;
                }
                this.html_list[index].last_click = now;
                ed.setMode('readonly');
                console.log("click");

                await delay(50);
                this.isVisible = document.visibilityState === "visible";


                if (!this.isVisible) {
                    if (this.html_list[index].clicked === 0){
                        this.html_list[index].clicked = 1;
                        this.html_list[index].MSG_tsclk = now;
                        this.update_read_message(
                            this.userId+"_"+this.postId+"_"+this.html_list[index].index,
                            "clicked",
                            1,
                            index
                        );
                        var clicked = 0;
                        this.html_list.forEach(value=>clicked+=value.clicked);
                        this.update_read_post(
                            this.userId+"_"+this.postId,
                            "clicked",
                            clicked
                        )
                    } else {
                        this.html_list[index].clicked = 0;
                    }
                }else{
                    ed.setMode('design');
                    ed.on('PostRender', function(){
                        ed.getBody().setAttribute('contenteditable', false);
                    });
                    ed.on('KeyPress', function(e){
                        e.preventDefault();
                        e.stopPropagation();
                    });
                }

            },

            click_tap: async function (index, ed) {
                var now = new Date().getTime();
                this.html_list[index].last_click = now;
                ed.setMode('readonly');
                console.log("click");
                function delay(time) {
                    return new Promise(resolve => setTimeout(resolve, time));
                }
                await delay(1000);
                this.isVisible = document.visibilityState === "visible";


                if (!this.isVisible) {
                    if (this.html_list[index].clicked === 0){
                        this.html_list[index].clicked = 1;
                        this.update_read_message(
                            this.userId+"_"+this.postId+"_"+this.html_list[index].index,
                            "clicked",
                            1,
                            index
                        );
                        var clicked = 0;
                        this.html_list.forEach(value=>clicked+=value.relevant);
                        this.html_list[index].MSG_tsclk = now;
                        this.update_read_post(
                            this.userId+"_"+this.postId,
                            "clicked",
                            clicked
                        )
                    } else {
                        this.html_list[index].clicked = 0;
                    }
                }else{
                    ed.setMode('design');
                    ed.on('PostRender', function(){
                        ed.getBody().setAttribute('contenteditable', false);
                    });
                    ed.on('KeyPress', function(e){
                        e.preventDefault();
                        e.stopPropagation();
                    });
                }

            },
            onMouseUpdate: function(e){
                // console.log(e.y);
                this.mouseX = e.x;
                this.mouseY = e.y;
                this.mousePageX = e.pageX;
                this.mousePageY = e.pageY;
            },
            sum: function(array){
                return array.reduce((partialSum, a) => partialSum + a, 0);
            },
            exp: function(array){
                return array.map((e) => Math.exp(e));
            },
            click_comment: function(index){
                this.html_list[index].show_comment = !this.html_list[index].show_comment;
                if (this.html_list[index].show_comment) this.html_list[index].show_bug = false;
                this.$nextTick(() => {
                    this.html_list = [...this.html_list];
                });
            },
            updateVis: function(){
                const callback = (entries, observer) =>{
                    var entry = entries[0];
                    var mode;
                    var index = observer.index;
                    if ( (entry.intersectionRatio>0)) {
                        this.html_list[index].isVisible = true;
                        mode = 3;
                        this.html_list[index].MSG_y = (entry.intersectionRect.bottom+entry.intersectionRect.top)/2/entry.rootBounds.height;
                        this.html_list[index].MSG_h = entry.intersectionRect.height/entry.rootBounds.height;

                        if ((this.mouseY <= entry.intersectionRect.bottom) && (this.mouseY >= entry.intersectionRect.top)) {
                            this.html_list[index].visibleHeight[mode] = 10000;
                        } else {
                            this.html_list[index].visibleHeight[mode] = 1/Math.min(Math.abs(entry.intersectionRect.bottom-this.mouseY), Math.abs(entry.intersectionRect.top-this.mouseY));
                        }
                        if ((this.mouseY <= entry.intersectionRect.bottom) && (this.mouseY >= entry.intersectionRect.top)
                            &&(this.mouseX>=entry.intersectionRect.left)&&(this.mouseX<=entry.intersectionRect.right)) {
                            this.html_list[index].visibleHeight["m_hover"] = 1;
                        }else{
                            this.html_list[index].visibleHeight["m_hover"] = 0;
                        }
                        mode = 2;
                        this.html_list[index].visibleHeight[mode] = Math.max(0.5 - Math.abs(this.html_list[index].MSG_y - 0.5), 0);

                        // this.html_list[index].visibleHeight[mode] =
                        //     1/Math.min(Math.abs((entry.intersectionRect.bottom+entry.intersectionRect.top)/2-entry.rootBounds.height/2)/entry.rootBounds.height,
                        //     0.1);
                        this.html_list[index].visibleHeight["d_to_c"] = 1- Math.abs((entry.intersectionRect.bottom+entry.intersectionRect.top)/2-entry.rootBounds.height/2)/entry.rootBounds.height;

                        mode = 1;
                        this.html_list[index].visibleHeight[mode] =this.html_list[index].MSG_h;

                        // console.log(index, this.html_list[index].visibleHeight);
                    }else{
                        this.html_list[index].isVisible = false;
                        this.html_list[index].visibleHeight[1] = 0;
                        this.html_list[index].visibleHeight[2] = 0;
                        this.html_list[index].visibleHeight[3] = 0;
                        this.html_list[index].visibleHeight["d_to_c"] = 0;
                        this.html_list[index].visibleHeight["m_hover"] = 0;
                        this.html_list[index].MSG_y = -1;
                        this.html_list[index].MSG_h = 0;
                    }
                    this.entry.bottom = entry.intersectionRect.bottom;
                    this.entry.top = entry.intersectionRect.top;
                    this.entry.height = entry.intersectionRect.height;
                };
                const observer = new IntersectionObserver(callback, {
                    root: null,
                    threshold: 0
                });
                var elements = document.querySelectorAll(".observable");
                var index = 0;
                function delay(time) {
                    return new Promise(resolve => setTimeout(resolve, time));
                }

                async function myLoop(l, index, delay) {
                    if (index<l) {
                        observer.index = index;
                        observer.observe(elements[index]);
                    }
                    await delay(50);
                    index += 1;
                    if (index<l) myLoop(l, index,delay);
                    else index = 0
                }


                myLoop(this.html_list.length, index,delay);

            },
            updateTime: function(){
                this.isVisible = document.visibilityState === "visible";
                this.dismissCountDown -= 1;
                if (this.isVisible) {
                    this.options[2].disabled = false;
                    if (window.pageYOffset>0){
                        this.pageYs.push(window.pageYOffset);
                        while(this.pageYs.length>2) this.pageYs.shift();
                        if (this.pageYs.length>1) this.MSF_y.push(Math.abs(window.pageYOffset-this.pageYs[this.pageYs.length-2])/window.innerHeight);
                        while (this.MSF_y.length>10) this.MSF_y.shift();

                    }
                    var MSF_y_2, MSF_y_5, MSF_y_10;
                    if (this.MSF_y.length>0){
                        MSF_y_2 = this.sum(this.MSF_y.slice(-2))/(this.MSF_y.slice(-2).length);
                        MSF_y_5 = this.sum(this.MSF_y.slice(-5))/(this.MSF_y.slice(-5).length);
                        MSF_y_10 = this.sum(this.MSF_y.slice(-10))/(this.MSF_y.slice(-10).length);
                        this.MSF_y_inf = (this.MSF_y_inf*this.time+this.MSF_y[this.MSF_y.length-1])/(this.time+1);
                    }else{
                        MSF_y_2 = 0;
                        MSF_y_5 = 0;
                        MSF_y_10 = 0;
                        this.MSF_y_inf = 0;
                    }

                    if (this.mouseX>0){
                        this.mouseXs.push(
                            this.mouseX
                        );
                        while (this.mouseXs.length>2) this.mouseXs.shift();
                        if (this.mouseXs.length>1) this.MMF_x.push(Math.abs(this.mouseX-this.mouseXs[this.mouseXs.length-2])/window.innerWidth);
                        while (this.MMF_x.length>10) this.MMF_x.shift();
                    }
                    if (this.mouseY>0){
                        this.mouseYs.push(
                            this.mouseY
                        );
                        while (this.mouseYs.length>2) this.mouseYs.shift();
                        if (this.mouseYs.length>1) this.MMF_y.push(Math.abs(this.mouseY-this.mouseYs[this.mouseYs.length-2])/window.innerHeight);
                        while (this.MMF_y.length>10) this.MMF_y.shift();
                    }
                    var MMF_x_2, MMF_x_5, MMF_x_10, MMF_y_2, MMF_y_5, MMF_y_10;

                    if (this.MMF_x.length>0){
                        MMF_x_2 = this.sum(this.MMF_x.slice(-2))/(this.MMF_x.slice(-2).length);
                        MMF_x_5 = this.sum(this.MMF_x.slice(-5))/(this.MMF_x.slice(-5).length);
                        MMF_x_10 = this.sum(this.MMF_x.slice(-10))/(this.MMF_x.slice(-10).length);
                        this.MMF_x_inf = (this.MMF_x_inf*this.time+this.MMF_x[this.MMF_x.length-1])/(this.time+1);
                    }else {
                        MMF_x_2 = 0;
                        MMF_x_5 = 0;
                        MMF_x_10 = 0;
                        this.MMF_x_inf = 0;
                    }
                    if (this.MMF_y.length>0){
                        MMF_y_2 = this.sum(this.MMF_y.slice(-2))/(this.MMF_y.slice(-2).length);
                        MMF_y_5 = this.sum(this.MMF_y.slice(-5))/(this.MMF_y.slice(-5).length);
                        MMF_y_10 = this.sum(this.MMF_y.slice(-10))/(this.MMF_y.slice(-10).length);
                        this.MMF_y_inf = (this.MMF_y_inf*this.time+this.MMF_y[this.MMF_y.length-1])/(this.time+1);
                    }else{
                        MMF_y_2 = 0;
                        MMF_y_5 = 0;
                        MMF_y_10 = 0;
                        this.MMF_y_inf = 0;
                    }


                    var now = new Date();
                    this.read_time += 1;
                    this.timestamp = dateFormat(now, "yyyy-mm-dd h:MM:ss");
                    var value = {timestamp: now,
                        time_length: now.getTime()-this.start_time,
                        time: this.read_time,
                        start_time: this.start_time,
                        isVisible: this.isVisible,
                        entry: this.entry,
                        mouseY: this.mouseY/window.innerHeight,
                        mouseX: this.mouseX/window.innerWidth,
                        length: this.html_list.length,
                        S_cy: 0.5,
                        height: window.innerHeight,
                        MMF_x_2: MMF_x_2,
                        MMF_x_5: MMF_x_5,
                        MMF_x_10: MMF_x_10,
                        MMF_x_inf: this.MMF_x_inf,
                        MMF_y_2: MMF_y_2,
                        MMF_y_5: MMF_y_5,
                        MMF_y_10: MMF_y_10,
                        MMF_y_inf: this.MMF_y_inf,
                        MSF_y_2: MSF_y_2,
                        MSF_y_5: MSF_y_5,
                        MSF_y_10: MSF_y_10,
                        MSF_y_inf: this.MSF_y_inf,
                    };
                    var MSF_clk = 0;
                    var M_tclk = 0;
                    value["MSG_svt"] = {}
                    value["MSG_sh"] = {}
                    value["MSG_sy"] = {}
                    value["MSG_tmy"] = {}
                    value["MSG_tclk"] = {}
                    value["MSG_clk"] = {}
                    value["MSG_y"] = {}
                    value["MSG_h"] = {}
                    for (let mode =1; mode <= 3; mode++) {
                        var s = 0;
                        value["height_"+mode.toString()] = {}
                        value["time_"+mode.toString()] = {}
                        if (this.isVisible) {
                            for (var i = 0; i < this.html_list.length; i++) {
                                if (this.html_list[i].isVisible){
                                    if (mode === 1) {
                                        this.html_list[i].MSG_svt += 1;
                                        this.html_list[i].MSG_sh += this.html_list[i].visibleHeight[mode]/window.innerHeight;
                                        this.html_list[i].MSG_sy += this.html_list[i].visibleHeight["d_to_c"];
                                        this.html_list[i].MSG_tmy += this.html_list[i].visibleHeight["m_hover"];
                                        value["MSG_svt"][i] = this.html_list[i].MSG_svt;
                                        value["MSG_sh"][i] = this.html_list[i].MSG_sh;
                                        value["MSG_sy"][i] = this.html_list[i].MSG_sy;
                                        value["MSG_tmy"][i] = this.html_list[i].MSG_tmy;
                                        value["MSG_y"][i] = this.html_list[i].MSG_y;
                                        value["MSG_h"][i] = this.html_list[i].MSG_h;
                                    }
                                    s += this.html_list[i].visibleHeight[mode];
                                    value["height_"+mode.toString()][i] = this.html_list[i].visibleHeight[mode];
                                }else{
                                    value["MSG_svt"][i] = this.html_list[i].MSG_svt;
                                    value["MSG_sh"][i] = this.html_list[i].MSG_sh;
                                    value["MSG_sy"][i] = this.html_list[i].MSG_sy;
                                    value["height_"+mode.toString()][i] = 0 ;
                                    value["MSG_tmy"][i] = this.html_list[i].MSG_tmy;
                                    value["MSG_y"][i] = this.html_list[i].MSG_y;
                                    value["MSG_h"][i] = this.html_list[i].MSG_h;
                                }

                            }
                            if (s === 0) s = 1;
                            for (i = 0; i < this.html_list.length; i++) {
                                if (this.html_list[i].isVisible) {
                                    this.html_list[i].time += this.html_list[i].visibleHeight[mode] / s;
                                    value["time_"+mode.toString()][i] = this.html_list[i].visibleHeight[mode] / s;
                                }else{
                                    value["time_"+mode.toString()][i] = 0;
                                }
                                if (mode===1) {
                                    if (this.html_list[i].MSG_tsclk !== 0) {
                                        value["MSG_tclk"][i] = 1 - (now.getTime() - this.html_list[i].MSG_tsclk)/(30 * 60 * 1000);
                                        value["MSG_clk"][i] = 1;
                                        MSF_clk += 1/this.html_list.length;
                                    } else {
                                        value["MSG_tclk"][i] = 0;
                                        value["MSG_clk"][i] = 0;
                                    }
                                    M_tclk = Math.max(M_tclk, value["MSG_tclk"][i]);
                                }

                            }
                        }else{
                            for (let i = 0; i < this.html_list.length; i++) {
                                value["height_"+mode.toString()][i] = 0 ;
                                value["time_"+mode.toString()][i] = 0;
                                if (mode === 1) {
                                    value["MSG_svt"][i] = this.html_list[i].MSG_svt;
                                    value["MSG_sh"][i] = this.html_list[i].MSG_sh;
                                    value["MSG_sy"][i] = this.html_list[i].MSG_sy;
                                    value["MSG_tmy"][i] = this.html_list[i].MSG_tmy;
                                    value["MSG_y"][i] = this.html_list[i].MSG_y;
                                    value["MSG_h"][i] = this.html_list[i].MSG_h;
                                    if (this.html_list[i].MSG_tsclk !== 0) {
                                        value["MSG_clk"][i] = 1;
                                        MSF_clk += 1/this.html_list.length;
                                    } else {
                                        value["MSG_clk"][i] = 0;
                                    }
                                    if (this.html_list[i].MSG_tsclk !== 0) value["MSG_tclk"][i] = 1 - (now.getTime() - this.html_list[i].MSG_tsclk)/(30 * 60 * 1000);//now.getTime() - this.html_list[i].MSG_tsclk;
                                    else value["MSG_tclk"][i] = 0;
                                    M_tclk = Math.max(M_tclk, value["MSG_tclk"][i]);
                                }
                            }

                        }
                        value["s_"+mode.toString()] = s;
                    }
                    for (let mode =1; mode <= 3; mode++) {
                        for (let i = 0; i < this.html_list.length; i++) {
                            if (value["s_" + mode.toString()] > 0) value["height_" + mode.toString()][i] = value["height_" + mode.toString()][i] / value["s_" + mode.toString()]
                            else value["height_" + mode.toString()][i] = 0;
                        }
                    }

                    value["M_tclk"] = M_tclk;

                    value["S_h"] = 1;
                    value["MSF_clk"] = MSF_clk;
                    // console.log(value);

                    var res = this.second_predict(value);
                    var read_level;
                    var read = 0;
                    var detail = 0;
                    var time_s = 0;
                    for (i = 0; i < this.html_list.length; i++) {
                        if (this.html_list[i].data.data[1]) {
                            if (res.s !== undefined && res.s > 0) {

                                if (res.preds[i] > 0) {
                                    this.html_list[i].time += res.preds[i] / res.s;
                                    time_s += this.html_list[i].time;
                                    this.update_read_message(
                                        this.userId + "_" + this.postId + "_" + this.html_list[i].index,
                                        "time",
                                        this.html_list[i].time,
                                        i
                                    )
                                }
                            } else {
                                if (this.html_list[i].visibleHeight[1] > 0) {
                                    this.html_list[i].time += this.html_list[i].visibleHeight[1] / s;
                                    time_s += this.html_list[i].time;
                                    this.update_read_message(
                                        this.userId + "_" + this.postId + "_" + this.html_list[i].index,
                                        "time",
                                        this.html_list[i].time,
                                        i
                                    )
                                }
                            }
                            read_level = ((this.html_list[i].n_word > 20) * ((this.html_list[i].time > (this.html_list[i].n_word / 200.0 * 60)))) ? 1 : 0;
                            read_level += ((this.html_list[i].n_word > 20) * ((this.html_list[i].time > (this.html_list[i].n_word / 400.0 * 60)))) ? 1 : 0;
                            read += read_level >= 1 ? 1 : 0;
                            detail += read_level >= 2 ? 1 : 0;
                            if (this.html_list[i].read_level === undefined) this.html_list[i].read_level = 0;
                            if (read_level > this.html_list[i].read_level) {
                                this.html_list[i].read_level = read_level;
                                this.update_read_message(
                                    this.userId + "_" + this.postId + "_" + this.html_list[i].index,
                                    "read_level",
                                    this.html_list[i].read_level,
                                    i
                                )

                            }

                        }
                    }
                    this.time += 1;
                    this.read_time = Math.max(time_s, this.read_time);
                    read_level = ((this.n_word > 20) * ((this.read_time > (this.n_word / 200 * 60)))) ? 1 : 0;
                    read_level += ((this.n_word > 20) * ((this.read_time > (this.n_word / 400 * 60)))) ? 1 : 0;
                    this.update_read_post(
                        this.userId + "_" + this.postId,
                        "read_level",
                        read_level
                    );
                    this.update_read_post(
                        this.userId + "_" + this.postId,
                        "read",
                        read
                    );
                    this.update_read_post(
                        this.userId + "_" + this.postId,
                        "detail",
                        detail
                    );
                    this.update_read_post(
                        this.userId + "_" + this.postId,
                        "time",
                        this.read_time
                    );
                }
            },
            second_predict: function(value){
                var x1 = [];
                var x2 = [];
                for (let i = 0; i < this.html_list.length; i++){
                    var tmp1 = [];
                    this.msg_tmp_cols.forEach((col)=>tmp1.push(Number(value[col][i.toString()])));
                    x1.push(tmp1);
                    var tmp2 = [];
                    this.user_pattern_cols.forEach((col)=>tmp2.push(Number(value[col])));
                    x2.push(tmp2);
                }
                var preds = this.second_model.predict([tf.tensor2d(x1), tf.tensor2d(x2)]).dataSync();


                preds = this.exp(preds);
                for (let i = 0; i < this.html_list.length; i++) {
                    if (!this.html_list[i].isVisible) preds[i] = 0;
                }
                var s = this.sum(preds);

                return {preds:preds, s:s}

            }
        }
    }
</script>

<style scoped>
    .alert-fixed {
        position:fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        z-index:9999;
        border-radius:0px
    }

    .maximg ::v-deep img { max-width: 700px; }
</style>