<template>
    <div>
    <body class="c13 doc-content"><h1 class="c8" id="h.ois6arbcaqr3"><span class="c15">Tools for Communicator - Field Study </span>
    </h1>
    <h2 class="c7" id="h.sm6eakeffb05"><span class="c14">Supporting Organizational Bulk Email Communication</span></h2>
    <p class="c1"><span class="c0">This study is aimed to help organiztion&rsquo;s bulk communication. Specifically, we will design and test a bulk email evaluation platform with 10 communicators and 300 employees of the University of Minnesota. </span>
    </p>
    <p class="c1 c5"><span class="c0"></span></p>
    <p class="c1"><span class="c0">This platform enables communicators to have access to various metrics of each email / message, such as open rate, reading time, relevance rate, comment rate, etc. We want to see how these detailed metrics could be used to support communication professionals in editing/targeting the university&rsquo;s bulk emails.</span>
    </p>
    <p class="c1 c5"><span class="c0"></span></p>
    <p class="c1 c5"><span class="c0"></span></p>
    <p class="c1 c5"><span class="c0"></span></p>
    <p class="c1 c5"><span class="c0"></span></p>
    <h3 class="c12" id="h.q703tqin18q3"><span class="c2">For recipients:</span></h3>
    <p class="c1"><span>You will receive emails from </span><span class="c4"><a class="c6"
                                                                                href="mailto:email-study@umn.edu">email-study@umn.edu</a></span><span
            class="c0">&nbsp;in the next 2 months like below (figure 1).</span></p>
    <p class="c11"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 380.50px; height: 231.28px;"><img
            alt="" src="../assets/image2.png"
            style="width: 380.50px; height: 231.28px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
            title=""></span><span class="c0">&nbsp;</span></p>
    <p class="c11"><span class="c0">Figure 1. Sample test email.</span></p>
    <p class="c1"><span>Each email will have a personalized link by which you can click to read the email. You will be assigned to either the implicit or explicit participant group (we will notify you of this assignment before the study begins &nbsp;--- please contact </span><span
            class="c4"><a class="c6" href="mailto:kong0135@umn.edu">kong0135@umn.edu</a></span><span class="c0">&nbsp;if you don&rsquo;t know which group you are in).</span>
    </p>
    <p class="c1 c5"><span class="c0"></span></p>
    <p class="c1"><span class="c3">You are assigned to the </span><span class="c10 c3">explicit</span><span class="c3">&nbsp;user group If you</span><span>&nbsp;</span><span
            class="c3"> see the comment / relevant buttons </span><span>on the emails you receive like below (figure 2). Please </span><span
            class="c16">try reading each test email</span><span class="c0">, checking &ldquo;relevant-to-me&rdquo; if you feel a message is relevant to you, and leaving comments if you want to.</span>
    </p>
    <p class="c1"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 624.00px; height: 124.00px;"><img
            alt="" src="../assets/image1.png"
            style="width: 624.00px; height: 124.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
            title=""></span></p>
    <p class="c1"><span class="c0">Figure 2 -- You are in the explicit user group if your pages look like this.</span>
    </p>
    <p class="c1 c5"><span class="c0"></span></p>
    <p class="c1 c5"><span class="c0"></span></p>
    <p class="c1 c5"><span class="c0"></span></p>
    <p class="c1"><span class="c3">You are assigned to the </span><span class="c3 c10">implicit</span><span class="c3">&nbsp;user group If you do not</span><span>&nbsp;</span><span
            class="c3"> see the comment / relevant buttons, like below (figure 3). </span><span>Please react to these emails as normal as you could -- acting as what you normally would on whether opening each test email you receive, whether reading them --- </span><span
            class="c16">like if you were not in this study</span><span class="c0">.</span></p>
    <p class="c1"><span
            style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 624.00px; height: 96.78px;"><img
            alt="" src="../assets/image1.png"
            style="width: 624.00px; height: 124.00px; margin-left: 0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
            title=""></span></p>
    <p class="c1"><span class="c0">Figure 3 -- You are in the implicit user group if your pages look like this.</span>
    </p>
    <p class="c1 c5"><span class="c0"></span></p>
    <p class="c1 c5"><span class="c0"></span></p>
    <p class="c1 c5"><span class="c0"></span></p>
    <p class="c1 c5"><span class="c0"></span></p>
    <h3 class="c12" id="h.x61oltmxo7h2"><span class="c2">For communication professionals:</span></h3>
    <p class="c1"><span class="c0">&nbsp; &nbsp;Please come to this platform to send the same email every time you send out the selected newsletters during the study. You will receive a reminder email after 24 hours; follow its instructions to</span>
    </p>
    <p class="c1"><span class="c0">-- read 3 reports on the open rate, relevance rate, reading time &hellip; of (each sub-message of) the email</span>
    </p>
    <p class="c1"><span class="c0">-- fill in the 3 surveys under the 3 reports (for research purposes).</span></p>
    <p class="c1 c5"><span class="c0"></span></p>
    <p class="c1 c5"><span class="c0"></span></p>
    <p class="c1 c5"><span class="c0"></span></p>
    <p class="c1"><span class="c0">Thanks for participating in this study! </span></p>
    <p class="c1 c5"><span class="c0"></span></p>
    <p class="c1"><span class="c3 c9">More information on the research Team:</span></p>
    <p class="c1"><span>We&rsquo;re a research team from Grouplens Lab (</span><span class="c4"><a class="c6"
                                                                                                   href="https://www.google.com/url?q=https://grouplens.org/&amp;sa=D&amp;source=editors&amp;ust=1664229133417517&amp;usg=AOvVaw0sOQVpvtzm-Ju_OlzPdUlC">https://grouplens.org/</a></span><span>, student PI: Ruoyan Kong, advisor: Prof. Joseph Konstan</span><span
            class="c0">) studying organizational bulk communication. We aimed to learn this system&rsquo;s stakeholders&#39; experience with and perspectives of the bulk emails they receive, and design technologies to make this system more effective.</span>
    </p>
    <p class="c1 c5"><span class="c0"></span></p>
    <p class="c1"><span>This study has been approved by the IRB of the University of Minnesota (</span><span class="c4"><a
            class="c6"
            href="https://www.google.com/url?q=https://docs.google.com/document/d/15YcgOVLWyF6NHzq-3aDFA77g9hOlvWheQS741InmbXc/edit?usp%3Dsharing&amp;sa=D&amp;source=editors&amp;ust=1664229133418137&amp;usg=AOvVaw3wSbWKq8d-i3VqNYasVfRa">protocol</a></span><span
            class="c0">).</span></p>
    <p class="c1"><span>For any questions, please contact Ruoyan at kong0135@umn.edu.</span></p></body>
    </div>
</template>

<script>
    export default {
        name: "About"
    }
</script>

<style scoped>

table td, table th {
    padding: 0
}

.c14 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 16pt;
    font-family: "Arial";
    font-style: italic
}

.c8 {
    padding-top: 20pt;
    padding-bottom: 6pt;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c15 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 20pt;
    font-family: "Arial";
    font-style: normal
}

.c0 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.c7 {
    padding-top: 18pt;
    padding-bottom: 6pt;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c12 {
    padding-top: 16pt;
    padding-bottom: 4pt;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c2 {
    color: #434343;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 14pt;
    font-family: "Arial";
    font-style: normal
}

.c1 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c9 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.c11 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: center
}

.c4 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    color: #1155cc;
    text-decoration: underline
}

.c16 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    text-decoration: underline
}

.c13 {
    background-color: #ffffff;
    max-width: 1000pt;
    padding: 72pt 72pt 72pt 72pt
}

.c6 {
    color: inherit;
    text-decoration: inherit
}

.c5 {
    height: 11pt
}

.c10 {
    color: #ff0000
}

.c3 {
    font-weight: 700
}

.title {
    padding-top: 0pt;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

li {
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}</style>