<template>
    <div class="row" style="margin-top:5px;">
        <b-col md="2">

        </b-col>
        <b-col md="8">
            {{index}}
            <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                <b-form-group
                        id="input-group-1"
                        label="Database Collection Name:"
                        label-for="input-1"
                        description="Name of database collection."
                >
                    <b-form-input
                            id="input-1"
                            v-model="form.collection"
                            placeholder="Enter collection name, e.g., eye_tracking_read_post"
                            required
                    ></b-form-input>
                </b-form-group>

                <!--                <b-form-group id="input-group-2" label="Absolute Local Path:" label-for="input-2">-->
                <!--                    <b-form-input-->
                <!--                            id="input-2"-->
                <!--                            v-model="form.path"-->
                <!--                            placeholder="e.g., absolute path - /home/ruoyan/Documents/hw/study3/data"-->
                <!--                            required-->
                <!--                    ></b-form-input>-->
                <!--                </b-form-group>-->


                <b-button type="submit" variant="primary" style="margin-right: 10px">Download</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
    import {db} from "../firebaseConfig";
    import download from "downloadjs";
    import {auth} from "@/firebaseConfig"

    export default {
        name: "Admin",
        data() {
            return {
                form: {
                    collection: 'eye_tracking_read_post',
                    // path: '/home/ruoyan/Documents/hw/study3/data',
                },
                show: true,
                implicit_users: ['doepn002@umn.edu', 'kats@umn.edu', 'verolson@d.umn.edu', 'smmay@umn.edu', 'seliason@umn.edu', 'opayx002@umn.edu', 'jyt@umn.edu', 'erick374@umn.edu', 'jenki136@umn.edu', 'sitxx001@umn.edu', 'johnsojr@umn.edu', 'bstarbuc@umn.edu', 'bold0099@umn.edu', 'twhite01@umn.edu', 'bale0028@umn.edu', 'grev0043@umn.edu', 'hans1247@umn.edu', 'fogel033@umn.edu', 'arend198@umn.edu', 'dinki009@umn.edu', 'dannardh@umn.edu', 'alchx011@umn.edu', 'kdowd@umn.edu', 'hovex004@umn.edu', 'cristaly@umn.edu', 'desel006@umn.edu', 'meyer033@umn.edu', 'twolf@umn.edu', 'jdwilson@umn.edu', 'joh14258@umn.edu', 'rchow@umn.edu', 'sbackman@morris.umn.edu', 'hoang@umn.edu', 'grime004@umn.edu', 'casie@umn.edu', 'ayetter@umn.edu', 'vange044@umn.edu', 'clemenw@umn.edu', 'pnw@umn.edu', 'soude022@umn.edu', 'arnosti@umn.edu', 'herol067@umn.edu', 'buckm013@umn.edu', 'emarin@umn.edu', 'mjuliett@umn.edu', 'torg0096@umn.edu', 'hell0025@umn.edu', 'smsevcik@umn.edu', 'jmarrone@umn.edu', 'macn0029@umn.edu', 'hanse098@umn.edu', 'rmnelson@d.umn.edu', 'chazuka@umn.edu', 'jevans@umn.edu', 'karst024@umn.edu', 'livin170@umn.edu', 'frit0055@umn.edu', 'wrightan@umn.edu', 'b-lux@umn.edu', 'wege0002@umn.edu', 'dietersm@morris.umn.edu', 'ablumer@umn.edu', 'baue0437@umn.edu', 'junk0011@umn.edu', 'nyeg0007@umn.edu', 'alholmgr@umn.edu', 'rajohnso@umn.edu', 'asivils@umn.edu', 'mcgoffin@umn.edu', 'tai00020@umn.edu', 'sbartsch@d.umn.edu', 'klein052@umn.edu', 'mwind@umn.edu', 'smit5615@umn.edu', 'sonya@umn.edu', 'zielk020@umn.edu'],
                explicit_users: ['kjhanson@d.umn.edu', 'suski004@umn.edu', 'ewf@umn.edu', 'alqub001@umn.edu', 'bortz011@umn.edu', 'held0086@umn.edu', 'huan2321@umn.edu', 'barcl052@umn.edu', 'carl1213@umn.edu', 'shlaf002@umn.edu', 'pepel014@umn.edu', 'khakanso@umn.edu', 'simo0527@umn.edu', 'knig0087@umn.edu', 'dohm0004@umn.edu', 'lang0636@umn.edu', 'sallyann@umn.edu', 'aluminae@umn.edu', 'mipsan@umn.edu', 'cole0430@umn.edu', 'toddmehr@umn.edu', 'shrye003@umn.edu', 'ferna010@umn.edu', 'kjabri@morris.umn.edu', 'mkcon@umn.edu', 'wuori003@umn.edu', 'grand006@umn.edu', 'arueter@umn.edu', 'tittl006@umn.edu', 'holr0002@umn.edu', 'hoff0511@umn.edu', 'simo0228@umn.edu', 'ogra0013@umn.edu', 'pusch030@umn.edu', 'mdargay@umn.edu', 'thuydoan@umn.edu', 'cdmyers@umn.edu', 'walke021@umn.edu', 'weston@umn.edu', 'john4830@umn.edu', 'cress021@umn.edu', 'smk@umn.edu', 'siven001@umn.edu', 'ruuda@umn.edu', 'tenen011@umn.edu', 'papro014@umn.edu', 'petru049@umn.edu', 'nrolstad@umn.edu', 'desethsj@morris.umn.edu', 'kraft146@umn.edu', 'millersa@umn.edu', 'lorti001@umn.edu', 'ksquick@umn.edu', 'astewart@umn.edu', 'moor0055@umn.edu', 'najohn@umn.edu', 'wils0975@umn.edu', 'plachejo@umn.edu', 'jense010@umn.edu', 'eparedes@umn.edu', 'amelia@umn.edu', 'dreis020@umn.edu', 'johns706@umn.edu', 'ggrensin@d.umn.edu', 'spoppoff@umn.edu', 'krag0038@crk.umn.edu', 'venne025@umn.edu', 'kboman@umn.edu', 'diedr006@umn.edu', 'chama197@umn.edu', 'wiltx013@umn.edu', 'larso169@umn.edu', 'organ006@umn.edu', 'mahx0004@umn.edu', 'rachels@umn.edu'],
                map_d: {
                    'carlson school of management, work and organizations': 'carlson school of management & business schools',
                    'medical school - family medicine & behavioral health': 'medical school',
                    'college of veterinary medicine': 'college of veterinary medicine',
                    'printing services': 'facilities management / university services',
                    'college of biological sciences': 'college of biological sciences',
                    'office for student affairs': 'office for student affairs / undergraduate education / libraries / admissions',
                    'sociology': 'humphrey school of public affairs',
                    'sponsored financial reporting': 'budget & finance & ohr',
                    'humphrey school of public affairs': 'humphrey school of public affairs',
                    'boynton': 'boynton',
                    'advising': 'office for student affairs / undergraduate education / libraries',
                    'college of science and engineering': 'college of science and engineering',
                    'department of primary dental care, school of dentistry': 'school of dentistry',
                    'college of liberal arts': 'college of liberal arts',
                    'school of public health, hpm division': 'school of public health',
                    'office of undergraduate education ': 'office for student affairs / undergraduate education / libraries',
                    'pediatrics': 'medical school',
                    'housing & residential life': 'office for student affairs / undergraduate education / libraries',
                    "the controller's office": 'budget & finance & ohr',
                    'humphrey school of public affairs, office of the dean': 'humphrey school of public affairs',
                    'college of veterinary medicine, office of the associate dean of research': 'college of veterinary medicine',
                    'pharmacy': 'college of pharmacy',
                    'ess,surgery, med school': 'medical school',
                    'cehd': 'cehd',
                    'department of surgery': 'medical school',
                    'department of family medicine and community health': 'medical school',
                    'medical school department of pediatrics': 'medical school',
                    'medical school, department of medicine': 'medical school',
                    'department of primary dental care': 'school of dentistry',
                    'school of mathematics': 'college of science and engineering',
                    'admissions': 'office for student affairs / undergraduate education / libraries / admissions',
                    'budget & finance': 'budget & finance & ohr',
                    "school of public health dean's office": 'school of public health',
                    'carlson school of management': 'carlson school of management & business schools',
                    'academic clinical affairs, msimulation': 'office of academic clinical affairs',
                    'cla-philosophy': 'college of liberal arts',
                    'housing and residential life': 'office for student affairs / undergraduate education / libraries / admissions',
                    'university of minnesota foundation': 'budget & finance & ohr',
                    'housing and res life': 'office for student affairs / undergraduate education / libraries / admissions',
                    'occupational therapy': 'office of athletic / allied health / recwell',
                    'institute for advanced study': 'office of the vice president of research',
                    'veterinary clinical sciences, college of veterinary medicine ': 'college of veterinary medicine',
                    'office of advancement': 'budget & finance & ohr',
                    'medical school': 'medical school',
                    'u of m foundation': 'budget & finance & ohr',
                    'department of biology teaching & learning': 'college of biological sciences',
                    'dentistry': 'school of dentistry',
                    'cbs st paul administrative cluster': 'college of biological sciences',
                    'college of design': 'college of design',
                    'cse': 'college of science and engineering',
                    'oit': 'oit',
                    'office of information technology': 'oit',
                    'psychiatry': 'medical school',
                    'medical school, department of pharmacology, wickman lab': 'medical school',
                    'facilities management': 'facilities management / university services',
                    'office of athletic compliance': 'office of athletic / allied health / recwell',
                    'university senate office': 'university senate office',
                    'extension': 'extension',
                    'cfans, animal science': 'cfans',
                    'gps alliance': 'office for student affairs / undergraduate education / libraries / admissions',
                    'cfans, graduate programs office': 'cfans',
                    'cla, department of english ': 'college of liberal arts',
                    'carlson': 'carlson school of management & business schools',
                    'environmental health science': 'school of public health',
                    'university health and safety': 'university health / public safety',
                    'grants development': 'budget & finance & ohr',
                    'office of information technology ': 'oit',
                    'itss': 'oit',
                    'cbs student services': 'college of biological sciences',
                    'oris': 'office of the vice president of research',
                    'school of medicine': 'medical school',
                    'recwell': 'office of athletic / allied health / recwell',
                    'ctsi': 'office of academic clinical affairs',
                    'asr': 'office for student affairs / undergraduate education / libraries / admissions',
                    'recreation and wellness, osa': 'office of athletic / allied health / recwell',
                    'division of epidemiology & community health': 'school of public health',
                    'school of public health': 'school of public health',
                    'school of public health - division of health policy & management': 'school of public health',
                    'ophthalmology and visual neurosciences': 'medical school',
                    'office of communication and marketing': 'office of communication and marketing',
                    'department of pediatrics, medical school': 'medical school',
                    'business ': 'budget & finance & ohr',
                    'umms, ume, student affairs': 'office for student affairs / undergraduate education / libraries / admissions',
                    'medicine': 'medical school',
                    'gems informatics': 'cfans',
                    'institute of child development': 'cehd',
                    'office of cost analysis': 'budget & finance & ohr',
                    'surgical administrative center': 'medical school',
                    'public safety': 'university health / public safety',
                    'college of food, agricultural and natural resource sciences': 'cfans',
                    'university services ': 'facilities management / university services',
                    'cfans': 'cfans',
                    "controller's office": 'budget & finance & ohr',
                    'minnesota supercomputing institute, office of the vice president of research': 'office of the vice president of research',
                    'pediatrics/med school': 'medical school',
                    'school of dentistry': 'school of dentistry',
                    'extension development office': 'extension',
                    'libraries': 'office for student affairs / undergraduate education / libraries / admissions',
                    'neurosurgery': 'medical school',
                    'office of academic clinical affairs, clinical and translational science institute': 'office of academic clinical affairs',
                    'masonic institute for the developing brain': 'office of academic clinical affairs',
                    'talent strategy / leadership and talent development': 'budget & finance & ohr',
                    'medical school - research office': 'medical school',
                    '10kfs': 'school of public health',
                    'learning abroad center': 'office for student affairs / undergraduate education / libraries / admissions',
                    'industrial and systems engineering': 'college of science and engineering',
                    'st paul zone 1 fm': 'facilities management / university services',
                    'dean\x19s office, school of public health': 'school of public health',
                    'veterinary clinical sciences': 'college of veterinary medicine',
                    'department of bioproducts and biosystems engineering': 'college of biological sciences',
                    'cahp': 'office of athletic / allied health / recwell',
                    'school of public health, health policy & management': 'school of public health',
                    'medical school ': 'medical school',
                    'department of industrial and systems engineering': 'college of science and engineering',
                    'parking & transportation services': 'facilities management / university services',
                    'ahc': 'office of academic clinical affairs',
                    'cop': 'college of pharmacy',
                    'labovitz school of business and economics     and the bureau of business and economic research ': 'carlson school of management & business schools',
                    'extension youth development': 'extension',
                    'recreation and wellness': 'office of athletic / allied health / recwell',
                    'school of public health, division of health policy & management': 'school of public health',
                    'research lab': 'medical school',
                    'medical school, otolaryngology': 'medical school',
                    'infectious diseases': 'medical school',
                    'international programs & services': 'office for student affairs / undergraduate education / libraries / admissions',
                    'center for learning health system sciences': 'medical school'
                },
                index: 0
            }
        },
        created: async function () {
            function delay(time) {
                return new Promise(resolve => setTimeout(resolve, time));
            }

            await delay(1000);
            // var promises = [];
            var users = []
            for (let i=0;i<10;i++){
                users.push("ruoyankong@gmail.com")
            }
            this.index = 0;
            // db.collection("mail")
            //     .where("message.subject", "==", "U of M Brief (December 21, 2022)")
            //     .where("delivery.state", "!=", "SUCCESS")
            //     .get().then((querySnapshot) => {
            //     async function load () {
            //         for (const doc of querySnapshot.docs) {
            //             var d = doc.data();
            //             console.log(d.delivery.state, d.to)
            //             db.collection("mail").doc(doc.id).update(
            //                 {
            //                     "delivery.state": "RETRY"
            //                 }
            //             )
            //             await delay(2000);
            //             console.log(d.delivery.state)
            //         }
            //     }
            //     load()
            // })


            // for (const item of users){
            //     var link = "https://www.comm-tool.com/";
            //     promises.push(
            //         db.collection('mail').add({
            //             to: item,
            //             from: '<email-study@umn.edu>',
            //             message: {
            //                 subject: 'test',
            //                 html: '<a href=' + link + ' target="_blank">click to read</a>',
            //             },
            //         })
            //     )
            //     await delay(1000);
            //     this.index += 1;
            // }
        },
        beforeCreate: function () {
            auth.onAuthStateChanged(user => {
                console.log("user state:", user);
                // uncomment above to check out which user properties are available.
                if (!user) {
                    //this.$router.push("/"); this have already been done in router
                } else {
                    this.user = user;
                    if ((this.user.email !== "ruoyankong@gmail.com")) {
                        this.$router.push("/");
                    }

                }
            });




            // db.collection("users")
            // .get()
            // .then((querySnapshot) => {
            // querySnapshot.forEach((doc) => {
            // var d = doc.data();
            // var unit_new = '';
            // if (d['demo'] !== true) {
            // var group = '';
            // if (this.implicit_users.includes(d['email'])) group = 'implicit';
            // if (this.explicit_users.includes(d['email'])) group = 'explicit';
            //
            // if (group === ''){
            //     console.log("check this group")
            //     console.log(d)
            //     console.log(group)
            // }
            //
            // if (d['campus'] !== 'Twin Cities') {
            //     unit_new = d['campus'].toLowerCase();
            // } else {
            //     if (this.map_d[d['unit'].toLowerCase()] === undefined) {
            //         console.log("check this")
            //         console.log(d)
            //     } else {
            //         unit_new = this.map_d[d['unit'].toLowerCase()];
            //     }
            // }
            // db.collection("users").doc(doc.id).update(
            //     {
            // unit: unit_new,
            // unit_org: d['unit'],
            // group: group,
            // recipient_checked: true

            //     }
            // )
            // }
            //
            // })
            // })


        },
        methods: {
            onSubmit(event) {
                event.preventDefault();
                db.collection(this.form.collection)
                    .get()
                    .then((querySnapshot) => {
                        var s = "";
                        var tmp;
                        querySnapshot.forEach((doc) => {
                            // doc.data() is never undefined for query doc snapshots
                            tmp = doc.data();
                            tmp["doc_id"] = doc.id;
                            s += JSON.stringify(tmp) + "\n";

                            // download("hello world", "dlText.txt", "text/plain");
                        });
                        download(s, this.form.collection + ".json", "text/plain");

                        this.form.collection = '';
                        // this.form.path = '';
                        // Trick to reset/clear native browser form validation state
                        this.show = false
                        this.$nextTick(() => {
                            this.show = true
                        })
                    })
                // .catch((error) => {
                //     alert("check console for error")
                //     console.log(error);
                // });
            },
            onReset(event) {
                event.preventDefault()
                // Reset our form values
                // this.form.path = '';
                this.form.collection = '';
                // Trick to reset/clear native browser form validation state
                this.show = false
                this.$nextTick(() => {
                    this.show = true
                })
            }
        }
    }
</script>

<style scoped>

</style>