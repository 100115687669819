<template>
    <div></div>
</template>

<script>
    import {db} from "../firebaseConfig";

    export default {
        name: "UpdatePerf",
        components: {},
        methods: {


        },
        async beforeCreate() {
            async function update () {
                var demo = false;
                var outer_promises = [];
                var open_rate_dict = {};
                var date = new Date();
                var upper_time_bound = new Date(date.getTime() - (24 * 60 * 60 * 1000));
                outer_promises.push(
                db.collection("users").where("recipient_checked", "==", true)
                    .where("demo","==",demo).get().then(querySnapshot => {
                    var users = {};
                    var explicit_staff = 0;
                    var explicit_faculty = 0;
                    var implicit_staff = 0;
                    var implicit_faculty = 0;
                    var posts = {
                        'list_size': 0,
                        'open rate': 0,
                        'click rate': 0,
                        "relevance rate": 0,
                        "# comments": 0,
                        "reading time": 0,
                        "est cost*": 0,
                        'read rate': 0,
                        'detail rate': 0
                    };

                    querySnapshot.docs.map((doc) => {
                        // console.log('LOG 1', doc.data());
                        if (demo){
                            if (doc.data().demo !== true) return;
                        }else{
                            if (doc.data().demo === true) return;
                        }
                        users[doc.data().userId] = doc.data().group;
                        if (doc.data().group === "explicit") {
                            if (doc.data().category === "staff") explicit_staff += 1;
                            else explicit_faculty += 1
                        }
                        else{
                            if (doc.data().category === "staff") implicit_staff += 1;
                            else implicit_faculty += 1
                        }
                    });


                    db.collection("post").where("timestamp", "<", upper_time_bound).get().then(querySnapshot => {
                        // var post_size = querySnapshot.docs.length;
                        if (querySnapshot.docs.length > 0) {
                            var proms = [];
                            querySnapshot.docs.map(doc => {
                                // console.log('LOG 1', doc.data());
                                var tmp = doc.data();
                                var explicit = 0;
                                var implicit = 0;
                                if (tmp.audience.includes("staff")){
                                    explicit += explicit_staff;
                                    implicit += implicit_staff
                                }
                                if (tmp.audience.includes("faculty")){
                                    explicit += explicit_faculty;
                                    implicit += implicit_faculty;
                                }
                                if (demo){
                                    if (tmp.demo !== true) return;
                                }else{
                                    if (tmp.demo === true) return;
                                }
                                if (tmp.delete !== true) {
                                    tmp['# recipients'] = explicit + implicit;
                                    tmp['# opened'] = 0;
                                    tmp['# clicked'] = 0;
                                    tmp['# relevance'] = 0;
                                    tmp['# comments'] = 0;
                                    tmp['reading time'] = 0;
                                    tmp['read rate'] = 0;
                                    tmp['detail rate'] = 0;
                                    tmp['size'] = parseInt(tmp['n_recipient']);
                                    tmp['reputation change**'] = 0;
                                    tmp['est cost*'] = 0;
                                    tmp['newsletter'] = "";
                                    var postId = doc.id;
                                    tmp['postId'] = postId;
                                    var newsletterId = tmp['newsletterId'];
                                    var collection = db.collection('newsletter');
                                    proms.push(collection.doc(newsletterId).get().then((document) => {
                                        var low_proms = [];
                                        if (document.exists) {
                                            tmp['newsletter'] = document.data().newsletter_brand;
                                        } else {
                                            tmp['newsletter'] = "";
                                        }

                                        low_proms.push(
                                            db.collection("read_post").where("postId", "==", postId).get().then(querySnapshot => {
                                                if (querySnapshot.docs.length > 0) {
                                                    querySnapshot.docs.map(doc => {
                                                        // console.log('LOG 1', doc.data());
                                                        var row = doc.data();
                                                        if (users[row.userId] !== undefined) {
                                                            if (users[row.userId] === "explicit") {
                                                                tmp['# comments'] += row.comments;
                                                                tmp['# relevance'] += (row.relevant > 0);
                                                            } else {
                                                                if (isNaN(row.time)) row.time = 0;
                                                                tmp['reading time'] += Math.min(row.time, 30*60);
                                                                tmp['# opened'] += 1;
                                                                tmp['# clicked'] += (row.clicked > 0);
                                                                if (row.read_level !== undefined){
                                                                    tmp['read rate'] += row.read_level>0?1:0;
                                                                    tmp['detail rate'] += row.read_level>1?1:0;
                                                                }
                                                            }
                                                        }

                                                    });
                                                    if (implicit === 0) implicit = 1;
                                                    if (explicit === 0) explicit = 1;
                                                    tmp['open rate'] = tmp['# opened'] / implicit * 100;
                                                    tmp['click rate'] = tmp['# clicked'] / implicit * 100;
                                                    tmp['relevance rate'] = tmp['# relevance'] / explicit * 100;
                                                    tmp['read rate'] /= implicit / 100;
                                                    tmp['detail rate'] /= implicit / 100;
                                                    tmp['est cost*'] = 40 / 60.0 / 60.0 * (tmp['reading time']/ implicit + 6) * parseInt(tmp['size']) ;
                                                    if (tmp['# opened'] > 0) tmp['reading time'] = tmp['reading time'] / tmp['# opened']+6;
                                                    else tmp['reading time'] = 6;

                                                } else {
                                                    tmp['open rate'] = 0;
                                                    tmp['click rate'] = 0;
                                                    tmp['read rate'] =0;
                                                    tmp['detail rate'] =0;
                                                    tmp['relevance rate'] = 0;
                                                    tmp['est cost*'] = 40 / 60.0 / 60.0 * 6 * parseInt(tmp['size']) ;
                                                    tmp['reading time'] = 6;
                                                }
                                                tmp['list_size'] = parseInt(tmp['size']) / 1000.0;
                                                return tmp;

                                            })
                                        )
                                        return Promise.all(low_proms);
                                    }));
                                }
                            });
                            var items = Promise.all(proms);
                            items.then(function (results) {
                                var n_post = 0;
                                results.forEach((tmp) => {
                                    tmp = tmp[0];
                                    Object.keys(posts).forEach(function (key) {
                                        posts[key] += tmp[key];

                                    });
                                    n_post += 1;
                                    open_rate_dict[tmp["postId"]] = tmp["open rate"]/100;
                                });


                                Object.keys(posts).forEach(function (key) {
                                    posts[key] /= n_post;
                                });
                                posts["timestamp"] = date;
                                posts["type"] = "post";
                                posts["demo"] = demo;
                                // console.log(posts);
                                db.collection('perf_avg').add(posts);

                                var inner_promises = [];
                                inner_promises.push(
                                    db.collection("newsletter").get().then(
                                        querySnapshot => {
                                            var promises = [];
                                            querySnapshot.docs.map((document) => {
                                                var newsletterId = document.id;
                                                if (demo){
                                                    if (document.data().demo !== true) return;
                                                }else{
                                                    if (document.data().demo === true) return;
                                                }
                                                var audience = document.data().audience;
                                                promises.push(
                                                    db.collection("users").where("recipient_checked", "==", true)
                                                        .where("demo","==",demo).get().then(querySnapshot => {
                                                            var users = {}
                                                            var explicit = 0;
                                                            var implicit = 0;
                                                            querySnapshot.docs.map((doc) => {
                                                                // console.log('LOG 1', doc.data());
                                                                if (!audience.includes(doc.data().category)) return;
                                                                users[doc.data().userId] = doc.data().group;
                                                                if (doc.data().group === "explicit") explicit += 1;
                                                                else implicit += 1;
                                                            });
                                                            var promises1 = [];
                                                            promises1.push(
                                                                db.collection("messages").where("newsletterId", "==", newsletterId).where("timestamp", "<", upper_time_bound).get().then(querySnapshot => {
                                                                    var promises2 = [];
                                                                    if (querySnapshot.docs.length > 0) {
                                                                        querySnapshot.docs.map(doc => {
                                                                            // console.log('LOG 1', doc.data());
                                                                            var tmp = doc.data();
                                                                            if (tmp.delete !== true) {
                                                                                if (tmp.data.data[1]) {
                                                                                    tmp['# recipients'] = Object.keys(users).length;
                                                                                    tmp['# clicked'] = 0;
                                                                                    tmp['# relevance'] = 0;
                                                                                    tmp['# comments'] = 0;
                                                                                    tmp['reading time'] = 0;
                                                                                    tmp['size'] = 0;
                                                                                    tmp['reputation change**'] = 0;
                                                                                    tmp['est cost*'] = 0;
                                                                                    tmp['subject'] = "";
                                                                                    tmp['read rate'] =0;
                                                                                    tmp['detail rate'] =0;
                                                                                    var collection = db.collection('post');
                                                                                    promises2.push(
                                                                                        collection.doc(tmp["postId"]).get().then((document) => {

                                                                                            if (document.exists) {
                                                                                                tmp['subject'] = document.data().subject;
                                                                                                tmp['size'] = document.data()['n_recipient'];
                                                                                            } else {
                                                                                                tmp['subject'] = "";
                                                                                            }
                                                                                            tmp['message'] = tmp.data.data[3];
                                                                                            var index = tmp.index;

                                                                                            var promises3 = [];
                                                                                            promises3.push(
                                                                                                db.collection("read_message").where("postId", "==", tmp["postId"])
                                                                                                    .where("index", "==", index).get().then(querySnapshot => {

                                                                                                    if (querySnapshot.docs.length > 0) {
                                                                                                        querySnapshot.docs.map(doc => {
                                                                                                            // console.log('LOG 1', doc.data());
                                                                                                            var row = doc.data();
                                                                                                            if (users[row.userId] !== undefined) {
                                                                                                                if (users[row.userId] === "explicit") {
                                                                                                                    tmp['# comments'] += row.comments.length;
                                                                                                                    tmp['# relevance'] += row.relevant;
                                                                                                                } else {
                                                                                                                    if (isNaN(row.time)) row.time = 0;
                                                                                                                    tmp['reading time'] += Math.min(row.time, 10*60);
                                                                                                                    tmp['# clicked'] += row.clicked;
                                                                                                                    if (row.read_level !== undefined){
                                                                                                                        tmp['read rate'] += row.read_level>0?1:0;
                                                                                                                        tmp['detail rate'] += row.read_level>1?1:0;
                                                                                                                    }
                                                                                                                }
                                                                                                            }

                                                                                                        });
                                                                                                        if (implicit === 0) implicit = 1;
                                                                                                        if (explicit === 0) explicit = 1;
                                                                                                        tmp['click rate'] = tmp['# clicked'] / implicit * 100;
                                                                                                        tmp['relevance rate'] = tmp['# relevance'] / explicit * 100;
                                                                                                        tmp['read rate'] /= implicit / 100;
                                                                                                        tmp['detail rate'] /= implicit / 100;
                                                                                                        if (implicit > 0 && tmp['reading time'] > 0.5) tmp['est cost*'] = 40 / 60 / 60 * (tmp['reading time']) * tmp['size'] / implicit;
                                                                                                        else tmp['est cost*'] = 0;
                                                                                                        if (tmp['reading time']> 0) tmp['reading time'] = tmp['reading time'] / implicit / open_rate_dict[tmp["postId"]];
                                                                                                        else tmp['reading time'] = 0;

                                                                                                    } else {
                                                                                                        tmp['read rate'] =0;
                                                                                                        tmp['detail rate'] =0;
                                                                                                        tmp['click rate'] = 0;
                                                                                                        tmp['relevance rate'] = 0;
                                                                                                        tmp['est cost*'] = 0;
                                                                                                        tmp['reading time'] = 0;
                                                                                                    }
                                                                                                    return tmp;
                                                                                                })
                                                                                            )
                                                                                            return Promise.all(promises3);
                                                                                        })
                                                                                    )


                                                                                }
                                                                            }
                                                                        });
                                                                        return Promise.all(promises2);
                                                                    }
                                                                })
                                                            )
                                                            return Promise.all(promises1);
                                                        }
                                                    )
                                                )
                                            })
                                            var items = Promise.all(promises);
                                            var msgs = {
                                                'click rate': 0,
                                                "relevance rate": 0,
                                                "# comments": 0,
                                                "reading time": 0,
                                                "est cost*": 0,
                                                'reputation change**': 0,
                                                'read rate': 0,
                                                'detail rate': 0,
                                            };
                                            var n_msg = 0;
                                            items.then(function (results) {
                                                results.forEach((t1) => {
                                                    if (t1 !== undefined) {
                                                        t1.forEach((t2) => {
                                                            if (t2 !== undefined) {
                                                                (t2).forEach((t3) => {
                                                                    if (t3 !== undefined) {
                                                                        t3 = t3[0];
                                                                        Object.keys(msgs).forEach(function (key) {
                                                                            msgs[key] += t3[key];

                                                                        });
                                                                        n_msg += 1;
                                                                    }

                                                                })
                                                            }
                                                        })
                                                    }

                                                });

                                                Object.keys(msgs).forEach(function (key) {
                                                    msgs[key] /= n_msg;
                                                });
                                                msgs["timestamp"] = date;
                                                msgs["type"] = "message";
                                                msgs["demo"] = demo;
                                                // console.log(msgs);
                                                db.collection('perf_avg').add(msgs);
                                            })

                                        })
                                )
                                return Promise.all(inner_promises);


                            })
                        }


                    });

                })
                )

                return Promise.all(outer_promises);

            }

            var func_promises = [];
            func_promises.push(update());
            var items = Promise.all(func_promises);
            items.then(function (results) {
                return results;
            })


        }
    }
</script>

<style scoped>

</style>