<template>
    <div class="w-3/4 m-auto">
        <div>



            <div id="my_area" >
                <h3  style="margin-top: 50px">Read the sentence and imagine the scene (for 8 seconds).</h3>
                <div  class="bg-gray-300 h-64 w-64 rounded-lg shadow-md bg-cover bg-center" style="margin-top: 50px" v-if="show">
                    <!--                <h3>Original Image</h3>-->
                    <img  crossorigin="anonymous" style="width: 80%; max-width: 500px"  alt="" :src="pre_img"/>
                    <img  crossorigin="anonymous" style="width: 80%; margin-top: 10px; max-width: 500px"   alt="" :src="post_img"/>
                </div>
                <h3  style="margin-top: 20px; font-weight: bold">{{title}}</h3>
            </div>



            <div v-if="show">
                <h3  style="margin-top: 30px">Supposing you want to check what happened for security reasons,</h3>
                <h3> does the sentence describe what happened accurately?</h3>
                <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group
                            id="radio-group-1"
                            v-model="value"
                            :options="options"
                            :aria-describedby="ariaDescribedby"
                            name="radio-options"
                            stacked
                    ></b-form-checkbox-group>
                </b-form-group>

                <h3  style="margin-top: 30px" v-if="!((this.value.length===0)||(this.value.length===1&&this.value[0]===4))">Any information in the sentence you feel missing/wrong/redundant?</h3>
                <b-textarea v-model="text" style="margin:auto; width: 80%; max-width: 500px" v-if="!((this.value.length===0)||(this.value.length===1&&this.value[0]===4))" placeholder="It's inaccurate because ..."></b-textarea>

                <div v-if="!((this.value.length===0)||(this.value.length===1&&this.value[0]===4))" style="margin-top: 20px">
                <h3> Does this sentence describe what happened accurately:</h3>
                <h3> {{title_true}}</h3>
                <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group
                            id="radio-group-2"
                            v-model="value_true"
                            :options="options"
                            :aria-describedby="ariaDescribedby"
                            name="radio-options"
                            stacked
                    ></b-form-checkbox-group>
                </b-form-group>

<!--                <h3  style="margin-top: 30px" v-if="!((this.value_true.length===0)||(this.value_true.length===1&&this.value_true[0]===4))">Any information in the sentence you feel missing/wrong/redundant?</h3>-->
<!--                <b-textarea v-model="text" style="margin:auto; width: 80%; max-width: 500px" v-if="!((this.value_true.length===0)||(this.value_true.length===1&&this.value_true[0]===4))" placeholder="It's inaccurate because ..."></b-textarea>-->
                </div>


                <b-button v-if="!this.load" variant="primary" @click="submit" style="margin-bottom: 30px">Submit</b-button>
                <p v-if="this.load">Received! Redirecting you to another test ... </p>
            </div>

        </div>
    </div>
</template>

<script lang="ts">
    import {db} from "../firebaseConfig";

    export default {
        name: 'ImageApp',
        props: {
            sessionId: {
                type: String,
                default: '',
            }
        },
        async created () {
            this.t=0;
            this.show=false;


            var collection = db.collection('captions_spotdiff');
            this.n_word = 0;
            var min_count = 0;
            var ids = [];
            collection.orderBy("count", "asc").limit(1).get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                        min_count = doc.data().count;
                    }
                );
                collection.where("count","==",min_count).limit(20).get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                            ids.push(doc.id);
                        }
                    );
                    this.id = ids[Math.floor(Math.random() * ids.length)];
                    collection.doc(this.id.toString()).get().then((document) => {
                        this.title = document.data().relativeCap;

                        this.pre_img = 'https://storage.googleapis.com/commtool_test_image/spotdiff_val_imgs_for_csv_200/' + document.data().referenceImg ;
                        this.post_img = 'https://storage.googleapis.com/commtool_test_image/spotdiff_val_imgs_for_csv_200/' + document.data().targetImg ;
                        this.title_true = document.data().groundTruth;
                        this.title_true = this.title_true.substring(1, this.title_true.length - 1);
                    })

            })


            })

        },
        mounted() {
            window.setInterval(() => {
                this.t+=1;
                if (this.t==8) this.show = true;
            }, 1000);

        },
        data:function() {
                return {
                    predictions:null,
                    show: false,
                    pre_img: '',
                    post_img: '',
                    t:0,
                    value:[],
                    value_true: [],
                    title: '',
                    title_true: '',
                    text: '',
                    options: [
                        { text: 'Not accurate - missing information', value: 1 },
                        { text: 'Not accurate - wrong information', value: 2 },
                        { text: 'Not accurate - redundant information', value: 3 },
                        { text: "Yes accurate", value: 4 },
                        { text: "Others", value: 0 },
                    ],
                    load: false,
                }
        },
        methods: {
            submit() {
                if (this.value.length == 0) {
                    this.$alert("Please select not accurate / not sure / yes accurate");
                } else {
                    db.collection("img_app_answer").add({
                        id: this.id,
                        value: this.value,
                        text: this.text,
                        value_true: this.value_true,
                        tstamp: new Date(),
                        title: this.title,
                        title_true: this.title_true,
                        pre_img: this.pre_img,
                        post_img: this.post_img,
                        sessionId: this.sessionId
                    })
                        .then((docRef) => {
                            if (this.sessionId === ''){
                                this.sessionId = docRef.id;
                                db.collection("img_app_answer").doc(this.sessionId).update({
                                    sessionId: this.sessionId
                                }).then(()=>{
                                    var sfDocRef = db.collection("captions_spotdiff").doc(this.id.toString());
                                    db.runTransaction((transaction) => {
                                        return transaction.get(sfDocRef).then((sfDoc) => {
                                            if (!sfDoc.exists) {
                                                throw "Document does not exist!";
                                            }

                                            var newcount = sfDoc.data().count + 1;
                                            transaction.update(sfDocRef, { count: newcount });
                                            return newcount;
                                        });
                                    }).then(() => {
                                        this.load = true;
                                        let that = this;
                                        setTimeout(function(){
                                            console.log(that.sessionId)
                                            that.$router.push('/image_app/'+that.sessionId);
                                            location.reload();
                                        }, 1000);
                                    }).catch((err) => {
                                        // This will be an "population is too big" error.
                                        this.load = true;
                                        console.error(err);
                                        let that = this;
                                        setTimeout(function(){
                                            that.$router.push('/image_app/'+that.sessionId);
                                            location.reload();
                                        }, 1000);
                                    });
                                })
                            }else {
                                var sfDocRef = db.collection("captions_spotdiff").doc(this.id.toString());
                                db.runTransaction((transaction) => {
                                    return transaction.get(sfDocRef).then((sfDoc) => {
                                        if (!sfDoc.exists) {
                                            throw "Document does not exist!";
                                        }
                                        var newcount = sfDoc.data().count + 1;
                                        transaction.update(sfDocRef, {count: newcount});
                                        return newcount;
                                    });
                                }).then(() => {
                                    this.load = true;
                                    setTimeout(function(){
                                        location.reload();
                                    }, 1000);
                                }).catch((err) => {
                                    this.load = true;
                                    console.error(err);
                                    setTimeout(function(){
                                        location.reload();
                                    }, 1000);

                                });
                            }

                        })
                }
            }
        }

    }
</script>
<!--gsutil -m acl set -R -a public-read gs://commtool_test_image/spotdiff_val_imgs_for_csv_200-->
<!--ruoyankong@cloudshell:~$ printf '[{"origin": ["*"],"responseHeader": ["*"],"method":-->
<!--["GET","POST","HEAD"],"maxAgeSeconds": 86400}]' > cors.json-->
<!--ruoyankong@cloudshell:~$ gsutil cors set cors.json gs://commtool_test_image-->
<!--Setting CORS on gs://commtool_test_image/...-->

