import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { firestorePlugin } from 'vuefire' // Added to make vuefire work
// import {auth} from './firebaseConfig.js' // for authentication
import datePicker from 'vue-bootstrap-datetimepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import { BootstrapVueIcons } from 'bootstrap-vue'
// import IdleVue from "idle-vue";
import store from './router/store'
import Vuex from 'vuex'
import { auth } from "@/firebaseConfig"
Vue.use(Vuex)
Vue.use(BootstrapVueIcons);
Vue.use(datePicker);
Vue.config.productionTip = false
Vue.use(firestorePlugin); // added to make vuefire work




let app // for authentication
auth.onAuthStateChanged(() => { // for authentication
  if (!app) { // for authentication
    app = new Vue({
      store,
      router,
      render: h => h(App)
    }).$mount('#app')

  }
})
// app =
// new Vue({
//   store,
//   router,
//   render: h => h(App)
// }).$mount('#app')

// const express = require('express');
// const app = express();
//
// app.get(
//     '/here', function () {
//       console.log("here");
//     }
// )
// var expressVue = require("express-vue");
// var app = expressVue();
//
// //pass your app through express-vue here
// //expressVueOptions is optional and explained later in the docs
// //this is a promise, so you can either await it or do this.
// expressVue.use(app, ).then(() => {
//   //the rest of your express routes.
// });