<template>
    <div></div>
</template>

<script>
    import {db} from "../firebaseConfig";
    export default {
        name: "EmailReminder",
        components: {},
        methods: {},
        async beforeCreate() {
            var outer_promises = []
            var date = new Date();
            var upper_time_bound = new Date(date.getTime() - (24 * 60 * 60 * 1000));
            var lower_time_bound = new Date(date.getTime() - (2 * 24 * 60 * 60 * 1000));
            //Getting the post within -48 hrs < current time < +24 hrs
            db.collection("post").where("timestamp", "<", upper_time_bound).where("timestamp", ">",lower_time_bound).get().then(querySnapshot => {
                if (querySnapshot.docs.length > 0) {
                    querySnapshot.docs.map(doc => {
                        var tmp = {};
                        var row = doc.data();
                        tmp['user_id'] = row.userId;
                        tmp['post_id'] = doc.id;
                        tmp['newsletterId'] = row.newsletterId;
                        tmp['subject'] = row.subject;
                        tmp['timestamp'] = row['timestamp']['seconds']*1000;
                        tmp['past_hour'] = Math.round((date - tmp['timestamp'])/1000.0/60/60);
                        outer_promises.push(tmp)
                        // Getting user info
                        db.collection("users").where("userId", "==", tmp['user_id']).get().then(
                            querySnapshot => {
                                if (querySnapshot.docs.length > 0) {
                                    querySnapshot.docs.map(doc => {
                                        var user = doc.data()
                                        var link = "https://www.comm-tool.com/report/" + user.userId + "/"
                                            + tmp['post_id'];
                                        var message_link = "https://www.comm-tool.com/email/" + tmp.newsletterId;
                                        var dashboard_link = 'https://www.comm-tool.com/dashboard';
                                        db.collection('mail').add({
                                            to: user.email,
                                            message: {
                                                subject: "Check Your Report of " + tmp['subject'],
                                                html: '<!DOCTYPE html><html lang="en"><body>' +
                                                    '<div>' +
                                                    'Your evaluation of ' + tmp['subject'] + " has been (" + tmp['past_hour'] + " hours since sent).<br> Please read the 3 reports below and <b>submit the <span style=\"color:  red\">3</span> surveys at the bottom of</b> the following pages:" +
                                                    '</div>' +
                                                    '<div>' +
                                                    '1. Dashboard of per-email performance <a href=' + dashboard_link + ' target="_blank">' + dashboard_link + '</a>' +
                                                    '</div>' +
                                                    '<div>' +
                                                    '2. Dashboard of per-story performance <a href=' + message_link + ' target="_blank">' + message_link + '</a>' +
                                                    '</div>' +
                                                    '<div>' +
                                                    '3. Sample of this email <a href=' + link + ' target="_blank">' + link + '</a>' +' (you can reply your recipients on this page; your reply will be shown anonymously as from the sender).'+
                                                    '</div>' +
                                                    '<div>' +
                                                    'Please email Ruoyan at kong0135@umn.edu if you have any questions.' +
                                                    '</div>' +
                                                    '</body></html>'
                                            },
                                        })
                                    }
                                    )
                                }
                            }
                        )
                    })
                }
            })
            // console.log(outer_promises)
            var items = Promise.all(outer_promises);
            items.then(function (results) {
                return results;
            })

        }
    }
</script>

<style scoped>
</style>