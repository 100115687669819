/* eslint-env jquery */
<template>
    <b-container  style="max-width: 90%;" fluid id="all">
        <div  >
            <h1 style="margin-top: 30px">  Dashboard -- All Emails' Performance </h1>
            <div class="row" style="margin-top:20px;" v-if="generating_report !== 'downloading'">
                <b-col md="5"></b-col>
                <b-col md="2" style="margin-bottom:20px;">
                    <b-button pill variant="primary" size="lg" href="/edit/new">Create Email</b-button>

                </b-col>

            </div>


            <b-row v-if="generating_report !== 'downloading'">
                <b-col lg="3"  class="my-1 row"><b-button variant="outline-secondary" size="sm" @click="clear_sort">Clear Sort X</b-button></b-col>
                <b-col lg="6" class="my-1 text-center" style="margin-bottom: 10px">
                    <b-form-group
                            label="Filter Subject / Message"
                            label-for="filter-input"
                            label-cols-sm="3"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-0"
                    >
                        <b-input-group size="sm">
                            <b-form-input
                                    id="filter-input"
                                    v-model="TestfilterV"
                                    type="search"
                                    placeholder="Type Any Key Words"
                            ></b-form-input>

                            <b-input-group-append>
                                <b-button :disabled="!TestfilterV" @click="TestfilterV = null">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>

                <b-col lg="3" class="my-1 text-right" ><b-link @click="share"><font-awesome-icon icon="fa-solid fa-share"/> Share this Page</b-link></b-col>
            </b-row>

            <div v-if="generating_report === 'begin'">
                <h4>Notes</h4>
                <div style="max-width: 70%;    display: table;margin-right: auto; margin-left: auto;">
                    <editor v-model="share_text"
                            api-key="6amjl4rlxjw7zvlf91tg3iz4m9ntrvj0tk1543pxopouuq9k"
                            placeholder = "Please input any notes you want to share"
                            :init="{
                                 menubar: false,
                                 content_style: 'img {max-width: 600px; }' + 'div {max-width: 600px}'+ 'td {max-width: 600px}'+ 'tr {max-width: 600px}'+ 'tbody {max-width: 600px}' +  'table {max-width: 600px}',
                                 plugins: [
                                     // 'autoresize',
                                   'advlist autolink lists link image charmap print preview anchor',
                                   'searchreplace visualblocks code fullscreen textcolor',
                                   'insertdatetime media table paste code help wordcount'
                                 ],
                                 toolbar:
                                   'undo redo | formatselect | bold italic forecolor backcolor | \
                                   alignleft aligncenter alignright alignjustify | \
                                   bullist numlist outdent indent | removeformat | help',
                                   // autoresize_on_init: true,
                               }"
                    />
                </div>
                <div>
                    <b-button variant="outline-secondary" style="margin: 10px" @click="close">Close</b-button>
                    <b-button variant="primary" @click="download">Download</b-button>
                </div>
            </div>

            <div v-if="generating_report === 'downloading'">
                <h4 style="max-width: 70%;    display: table;margin-right: auto; margin-left: auto;">Notes</h4>
                <div v-html="share_text"  style="max-width: 70%;    display: table;margin-right: auto; margin-left: auto;"></div>
            </div>

            <div class="row" >
                <b-table striped hover small :items="posts" :fields="fields" :sticky-header="stickyHeader"                  :sort-by.sync="sorttestBy"
                         :sort-desc.sync="sorttestDesc"                  :filter="Testfilter"
                         :filter-included-fields="TestfilterOn" @filtered="onTestFiltered">

                    <template #cell(newsletter)="row">
                        <!--                <b-button size="sm" @click="info(row.item, row.index, $event.target)" class="mr-1">-->
                        <b-button size="sm" @click="view_report(row.item)" class="mr-1" v-if="row.item.newsletterId">
                            + {{row.item.newsletter}}
                        </b-button>
                        <div v-if="row.item.newsletter === 'Avg Performance of All Communicators'"><b class="text-primary">{{row.item.newsletter}}</b></div>
                    </template>
                    <template #cell(subject)="row">
                        <p v-if="row.item.latest !== false && row.item.newsletter !== 'Avg Performance of All Communicators'"><b class="text-primary">NEW! </b></p>
                        {{row.item.subject}}
                    </template>
                    <template #cell(reputation_change)="row">
                        <span v-if="row.item.reputation_change !== undefined && row.item.reputation_change.length>1 && row.item.reputation_change[0]==='-'"><font-awesome-icon icon="fa-solid fa-arrow-down" style="color: red"/></span>
                        <span v-if="row.item.reputation_change !== undefined && row.item.reputation_change.length>1 && row.item.reputation_change[0]!=='-' && row.item.reputation_change[0]!=='0'"><font-awesome-icon icon="fa-solid fa-arrow-up" style="color: green"/></span>
                        {{row.item.reputation_change}}
                    </template>
                    <template #head(newsletter)="data">
                    <span v-b-tooltip.hover.righttop="'The sender name that this series of emails uses'">
                        {{ data.label}}
                        <font-awesome-icon
                                icon="fa-solid fa-question-circle"/>
                    </span>
                    </template>
                    <template #head(subject)="data">
                    <span v-b-tooltip.hover.righttop="'The email subject'">
                        {{ data.label}}
                        <font-awesome-icon
                                icon="fa-solid fa-question-circle"/>
                    </span>
                    </template>
                    <template #head(n_recipients)="data">
                    <span v-b-tooltip.hover.righttop="'The number of participants you sent this email to, including the explicit participant group who we ask to like/comment and the implicit participant group who we ask to react as normal as they would.'">
                        {{ data.label}}
                        <font-awesome-icon
                                icon="fa-solid fa-question-circle"/>
                    </span>
                    </template>
                    <template #head(open_rate)="data">
                    <span v-b-tooltip.hover.righttop="'The percentage of the implicit participants who opened this email.'">
                        {{ data.label}}
                        <font-awesome-icon
                                icon="fa-solid fa-question-circle"/>
                    </span>
                    </template>
                    <template #head(click_rate)="data">
                    <span v-b-tooltip.hover.righttop="'The percentage of the implicit participants who at least clicked one link in this email.'">
                        {{ data.label}}
                        <font-awesome-icon
                                icon="fa-solid fa-question-circle"/>
                    </span>
                    </template>
                    <template #head(read_rate)="data">
                    <span v-b-tooltip.hover.righttop="'The percentage of the implicit participants who skimmed this email or read it in detail.'">
                        {{ data.label}}
                        <font-awesome-icon
                                icon="fa-solid fa-question-circle"/>
                    </span>
                    </template>
                    <template #head(detail_rate)="data">
                    <span v-b-tooltip.hover.righttop="'The percentage of the implicit participants who read this email in detail.'">
                        {{ data.label}}
                        <font-awesome-icon
                                icon="fa-solid fa-question-circle"/>
                    </span>
                    </template>
                    <template #head(relevance_rate)="data">
                    <span v-b-tooltip.hover.righttop="'The percentage of the explicit participants who at least indicated one message of this email that is relevant to them.'">
                        {{ data.label}}
                        <font-awesome-icon
                                icon="fa-solid fa-question-circle"/>
                    </span>
                    </template>
                    <template #head(n_comments)="data">
                    <span v-b-tooltip.hover.righttop="'The number of comments the explicit participants left on this email.'">
                        {{ data.label}}
                        <font-awesome-icon
                                icon="fa-solid fa-question-circle"/>
                    </span>
                    </template>
                    <template #head(reading_time)="data">
                    <span v-b-tooltip.hover.righttop="'The average number of seconds the implicit participants who opened this email spent on reading this email.'">
                        {{ data.label}}
                        <font-awesome-icon
                                icon="fa-solid fa-question-circle"/>
                    </span>
                    </template>
                    <template #head(est_cost)="data">
                    <span v-b-tooltip.hover.righttop="'The estimated money cost of this email, defined as the average reading time * open rate * $40 hour rate * the actual number of recipients of this newsletter.  6 seconds are added for recipient\'s time on making read / unread decisions.'">
                        {{ data.label}}
                        <font-awesome-icon
                                icon="fa-solid fa-question-circle"/>
                    </span>
                    </template>
                    <template #head(reputation_change)="data">
                    <span v-b-tooltip.hover.righttop="'This email\'s influence on this newsletter\'s reputation, defined as this newsletter\'s predicted future open rate - this email\'s open rate. This metric will be available after week 2.'">
                        {{ data.label}}
                        <font-awesome-icon
                                icon="fa-solid fa-question-circle"/>
                    </span>
                    </template>
                    <template #head(date)="data">
                    <span v-b-tooltip.hover.righttop="'The date you sent this email on CommTool. For the first row (avg performance of all communicators), it refers to when the system updated the numbers of that row (we update daily).'">
                        {{ data.label}}
                        <font-awesome-icon
                                icon="fa-solid fa-question-circle"/>
                    </span>
                    </template>

                </b-table>
            </div>
        </div>






    </b-container>
</template>

<script>
    // import { db } from "../firebaseConfig.js"
    import { auth } from "@/firebaseConfig"
    import firebase from 'firebase'
    import {db} from "../firebaseConfig";
    import html2pdf from "html2pdf.js";
    import * as tf from "@tensorflow/tfjs";
    import dateFormat from "dateformat";
    import Editor from '@tinymce/tinymce-vue'
    // import VueApexCharts from "vue-apexcharts"
    export default {
        name: "DashboardAdmin",
        components: {
            // apexchart: VueApexCharts,
            Editor
        },
        async created () {
            document.title = "Comm Tool: Email Dashboard";
            this.model = await tf.loadLayersModel('https://storage.googleapis.com/commtool-6d611.appspot.com/json/reputation7/model.json');
            db.collection("perf_avg").where("type", "==", "post").orderBy("timestamp", "desc").limit(1).get().then(
                querySnapshot => {
                    querySnapshot.docs.map((doc) => {
                        var tmp = doc.data();
                        tmp['newsletter'] = 'Avg Performance of All Communicators'
                        tmp['open_rate'] = Math.round(tmp['open rate']).toString()+"%";
                        tmp['click_rate'] = Math.round(tmp['click rate']).toString()+"%";
                        tmp['read_rate'] = Math.round(tmp['read rate']).toString() + "%";
                        tmp['detail_rate'] = Math.round(tmp['detail rate']).toString() + "%";
                        tmp['relevance_rate'] = Math.round(tmp['relevance rate']).toString()+"%";
                        tmp['est_cost'] = "$"+Math.round(tmp['est cost*']);
                        tmp['reading_time'] = Math.round(tmp['reading time']).toString()+ "s";
                        tmp['n_comments'] = Math.round(tmp['# comments']).toString()
                        tmp['postId'] = doc.id;
                        tmp['newsletterId'] = '';
                        tmp['real_newsletter_id'] = '';
                        tmp['date'] = dateFormat(new Date(tmp['timestamp']['seconds']*1000), "yyyy-mm-dd");
                        tmp['_rowVariant'] = 'secondary';
                        tmp['latest'] = true;

                        this.posts.push(tmp);
                    })
                    var d = {};
                    var seen_newsletter = new Set();


                    db.collection("users").where("recipient_checked", "==", true)
                        .where("demo","==",(this.demo || this.$store.state.demo)).get().then(querySnapshot => {
                            this.users = {};
                            var explicit_staff = 0;
                            var explicit_faculty = 0;
                            var implicit_staff = 0;
                            var implicit_faculty = 0;
                            querySnapshot.docs.map((doc) => {
                                // console.log('LOG 1', doc.data());
                                if (this.demo || this.$store.state.demo){
                                    if (doc.data().demo !== true) return;
                                }else{
                                    if (doc.data().demo === true) return;
                                }
                                this.users[doc.data().userId] = doc.data().group;
                                if (doc.data().group === "explicit") {
                                    if (doc.data().category === "staff") explicit_staff += 1;
                                    else explicit_faculty += 1
                                }
                                else{
                                    if (doc.data().category === "staff") implicit_staff += 1;
                                    else implicit_faculty += 1
                                }
                            });
                            db.collection("post").orderBy('userId').orderBy('newsletterId').orderBy('timestamp', 'desc').get().then(querySnapshot => {
                                if (querySnapshot.docs.length > 0) {
                                    var variant = false;
                                    var promises = [];

                                    querySnapshot.docs.map(doc => {
                                        // console.log('LOG 1', doc.data());

                                        var tmp = doc.data();
                                        if (this.demo || this.$store.state.demo){
                                            if (doc.data().demo !== true) return;
                                        }else{
                                            if (doc.data().demo === true) return;
                                        }
                                        var explicit = 0;
                                        var implicit = 0;
                                        if (tmp.audience.includes("staff")){
                                            explicit += explicit_staff;
                                            implicit += implicit_staff
                                        }
                                        if (tmp.audience.includes("faculty")){
                                            explicit += explicit_faculty;
                                            implicit += implicit_faculty;
                                        }
                                        if (this.demo || this.$store.state.demo){
                                            if (tmp.demo !== true) return;
                                        }else{
                                            if (tmp.demo === true) return;
                                        }

                                        tmp['postId'] = doc.id;

                                        if (tmp.delete !== true) {
                                            tmp['n_recipients'] = explicit+implicit;
                                            tmp['n_opened'] = 0;
                                            tmp['n_clicked'] = 0;
                                            tmp['n_relevance'] = 0;
                                            tmp['n_comments'] = 0;
                                            tmp['reading_time'] = 0;
                                            tmp['size'] = parseInt(tmp['n_recipient']);
                                            tmp['reputation_change'] = 0;
                                            tmp['est_cost'] = 0;
                                            tmp['newsletter'] = "";
                                            tmp['read_rate'] = 0;
                                            tmp['detail_rate'] = 0;
                                            tmp['msg read rate'] = 0;
                                            tmp['msg detail rate'] = 0;
                                            tmp['comment_rate'] = 0;
                                            tmp['commented'] = 0;
                                            tmp['log reading time'] = 0;
                                            tmp['latest'] = false;

                                            tmp['date'] = dateFormat(new Date(tmp['timestamp']['seconds']*1000), "yyyy-mm-dd");
                                            this.last_date = Math.max(this.last_date, tmp['timestamp']['seconds']);
                                            var postId = doc.id;
                                            var newsletterId = tmp['newsletterId'];
                                            if (d[newsletterId] === undefined) d[newsletterId] = [];
                                            var collection = db.collection('newsletter');
                                            promises.push(
                                                collection.doc(newsletterId).get().then((document) => {

                                                    if (document.exists) {
                                                        tmp['newsletter'] = document.data().newsletter_brand;
                                                    } else {
                                                        tmp['newsletter'] = "";
                                                    }



                                                    return   db.collection("read_post").where("postId", "==", postId).get().then(querySnapshot => {

                                                        if (querySnapshot.docs.length > 0) {
                                                            querySnapshot.docs.map(doc => {
                                                                // console.log('LOG 1', doc.data());
                                                                var row = doc.data();
                                                                if (this.users[row.userId] !== undefined) {
                                                                    if (this.users[row.userId] === "explicit") {
                                                                        tmp['n_comments'] += row.comments;
                                                                        tmp['commented'] += row.comments>0?1:0;
                                                                        tmp['n_relevance'] += (row.relevant > 0);
                                                                    } else {
                                                                        if (isNaN(row.time)) row.time =0;
                                                                        tmp['reading_time'] += Math.min(row.time, 30*60);
                                                                        tmp['n_opened'] += 1;
                                                                        tmp['n_clicked'] += (row.clicked > 0);
                                                                        if (row.read_level !== undefined){
                                                                            tmp['read_rate'] += row.read_level>0?1:0;
                                                                            tmp['detail_rate'] += row.read_level>1?1:0;
                                                                        }
                                                                        if (row.read !== undefined){
                                                                            tmp['msg read rate'] += row.read>0?1:0;
                                                                        }
                                                                        if (row.detail !== undefined){
                                                                            tmp['msg detail rate'] += row.detail>0?1:0;
                                                                        }
                                                                    }
                                                                }

                                                            });
                                                            if (implicit === 0) implicit = 1;
                                                            if (explicit === 0) explicit = 1;
                                                            tmp['open rate f'] = tmp['n_opened'] / implicit;
                                                            tmp['click rate f'] = tmp['n_clicked'] / implicit * 100;
                                                            tmp['read rate f'] = tmp['read_rate'] /implicit * 100;
                                                            tmp['detail rate f'] = tmp['detail_rate']/ implicit* 100;
                                                            tmp['msg read rate f'] = tmp['msg read rate']/implicit* 100;
                                                            tmp['msg detail rate f'] = tmp['msg detail rate']/implicit* 100;
                                                            tmp['comment rate f'] = tmp['commented'] / explicit * 100;
                                                            tmp['relevance rate f'] = tmp['n_relevance'] / explicit * 100;
                                                            if (d[newsletterId].length === 0) tmp['history open rate f'] = 0.5;
                                                            else tmp['history open rate f'] = this.mean(d[newsletterId], 4);

                                                            if (tmp['n_opened'] > 0) tmp['log reading time'] = Math.log(1+tmp['reading_time'] / tmp['n_opened']);
                                                            else tmp['log reading time'] = 0;
                                                            tmp['open_rate'] = Math.round(tmp['n_opened'] / implicit * 100).toString() + "%";
                                                            tmp['click_rate'] = Math.round(tmp['n_clicked'] / implicit * 100).toString() + "%";
                                                            tmp['read_rate'] = Math.round(tmp['read_rate'] / implicit*100).toString() + "%";
                                                            tmp['detail_rate'] = Math.round(tmp['detail_rate'] / implicit*100).toString() + "%";
                                                            tmp['relevance_rate'] = Math.round(tmp['n_relevance'] / explicit * 100).toString() + "%";
                                                            tmp['est_cost'] = "$" + Math.round(40 / 60.0 / 60.0 * (tmp['reading_time']/ implicit+6) * parseInt(tmp['size']) );
                                                            if (tmp['n_opened'] > 0) tmp['reading_time'] = Math.round(tmp['reading_time'] / tmp['n_opened']).toString() + "s";
                                                            else tmp['reading_time'] = "0s"
                                                            // To do: remove after week 2
                                                            // tmp['reputation_change'] = Math.round((Math.exp(this.predict(tmp)+Math.log(tmp['open rate f'])) - tmp['open rate f'])*100 )+"%";
                                                            tmp['reputation_change'] = Math.round(this.predict(tmp)*100)+"%";
                                                            // tmp['reputation_change'] = '-'
                                                            d[newsletterId].push(tmp['n_opened'] / implicit);

                                                        } else {

                                                            tmp['open rate f'] = 0;
                                                            tmp['click rate f'] = 0;
                                                            tmp['read rate f'] = 0;
                                                            tmp['detail rate f'] = 0;
                                                            tmp['msg read rate f'] = 0;
                                                            tmp['msg detail rate f'] = 0;
                                                            tmp['comment rate f'] = 0;
                                                            tmp['relevance rate f'] = 0;
                                                            if (d[newsletterId].length === 0) tmp['history open rate f'] = 0.5;
                                                            else tmp['history open rate f'] = this.mean(d[newsletterId], 4);
                                                            d[newsletterId].push(0);
                                                            // To do: remove after week 2
                                                            // tmp['reputation_change'] = Math.round((Math.exp(this.predict(tmp)+Math.log(tmp['open rate f'])) - tmp['open rate f'])*100 )+"%";
                                                            // tmp['reputation_change'] = '-';
                                                            tmp['reputation_change'] = Math.round(this.predict(tmp)*100)+"%";
                                                            if (tmp['n_opened'] > 0) tmp['log reading time'] = Math.log(1+tmp['reading_time'] / tmp['n_opened']);
                                                            else tmp['log reading time'] = 0;

                                                            tmp['open_rate'] = "0%"
                                                            tmp['click_rate'] = "0%"
                                                            tmp['relevance_rate'] = "0%"
                                                            tmp['est_cost'] = "$" + Math.round(40 / 60.0 / 60.0 * 6 * parseInt(tmp['size']) );
                                                            tmp['reading_time'] = "0s";
                                                            tmp['read_rate'] = "0%";
                                                            tmp['detail_rate'] = "0%";
                                                        }
                                                        if (!seen_newsletter.has(tmp['newsletterId'])) variant = !variant;
                                                        tmp['_rowVariant'] = variant?'light':'secondary';
                                                        tmp['real_rowVariant'] = tmp['_rowVariant'];
                                                        tmp['real_newsletter_id'] = tmp['newsletterId'];
                                                        if (seen_newsletter.has(newsletterId)){
                                                            tmp['newsletterId'] = '';
                                                        }else{
                                                            seen_newsletter.add(newsletterId);
                                                        }


                                                        this.posts.push(tmp);
                                                    })

                                                })
                                            )
                                        }

                                    });
                                    var items = Promise.all(promises);
                                    items.then(()=>{
                                        this.last_date = dateFormat(this.last_date*1000, "yyyy-mm-dd");
                                        this.posts.forEach((post)=> {
                                            if (post['newsletter'] !== 'Avg Performance of All Communicators' && post['date'] === this.last_date) post['latest'] = true;
                                            else if (post['newsletter'] !== 'Avg Performance of All Communicators') post['latest'] = false;
                                        })
                                    })
                                }


                            })

                        }
                    )
                }
            )


        },
        beforeCreate: function() {
            auth.onAuthStateChanged(user => {
                console.log("user state:", user);
                // uncomment above to check out which user properties are available.
                if (!user) {
                    //this.$router.push("/"); this have already been done in router
                }else{
                    this.user = user;
                    if (!user) {
                        //this.$router.push("/"); this have already been done in router
                    }else{
                        if ((this.user.email !== "ruoyankong@gmail.com") && (this.user.email !== "yuan0191@umn.edu")){
                            this.$router.push("/");
                        }
                    }
                    var collection = db.collection('users');
                    var docID = this.user.uid;
                    collection.doc(docID).get().then((document) => {
                        if(document.exists){
                            if (!document.data().communicator_checked) this.$router.push("/thank_login")
                            // this.demo = document.data().demo;
                            this.demo = false;
                            // this.$router.go();
                        }else{
                            this.$router.push("/register");
                            // this.$router.go();
                        }
                    })

                }
            });

        },

        data : function() {
            return {
                fields: [
                    { key: 'userId', class: 'text-center', sortable:true},
                    { key: 'newsletter', class: 'text-center',thStyle: { width: "12%" }, sortable:true},
                    { key: 'subject', class: 'text-center',thStyle: { width: "15%" } , sortable:true},
                    { key: "n_recipients", class: 'text-center', label: "# Recipients"},
                    { key: "open_rate", class: 'text-center', label: "Open Rate" , sortable:true},
                    { key: 'click_rate', class: 'text-center', sortable:true},
                    { key: 'read_rate', class: 'text-center', sortable:true},
                    { key: 'detail_rate', class: 'text-center', sortable:true},
                    { key: "relevance_rate",class: 'text-center', sortable:true},
                    { key: "n_comments",class: 'text-center', label: '# Comments', sortable:true},
                    { key: "reading_time", class: 'text-center', sortable:true },
                    { key: "est_cost", class: 'text-center', sortable:true },
                    { key: "reputation_change", class: 'text-center', sortable:true},
                    { key: "date", class: 'text-center', sortable:true,}
                ],
                demo: false,
                generating_report: '',
                share_text: '',
                posts:[],
                users: {},
                sorttestBy: '',
                sorttestDesc: false,
                // cols: ["open rate f", "log reading time", "read rate f","detail rate f","msg detail rate f", "msg read rate f","relevance rate f", "click rate f", 'comment rate f'],//"history open rate f",
                cols: ["open rate f",  "msg read rate f", "msg detail rate f","click rate f", ],
                time: 0,
                docid: "",
                metric_options: ["Average performance of all communicators", "Open Rate", "Click Rate", "Read Rate", "Detail Rate", "Relevance Rate", "# Comments", "Reading Time", "Est Cost", "Reputation Change", "None of those are helpful."],
                decision_options: ["Send to more people", "Send to fewer people", "Share this report with my clients", "Add more contents", "Remove or shorten some contents", "Move some contents to other newsletter","None of those"],
                feedback: '',
                metrics: [],
                decisions: [],
                submit_msg: "Please take this survey each time:",
                last_date: 0,
                stickyHeader: false,
                TestfilterV: null,
                Testfilter: '',
                TestfilterOn: [],
            }


        },

        computed: {
            state() {
                return this.metrics.length>=1
            },
            decision_state(){
                return this.decisions.length>=1
            }
        },

        mounted() {
            window.setInterval(() => {
                this.updateTime();
            }, 1000);
        },

        firestore: function () {
            if (!firebase.auth().currentUser){
                return {}
            }else{
                return {
                    // mylists: db.collection("lists").where("shared_with", "array-contains", firebase.auth().currentUser.email).orderBy("created_at", "desc"),
                }
            }



        },

        methods:{
            close () {
                this.generating_report = '';
            },
            download () {
                this.generateReport()
            },
            onTestFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                // if (filteredItems.length>0 && filteredItems[0].postId !== ''){
                //     if (this.messages.length>0){
                //         filteredItems.unshift(this.messages[0]);
                //         this.$emit('filtered', filteredItems)
                //     }
                // }

                this.totaltestRows = filteredItems.length

                this.currenttestPage = 1
            },
            handleSubmit() {
                if (this.decisions.length === 0 || this.metrics.length === 0) return;
                var data = [];
                this.posts.forEach((row)=>{
                        var tmp = {};
                        Object.keys(row).forEach(function(key) {
                            if (key!=="email_html"){
                                tmp[key] = row[key]
                            }
                        });
                        data.push(tmp);
                    }
                )
            },

            updateTime(){
                this.time += 1;
                if (this.time === 30){
                    var data = [];
                    this.posts.forEach((row)=>{
                            var tmp = {};
                            Object.keys(row).forEach(function(key) {
                                if (key!=="email_html"){
                                    tmp[key] = row[key]
                                }
                            });
                            data.push(tmp);
                        }
                    )

                }
            },
            predict(tmp){
                var x = [];
                this.cols.forEach((col)=>x.push(tmp[col]));
                x = [x];
                var preds = this.model.predict([tf.tensor2d(x)]).dataSync();
                return preds[0];
            },
            mean(array, n){
                var sub = array.slice(-n)
                const average = sub.reduce((a, b) => a + b);
                return average/sub.length;

            },
            share(){
                this.generating_report = 'begin';
            },
            generateReport () {
                this.stickyHeader = false;
                this.generating_report = 'downloading';
                const me = this;
                me.dom = "#all"
                me.name = "dashboard.pdf"
                const invoice = document.querySelector(me.dom);

                var opt = {
                    margin: 0,
                    filename: me.name,
                    html2canvas: {width: window.innerWidth/1.8}
                };
                html2pdf().from(invoice).set(opt).save().then(()=>{this.stickyHeader = false; this.generating_report = '';});
                var data = [];
                this.posts.forEach((row)=>{
                        var tmp = {};
                        Object.keys(row).forEach(function(key) {
                            if (key!=="email_html"){
                                tmp[key] = row[key]
                            }
                        });
                        data.push(tmp);
                    }
                )

            },
            view_report(item){
                if (item['newsletterId'] !== undefined)
                    this.$router.push('/email_admin/'+item['newsletterId']);
            },
            // info(item, index, button) {
            //     this.infoModal.title = `Row index: ${index}`
            //     this.infoModal.content = JSON.stringify(item, null, 2)
            //     this.$root.$emit('bv::show::modal', this.infoModal.id, button)
            // },
            // resetInfoModal() {
            //     this.infoModal.title = ''
            //     this.infoModal.content = ''
            // },
            onEmailFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalEmailRows = filteredItems.length

                this.currentEmailPage = 1
            },
            onMessageFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalMessageRows = filteredItems.length

                this.currentMessagePage = 1
            },
            clear_sort(){
                this.sorttestBy = '';
                this.sorttestDesc = false;
            }


        },
        watch: {
            TestfilterV(newvalue) {
                if (!newvalue) this.Testfilter = null;
                else{
                    this.Testfilter = new RegExp("Avg Performance|" + newvalue, "i");
                }
            },
            sorttestBy(newSortBy, old) {
                console.log(old)
                if (newSortBy !== '' && newSortBy !== 'newsletter' ) {
                    this.posts.forEach((value) => {
                            value.newsletterId = value.real_newsletter_id;
                            value['_rowVariant'] = '';
                        }
                    );
                }else{
                    var last_newsletter_id = '1';
                    if (!this.sorttestDesc) {

                        this.posts.forEach((value) => {
                            if (value.real_newsletter_id !== last_newsletter_id) {
                                value.newsletterId = value.real_newsletter_id;
                            } else {
                                value.newsletterId = '';
                            }
                            value['_rowVariant'] = value['real_rowVariant'];
                            last_newsletter_id = value.real_newsletter_id;
                        });
                    }else{
                        var n = this.posts.length;
                        if (n>0){
                            this.posts[0].newsletterId = this.posts[0].real_newsletter_id;
                            this.posts[0]['_rowVariant'] = this.posts[0]['real_rowVariant'];
                        }
                        for (let i=1; i<n-1;i++){
                            this.posts[i]['_rowVariant'] = this.posts[i]['real_rowVariant'];
                            if (this.posts[i].real_newsletter_id !== this.posts[i+1].real_newsletter_id){
                                this.posts[i].newsletterId = this.posts[i].real_newsletter_id;
                            }else{
                                this.posts[i].newsletterId = '';
                            }
                        }
                    }
                }
            },
            sorttestDesc(newsorttestDesc, old){
                console.log(old);
                if (this.sorttestBy !== '' && this.sorttestBy !== 'newsletter') {
                    this.posts.forEach((value) => {
                        value.newsletterId = value.real_newsletter_id
                        value['_rowVariant'] = '';
                    });
                }else{
                    var last_newsletter_id = '1';
                    var n, value;
                    if (!old) {
                        if (newsorttestDesc) {
                            this.posts.forEach((value) => {
                                if (value.real_newsletter_id !== last_newsletter_id) {
                                    value.newsletterId = value.real_newsletter_id;
                                } else {
                                    value.newsletterId = '';
                                }
                                last_newsletter_id = value.real_newsletter_id;
                                value['_rowVariant'] = value['real_rowVariant']
                            });
                        } else {
                            n = this.posts.length;
                            for (let i = n - 1; i >= 0; i--) {
                                value = this.posts[i];
                                if (value.real_newsletter_id !== last_newsletter_id) {
                                    value.newsletterId = value.real_newsletter_id;
                                } else {
                                    value.newsletterId = '';
                                }
                                last_newsletter_id = value.real_newsletter_id;
                                value['_rowVariant'] = value['real_rowVariant']
                            }
                        }
                    }else{
                        if (!newsorttestDesc) {
                            this.posts.forEach((value) => {
                                if (value.real_newsletter_id !== last_newsletter_id) {
                                    value.newsletterId = value.real_newsletter_id;
                                } else {
                                    value.newsletterId = '';
                                }
                                last_newsletter_id = value.real_newsletter_id;
                                value['_rowVariant'] = value['real_rowVariant']
                            });
                        } else {
                            n = this.posts.length;
                            for (let i = n - 1; i >= 0; i--) {
                                value = this.posts[i];
                                if (value.real_newsletter_id !== last_newsletter_id) {
                                    value.newsletterId = value.real_newsletter_id;
                                    value.newsletterId = value.real_newsletter_id;
                                } else {
                                    value.newsletterId = '';
                                }
                                value['_rowVariant'] = value['real_rowVariant']
                                last_newsletter_id = value.real_newsletter_id;
                            }
                        }
                    }
                }
            }
        }
    }
</script>

