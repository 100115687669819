<template>
    <div>
        <h1>TensorFlow.js Hello World</h1>
        <b-button @click="predict">predict</b-button>
        <!-- Import TensorFlow.js library -->

        <!-- Import the page's JavaScript to do some stuff -->
    </div>
</template>

<script>
    import  * as tf from '@tensorflow/tfjs';
    // import browser from 'browser';
    export default {
        name: "Model",
        mounted() {
            // let recaptchaScript = document.createElement('script')
            // recaptchaScript.setAttribute('src', "https://cdn.jsdelivr.net/npm/@tensorflow/tfjs/dist/tf.min.js")
            // document.head.appendChild(recaptchaScript)
        },
        methods: {
            predict: async function () {
                // var fburl = browser.extension.getURL("assets/my-model.weights.bin");
                //
                // fetch(fburl).then(response=> response.arrayBuffer() ).then(
                //     buffer=>
                //     {
                //         var fb = new File([buffer],"mymodel.weights.bin", {type: 'application/octet-binary'});
                //         tf.loadLayersModel(tf.io.browserFiles(["assets/my-model.json", fb])).then( model=>console.log(model.predict(tf.tensor2d([[1337]])).print()));
                //     }
                // );

                const model = await tf.loadLayersModel('https://storage.googleapis.com/commtool-6d611.appspot.com/json/my-model.json');
                console.log(model.summary());
                model.predict(tf.tensor2d([[1337]])).print();
            }
        }
    }
</script>

<style scoped>

</style>