<template>
    <div class="home">
        <span><br><br>You have been logged out after 3 minutes of idling. Please click the link from your inbox to continue reading. Thank you!

        </span>
    </div>
</template>

<script>
    export default {
        name: "Logout.vue",
    }
</script>

<style scoped>

</style>