<template>
    <div class="row" style="margin-top:5px;">
        <b-col md="4">
        </b-col>
        <b-col md="4">

            <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                <label>Select 8 posts: </label>
                <b-form-checkbox-group  v-if="this.posts.length>0"
                                        id="post"
                                        v-model="posts_selected"
                                        name="radio-options3"
                                        stacked
                                        style="margin-bottom: 20px"
                >
                    <b-form-checkbox
                            v-for="post in posts"
                            :value="post"
                            :key="post.postId"
                    >
                        {{ post["subject"] }}
                    </b-form-checkbox>
                    <b-form-invalid-feedback :state="state">Please select at least 8 posts.</b-form-invalid-feedback>
                </b-form-checkbox-group>

                <b-form-group
                        id="input-group-1"
                        label="Email address:"
                        label-for="input-1"
                        description="The inbox that the participants use to browse test emails, e.g., kong0135@umn.edu."
                >
                    <b-form-input
                            id="input-1"
                            v-model="form.email"
                            type="email"
                            placeholder="Enter email"
                            required
                    ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-2" label="ParticipantID:" label-for="input-2">
                    <b-form-input
                            id="input-2"
                            v-model="form.participantId"
                            placeholder="e.g., kong0135, or kong0135v1, use a different ID for each test (do not use _)."
                            required
                    ></b-form-input>
                </b-form-group>



                <b-button type="submit" variant="primary" style="margin-right: 10px">Send Test Email</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
            </b-form>
        </b-col>
    </div>
</template>

<script>
    import {db} from "../firebaseConfig";

    export default {
        name: "EyeTrackingTest",
        data() {
            return {
                form: {
                    email: '',
                    participantId: '',
                },
                show: true,
                posts: [],
                posts_selected: []
            }
        },
        firestore: function () {
            return {

            }
        },
        computed: {
            state() {
                return this.posts_selected.length >=8
            }
        },

        created () {
            this.posts = []
            db.collection("post").get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                        var tmp = doc.data();
                        if (tmp.delete !== true) {
                            this.posts.push({postId: doc.id, subject: tmp.subject})
                        }
                    }
                )
                this.posts.sort(function(a, b){return a.subject.localeCompare(b.subject)});
            })
        },
        methods: {
            onSubmit(event) {
                event.preventDefault();
                if (this.posts_selected.length<8) return;
                var docRef = db.collection("participants").doc(this.form.participantId);
                docRef.get().then((doc) => {
                    if (doc.exists) {
                        alert("this participantId already exists, please use a different one, e.g. kong0135_p2")
                    }else if (this.form.email === '' || this.form.participantId === ''){
                        alert("empty email or participantId");
                    }else {

                        // var posts = [
                        //     {postId: "BSvW3BYKfpWjDC0o7Hit", subject: "Synthesist: May 17, 2022"},
                        //     {postId: "51FTStG39lZnkzC9R8PL", subject: "U of M Brief (May 18, 2022)"},
                        //     {postId: "9IByvP9ShnibixGB7sVE", subject: "Synthesist: May 3, 2022"},
                        //     {postId: "hCYs37wsZDrrNKfURhqx", subject: "U of M Brief (May 11, 2022)"},
                        //     {postId: "SP7KZU158HwUD0AIKsWK", subject: "Synthesist: April 19, 2022"},
                        //     {postId: "fQQXycpIx3icHrJshyMX", subject: "U of M Brief (May 4, 2022)"},
                        //     {postId: "EF9cyVsgwxKE5m8A5jck", subject: "Synthesist: April 5, 2022"},
                        //     {postId: "BaVQRwxV06Y5gvPE8XMa", subject: "U of M Brief (April 27, 2022)"},
                        //
                        // ];
                        this.posts_selected.forEach(function (post) {
                            var link = "https://www.comm-tool.com/eye_tracking_test_view/" + this.form.participantId + "/" + post.postId;
                            // console.log(index, item.userId, link);
                            db.collection('mail').add({
                                to: this.form.email,
                                message: {
                                    subject: post.subject,
                                    html: '<a href=' + link + ' target="_blank">click to read</a><br>&nbsp;<br>&nbsp;<br>&nbsp;<br>&nbsp;<br>&nbsp;<br>&nbsp;<br>&nbsp;<br>&nbsp;<br>&nbsp;<br>&nbsp;<br>&nbsp;<br>&nbsp;<br>&nbsp;<br>&nbsp;<br>&nbsp;'+this.form.participantId.toString(),
                                },
                            })
                        }, this);
                        db.collection("participants").doc(this.form.participantId).set({sent_time: new Date()});
                        alert("Sent! Please check whether " + this.form.email + " get test emails in 1 minute -- links should contain " + this.form.participantId);
                        this.form.email = ''
                        this.form.participantId = ''
                        // Trick to reset/clear native browser form validation state
                        this.show = false
                        this.$nextTick(() => {
                            this.show = true
                        })
                    }
                })
            },
            onReset(event) {
                event.preventDefault()
                // Reset our form values
                this.form.email = ''
                this.form.participantId = ''
                this.posts_selected = []
                // Trick to reset/clear native browser form validation state
                this.show = false
                this.$nextTick(() => {
                    this.show = true
                })
            }
        }
    }
</script>

<style scoped>

</style>