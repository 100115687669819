<template>
    <div class="home">
        <!--    <img alt="Vue logo" src="../assets/logo3-lg.png" class="img-fluid" style="max-height:500px;">-->
        <CommAuth/>
    </div>
</template>

<script>
    import CommAuth from "../views/CommAuth"
    export default {
        name: 'CommHome',
        components: {
            CommAuth
        },
        created () {
            document.title = "Comm Tool";
        }
    }
</script>

<style scoped>

    h1{
        color: #ec931e;
    }

</style>