import { render, staticRenderFns } from "./UpdatePerf.vue?vue&type=template&id=440a5645&scoped=true&"
import script from "./UpdatePerf.vue?vue&type=script&lang=js&"
export * from "./UpdatePerf.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "440a5645",
  null
  
)

export default component.exports