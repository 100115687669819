var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('b-col',{attrs:{"sm":"2"}}),( (this.html_list.length===0))?_c('b-col',{attrs:{"sm":"8"}},[_vm._v(" Loading ... ")]):_vm._e(),((this.html_list.length>0))?_c('b-col',{attrs:{"sm":"8"}},_vm._l((this.html_list),function(item,index){return _c('li',{key:index,ref:"html_list",refInFor:true,staticStyle:{"max-width":"700px","margin-top":"40px"}},[(item.data.data[2])?_c('div',[_c('div',{staticClass:"observable"},[_c('editor',{attrs:{"api-key":"6amjl4rlxjw7zvlf91tg3iz4m9ntrvj0tk1543pxopouuq9k","branding":"false","init":{
                                    init_instance_callback: function (ed) {
                                        ed.on('click', function (e) {
                                          _vm.click_link(index, ed, e);
                                        });
                                        ed.on('tap', function (e) {
                                          _vm.click_tap(index, ed);
                                        });

                                     },
                                     menubar: false,
                                     plugins: ['autoresize'
                                     ],
                                     content_style: '.mce-content-body audio[data-mce-selected], .mce-content-body embed[data-mce-selected], .mce-content-body img[data-mce-selected], .mce-content-body object[data-mce-selected], .mce-content-body table[data-mce-selected], .mce-content-body video[data-mce-selected]{\n'+
                                        '    outline: none;\n'+
                                        '    outline-width: 0px;\n'+
                                        '}',
                                     toolbar:
                                       '',
                                     content_css: '../style/editor.css',
                                     autoresize_on_init: true,
                                     visual: false,
                                     autoresize_bottom_margin: 0,
                                     branding: false,
                                     statusbar: false,
                                   }},model:{value:(item.data.data[0]),callback:function ($$v) {_vm.$set(item.data.data, 0, $$v)},expression:"item.data.data[0]"}})],1)]):_vm._e()])}),0):_vm._e(),_c('b-col',{attrs:{"sm":"2"}},[_c('div',{staticClass:"sticky"},[_c('p',{staticStyle:{"color":"#aaaaaa"}},[_vm._v(_vm._s(this.timestamp))])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }