<template>
    <div></div>
</template>

<script>
    import {db} from "../firebaseConfig";

    export default {
        name: "UpdatePostPerf",
        components: {},
        methods: {


        },
        async beforeCreate() {
            async function update () {
                var date = new Date();
                var upper_time_bound = new Date(date.getTime() - (24 * 60 * 60 * 1000));
                var outer_promises = [];
                var demo = false;
                outer_promises.push(
                    db.collection("users").where("recipient_checked", "==", true)
                        .where("demo","==",demo).get().then(querySnapshot => {
                        var users = {};
                        var explicit_staff = 0;
                        var explicit_faculty = 0;
                        var implicit_staff = 0;
                        var implicit_faculty = 0;
                        var timestamp = date;
                        var open_rate_dict = {};
                        querySnapshot.docs.map((doc) => {
                            // console.log('LOG 1', doc.data());
                            if (demo){
                                if (doc.data().demo !== true) return;
                            }else{
                                if (doc.data().demo === true) return;
                            }
                            users[doc.data().userId] = doc.data().group;
                            if (doc.data().group === "explicit") {
                                if (doc.data().category === "staff") explicit_staff += 1;
                                else explicit_faculty += 1
                            }
                            else{
                                if (doc.data().category === "staff") implicit_staff += 1;
                                else implicit_faculty += 1
                            }
                        });

                        db.collection("post").where("timestamp", "<", upper_time_bound).get().then(querySnapshot => {
                            // var post_size = querySnapshot.docs.length;
                            if (querySnapshot.docs.length > 0) {

                                var proms = [];
                                querySnapshot.docs.map(doc => {
                                    // console.log('LOG 1', doc.data());
                                    var tmp = doc.data();
                                    var explicit = 0;
                                    var implicit = 0;
                                    if (tmp.audience.includes("staff")){
                                        explicit += explicit_staff;
                                        implicit += implicit_staff
                                    }
                                    if (tmp.audience.includes("faculty")){
                                        explicit += explicit_faculty;
                                        implicit += implicit_faculty;
                                    }
                                    if (demo){
                                        if (tmp.demo !== true) return;
                                    }else{
                                        if (tmp.demo === true) return;
                                    }
                                    tmp.postId = doc.id;
                                    tmp['sent_time'] = tmp['timestamp'];
                                    if (tmp.delete !== true) {
                                        tmp['# recipients'] = explicit+implicit;
                                        tmp['# opened'] = 0;
                                        tmp['# clicked'] = 0;
                                        tmp['# relevance'] = 0;
                                        tmp['# comments'] = 0;
                                        tmp['reading time'] = 0;
                                        tmp['size'] = parseInt(tmp['n_recipient']);
                                        tmp['reputation change**'] = 0;
                                        tmp['est cost*'] = 0;
                                        tmp['newsletter'] = "";
                                        tmp['read rate'] = 0;
                                        tmp['detail rate'] = 0;
                                        tmp['msg read rate'] = 0;
                                        tmp['msg detail rate'] = 0;
                                        tmp['comment rate'] = 0;
                                        tmp['commented'] = 0;

                                        tmp['timestamp'] = timestamp;
                                        var postId = doc.id;
                                        var newsletterId = tmp['newsletterId'];
                                        var collection = db.collection('newsletter');
                                        proms.push(collection.doc(newsletterId).get().then((document) => {
                                            var low_proms = [];
                                            if (document.exists) {
                                                tmp['newsletter'] = document.data().newsletter_brand;
                                            } else {
                                                tmp['newsletter'] = "";
                                            }

                                            low_proms.push(
                                                db.collection("read_post").where("postId", "==", postId).get().then(querySnapshot => {
                                                    if (querySnapshot.docs.length > 0) {
                                                        querySnapshot.docs.map(doc => {
                                                            // console.log('LOG 1', doc.data());
                                                            var row = doc.data();
                                                            if (users[row.userId] !== undefined) {
                                                                if (users[row.userId] === "explicit") {
                                                                    tmp['# comments'] += row.comments;
                                                                    tmp['commented'] += row.comments>0?1:0;
                                                                    tmp['# relevance'] += (row.relevant > 0);
                                                                } else {
                                                                    if (isNaN(row.time)) row.time = 0;
                                                                    tmp['reading time'] += Math.min(30*60, row.time);
                                                                    tmp['# opened'] += 1;
                                                                    tmp['# clicked'] += (row.clicked > 0);
                                                                    if (row.read_level !== undefined){
                                                                        tmp['read rate'] += row.read_level>0?1:0;
                                                                        tmp['detail rate'] += row.read_level>1?1:0;
                                                                    }
                                                                    if (row.read !== undefined){
                                                                        tmp['msg read rate'] += row.read>0?1:0;
                                                                    }
                                                                    if (row.detail !== undefined){
                                                                        tmp['msg detail rate'] += row.detail>0?1:0;
                                                                    }
                                                                }
                                                            }

                                                        });
                                                        if (implicit === 0) implicit = 1;
                                                        if (explicit === 0) explicit = 1;
                                                        tmp['open rate'] = tmp['# opened'] / implicit * 100;
                                                        tmp['click rate'] = tmp['# clicked'] / implicit * 100;
                                                        tmp['read rate'] /= implicit / 100;
                                                        tmp['detail rate'] /= implicit/ 100;
                                                        tmp['msg read rate'] /= implicit/ 100;
                                                        tmp['msg detail rate'] /= implicit/ 100;
                                                        tmp['comment rate'] = tmp['commented'] / explicit * 100;
                                                        tmp['relevance rate'] = tmp['# relevance'] / explicit * 100;
                                                        tmp['est cost*'] = 40 / 60.0 / 60.0 * tmp['reading time']/ implicit * parseInt(tmp['size']) ;
                                                        if (tmp['# opened'] > 0) tmp['reading time'] = tmp['reading time'] / tmp['# opened'];
                                                        else tmp['reading time'] = 0;

                                                    } else {
                                                        tmp['open rate'] = 0;
                                                        tmp['click rate'] = 0;
                                                        tmp['read rate'] =0;
                                                        tmp['detail rate'] =0;
                                                        tmp['msg read rate'] =0;
                                                        tmp['msg detail rate'] =0;
                                                        tmp['relevance rate'] = 0;
                                                        tmp['comment rate'] = 0;
                                                        tmp['est cost*'] = 0;
                                                        tmp['reading time'] = 0;
                                                    }
                                                    open_rate_dict[tmp['postId']] = tmp['open rate']/100;
                                                    tmp['list_size'] = parseInt(tmp['size']) / 1000.0;


                                                    return tmp;

                                                })
                                            )
                                            return Promise.all(low_proms);
                                        }));
                                    }
                                });

                                var items = Promise.all(proms);
                                var upload_promises = []
                                items.then(function (results) {
                                    results.forEach((tmp) => {
                                        tmp = tmp[0];
                                        delete tmp["email_html"];
                                        tmp["demo"] = demo;
                                        upload_promises.push(
                                            db.collection('post_perf_avg').add(tmp)
                                        )

                                    });
                                })

                                items = Promise.all(upload_promises);
                                items.then(function () {
                                    var inner_promises = [];
                                    inner_promises.push(
                                        db.collection("newsletter").get().then(
                                            querySnapshot => {
                                                var promises = [];

                                                querySnapshot.docs.map((document) => {
                                                    var newsletterId = document.id;
                                                    if (demo){
                                                        if (document.data().demo !== true) return;
                                                    }else{
                                                        if (document.data().demo === true) return;
                                                    }
                                                    var audience = document.data().audience;
                                                    promises.push(
                                                        db.collection("users").where("recipient_checked", "==", true)
                                                            .where("demo","==",demo).get().then(querySnapshot => {
                                                                var users = {}
                                                                var explicit = 0;
                                                                var implicit = 0;
                                                                querySnapshot.docs.map((doc) => {
                                                                    // console.log('LOG 1', doc.data());
                                                                    if (!audience.includes(doc.data().category)) return;
                                                                    users[doc.data().userId] = doc.data().group;
                                                                    if (doc.data().group === "explicit") explicit += 1;
                                                                    else implicit += 1;
                                                                });
                                                                var promises1 = [];
                                                                promises1.push(
                                                                    db.collection("messages").where("newsletterId", "==", newsletterId).where("timestamp", "<", upper_time_bound).get().then(querySnapshot => {
                                                                        var promises2 = [];
                                                                        if (querySnapshot.docs.length > 0) {
                                                                            querySnapshot.docs.map(doc => {
                                                                                // console.log('LOG 1', doc.data());
                                                                                var tmp = doc.data();
                                                                                if (tmp.delete !== true) {
                                                                                    if (tmp.data.data[1]) {
                                                                                        tmp['# recipients'] = Object.keys(users).length;
                                                                                        tmp['# clicked'] = 0;
                                                                                        tmp['# relevance'] = 0;
                                                                                        tmp['# comments'] = 0;
                                                                                        tmp['reading time'] = 0;
                                                                                        tmp['size'] = 0;
                                                                                        tmp['reputation change**'] = 0;
                                                                                        tmp['est cost*'] = 0;
                                                                                        tmp['subject'] = "";
                                                                                        tmp['read rate'] =0;
                                                                                        tmp['detail rate'] =0;
                                                                                        tmp['sent time'] = tmp['timestamp'];
                                                                                        tmp['timestamp'] = date;
                                                                                        var collection = db.collection('post');
                                                                                        promises2.push(
                                                                                            collection.doc(tmp["postId"]).get().then((document) => {

                                                                                                if (document.exists) {
                                                                                                    tmp['subject'] = document.data().subject;
                                                                                                    tmp['size'] = document.data()['n_recipient'];
                                                                                                } else {
                                                                                                    tmp['subject'] = "";
                                                                                                }
                                                                                                tmp['message'] = tmp.data.data[3];
                                                                                                var index = tmp.index;

                                                                                                var promises3 = [];
                                                                                                promises3.push(
                                                                                                    db.collection("read_message").where("postId", "==", tmp["postId"])
                                                                                                        .where("index", "==", index).get().then(querySnapshot => {

                                                                                                        if (querySnapshot.docs.length > 0) {
                                                                                                            querySnapshot.docs.map(doc => {
                                                                                                                // console.log('LOG 1', doc.data());
                                                                                                                var row = doc.data();
                                                                                                                if (users[row.userId] !== undefined) {
                                                                                                                    if (users[row.userId] === "explicit") {
                                                                                                                        tmp['# comments'] += row.comments.length;
                                                                                                                        tmp['# relevance'] += row.relevant;
                                                                                                                    } else {
                                                                                                                        if (isNaN(row.time)) row.time = 0;
                                                                                                                        tmp['reading time'] += Math.min(10*60, row.time);
                                                                                                                        tmp['# clicked'] += row.clicked;
                                                                                                                        if (row.read_level !== undefined){
                                                                                                                            tmp['read rate'] += row.read_level>0?1:0;
                                                                                                                            tmp['detail rate'] += row.read_level>1?1:0;
                                                                                                                        }
                                                                                                                    }
                                                                                                                }

                                                                                                            });
                                                                                                            if (implicit === 0) implicit = 1;
                                                                                                            if (explicit === 0) explicit = 1;
                                                                                                            tmp['click rate'] = tmp['# clicked'] / implicit *100;
                                                                                                            tmp['relevance rate'] = tmp['# relevance'] / explicit * 100;
                                                                                                            tmp['read rate'] /= implicit / 100;
                                                                                                            tmp['detail rate'] /= implicit / 100;
                                                                                                            if (implicit > 0 && tmp['reading time'] > 0.5) tmp['est cost*'] = 40 / 60.0 / 60 * (tmp['reading time']) * parseInt(tmp['size']) / implicit;
                                                                                                            else tmp['est cost*'] = 0;
                                                                                                            if (tmp['reading time']> 0) tmp['reading time'] = tmp['reading time'] / implicit / open_rate_dict[tmp["postId"]];
                                                                                                            else tmp['reading time'] = 0;

                                                                                                        } else {
                                                                                                            tmp['read rate'] =0;
                                                                                                            tmp['detail rate'] =0;
                                                                                                            tmp['click rate'] = 0;
                                                                                                            tmp['relevance rate'] = 0;
                                                                                                            tmp['est cost*'] = 0;
                                                                                                            tmp['reading time'] = 0;
                                                                                                        }
                                                                                                        tmp['list_size'] = parseInt(tmp['size'])/1000.0;
                                                                                                        delete tmp["data"];
                                                                                                        tmp["demo"] = demo;
                                                                                                        var promises4 = [];
                                                                                                        promises4.push(
                                                                                                            db.collection('message_perf_avg').add(tmp)
                                                                                                        )
                                                                                                        return Promise.all(promises4);


                                                                                                    })
                                                                                                )
                                                                                                return Promise.all(promises3);
                                                                                            })
                                                                                        )


                                                                                    }
                                                                                }
                                                                            });
                                                                            return Promise.all(promises2);
                                                                        }
                                                                    })
                                                                )
                                                                return Promise.all(promises1);
                                                            }
                                                        )
                                                    )
                                                })
                                                return Promise.all(promises);
                                            })
                                    )
                                    return Promise.all(inner_promises);
                                })
                            }


                        });

                    })
                )
                return Promise.all(outer_promises);

            }

            var func_promises = [];
            func_promises.push(update());
            var items = Promise.all(func_promises);
            items.then(function (results) {
                return results;
            })


        }
    }
</script>

<style scoped>

</style>