
<template>

  <div id="app">
    <!-- this is the new navigation bar. Added by Jia -->
    <div>
      <!-- Navigation bar type -->
      <b-navbar toggleable="sm" type="dark" class="navbar navbar-default navbar-fixed-top">
        <b-navbar-brand href="/">Image Tool</b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
<!--            <b-nav-item href="/dashboard" v-if="is_communicator">Dashboard</b-nav-item>-->
<!--            <b-nav-item href="/edit/new" v-if="is_communicator">Create Email</b-nav-item>-->
              <b-nav-item> Bug report: kong0135@umn.edu</b-nav-item>
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
<!--            <template v-if="!user">-->
<!--              <b-nav-item>-->
<!--                <a href @click.prevent="signInWithGoogle">Log In/Sign Up</a>-->
<!--              </b-nav-item>-->
<!--            </template>-->

            <template v-if="user">
              <b-nav-item-dropdown right>
                <template #button-content>
<!--                  <img :src="user.photoURL" alt="avatar"-->
<!--                    style=" width: 30px; height: 30px; border-radius: 25%; margin-right: 10px; " />-->
                  <label style="margin-bottom: 0rem !important">{{ user.displayName }}</label>
                </template>
                <b-dropdown-item @click.prevent="signOut">Sign Out</b-dropdown-item>
                <b-dropdown-item href="/about" target="_blank">About this Study</b-dropdown-item>
              </b-nav-item-dropdown>
            </template>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>

    <router-view />

<!--      <div >-->
<!--          <nav class="navbar navbar-light text-center" id = "help-bar">-->
<!--        <span class="navbar-text text-white" >-->
<!--          If you need help, please email Ruoyan Kong: kong0135@umn.edu-->
<!--        </span>-->
<!--          </nav>-->
<!--      </div>-->
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*background-image: url('https://images.pexels.com/photos/706484/pexels-photo-706484.png?');*/
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  min-height: 100%;
  width: 100%;
  min-height: 100vh;
}


.navbar {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
  position: relative;
  z-index: 100;
  background-color:#023E8A;
}

/*#help-bar {*/
/*    overflow: hidden;*/
/*    position: relative;*/
/*    width: 100%;*/
/*    bottom: 0;*/
/*    display: table;*/
/*    margin: 0 auto;*/
/*}*/
</style>

<script>

import { auth, provider } from "@/firebaseConfig";
import {db} from "./firebaseConfig.js"

export default {
  components: {},
  name: "Auth",
    props: ['redirect'],
  data() {
    return {
      user: null,
        is_communicator: false
    };
  },
  created () {
    document.title = "Comm Tool";
  },
  beforeCreate: function() {
    auth.onAuthStateChanged(user => {
      // console.log("user state:", user);
      // uncomment above to check out which user properties are available.
      if (user) {
        this.user = user;
        var collection = db.collection('users');
        var docID = this.user.uid;
        collection.doc(docID).get().then((document) => {
          if(document.exists){
            // this.$router.push("/dashboard")
            this.is_communicator = document.data().communicator_checked;
              // if (!document.data().communicator_checked) this.$router.push("/")
              // else {
              //     if (this.redirect !== undefined) this.$router.push(this.redirect);
              //     else this.$router.push("/dashboard")
              // }

          }
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });

      }
    });
  },
  methods: {
    signInWithGoogle: function () {
      console.log("here1");
      auth
        .signInWithRedirect(provider)
        .then(result => {
          this.user = result.user;
        })
    },
    signOut: function () {
      auth
        .signOut()
        .then(() => {
          this.user = null;
          this. $router. push('/')
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>