<template>
        <b-container style="max-width: 1000px;">
            <div class="row" style="margin-top:20px;">


                <b-col md="3">
                </b-col>
                <b-col md="6">
                    <b-form @submit="onSubmit"  v-if="show">
                <b-form-group
                        id="input-group-1"
                        label="Newsletter Brand Name:"
                        label-for="input-1"
                        description="Enter the BRAND name for this newsletter."
                >
                    <b-form-input
                            id="input-1"
                            v-model="form.email"
                            placeholder="e.g., U of M Brief"
                            required
                    ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-2" label="Size of Mailings List:" label-for="input-2"
                description="Enter the number of the recipients of this newsletter.">
                    <b-form-input
                            id="input-2"
                            v-model="form.n_recipient"
                            placeholder="e.g., 40000"
                            required
                    ></b-form-input>
                </b-form-group>

                <label>Select the audience of this newsletter</label>
                <b-form-radio-group
                        id="audience"
                        v-model="form.audience"
                        :options="audience_options"
                        name="radio-options"
                        required
                ></b-form-radio-group>


                <b-button type="submit" variant="primary" style="margin: 10px;">Create</b-button>
            </b-form>
                </b-col>
                <b-col md="3">
                </b-col>
            </div>
            </b-container>

</template>

<script>
    import { auth } from "@/firebaseConfig"
    import firebase from 'firebase'
    import {db} from "../firebaseConfig";
    export default {
        name: "CreateEmailTest",
        created () {
            document.title = "Comm Tool: Create Newsletter";
        },
        beforeCreate: function() {
            auth.onAuthStateChanged(user => {
                console.log("user state:", user);
                // uncomment above to check out which user properties are available.
                if (!user) {
                    //this.$router.push("/"); this have already been done in router
                }else{
                    this.user = user;
                    var collection = db.collection('users');
                    var docID = this.user.uid;
                    collection.doc(docID).get().then((document) => {
                        if(document.exists){
                            if (!document.data().communicator_checked) this.$router.push("/thank_login")

                            // this.$router.go();
                        }else{
                            this.$router.push("/register");
                            // this.$router.go();
                        }
                    })
                }
            });

        },
        data : function() {
            return {
                form: {
                    email: '',
                    n_recipient: 0,
                    audience: ''
                },
                audience_options: ["staff only", "faculty only", "staff and faculty"],
                show: true
            }
        },
        methods: {
            onSubmit(event) {
                let self=this;
                event.preventDefault()
                // alert(JSON.stringify(this.form))
                db.collection('newsletter').add({
                        userId:this.user.uid,
                        newsletter_brand:this.form.email,
                        n_recipient:this.form.n_recipient,
                        audience: this.form.audience,
                        timestamp: new Date()
                    }
                ).then(function(docRef) {
                    self.$router.push('/edit/'+docRef.id);
                    }

                )

            },
        },

        firestore: function () {
            if (!firebase.auth().currentUser){
                return {}
            }else{
                return {
                    // mylists: db.collection("lists").where("shared_with", "array-contains", firebase.auth().currentUser.email).orderBy("created_at", "desc"),
                }
            }
        },

    }

</script>

<style scoped>

</style>

