<template>
    <div class="home">
        <h1 style="margin-top: 10%"> Thank you for login! </h1>
        <span><br><br>We will send test emails to your email inbox during the study (as a participant you won't need to login to comm-tool during the study). Please email Ruoyan at kong0135@umn.edu if you have any questions.
        </span>
        <span>
        <br><br>Grouplens Lab,
            </span>
        <span>
        <br><br>University of Minnesota
            </span>
    </div>
</template>

<script>
    export default {
        name: "ThankLogin"
    }
</script>

<style scoped>

</style>