<template>
    <b-container style="max-width: 90%;" fluid id="all">
        <div  >
            <h1 style="margin-top: 10px">  Dashboard -- {{this.newsletter_brand}}'s Messages' Performance </h1>
            <div class="row" style="margin-top:10px"  v-if="generating_report !== 'downloading'">
                <b-col md="12" style="margin-bottom: 10px">
                    <b-button pill variant="primary" size="lg" @click="onCreateEmail">Create an Email</b-button>
                </b-col>
            </div>

            <b-row v-if="generating_report !== 'downloading'">
                <b-col lg="3"  class="my-1 row "><b-button variant="outline-secondary" size="sm" @click="clear_sort">Clear Sort X</b-button></b-col>
                <b-col lg="6" class="my-1" style="margin-bottom: 10px">
                    <b-form-group
                            label="Filter Newsletter / Subject"
                            label-for="filter-input"
                            label-cols-sm="3"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-0"
                    >
                        <b-input-group size="sm">
                            <b-form-input
                                    id="filter-input"
                                    v-model="TestfilterV"
                                    type="search"
                                    placeholder="Type Any Key Words"
                            ></b-form-input>

                            <b-input-group-append>
                                <b-button :disabled="!TestfilterV" @click="TestfilterV = null">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col lg="3" class="my-1 text-right" ><b-link @click="share"><font-awesome-icon icon="fa-solid fa-share"/> Share this Page</b-link></b-col>
            </b-row>

            <div v-if="generating_report === 'begin'">
                <h4>Notes</h4>
                <div style="max-width: 70%;    display: table;margin-right: auto; margin-left: auto;">
                    <editor v-model="share_text"
                            api-key="6amjl4rlxjw7zvlf91tg3iz4m9ntrvj0tk1543pxopouuq9k"
                            placeholder = "Please input any notes you want to share"
                            :init="{
                             menubar: false,
                             content_style: 'img {max-width: 600px; }' + 'div {max-width: 600px}'+ 'td {max-width: 600px}'+ 'tr {max-width: 600px}'+ 'tbody {max-width: 600px}' +  'table {max-width: 600px}',
                             plugins: [
                                 // 'autoresize',
                               'advlist autolink lists link image charmap print preview anchor',
                               'searchreplace visualblocks code fullscreen textcolor',
                               'insertdatetime media table paste code help wordcount'
                             ],
                             toolbar:
                               'undo redo | formatselect | bold italic forecolor backcolor | \
                               alignleft aligncenter alignright alignjustify | \
                               bullist numlist outdent indent | removeformat | help',
                               // autoresize_on_init: true,
                           }"
                    />
                </div>
                <div>
                    <b-button variant="outline-secondary" style="margin: 10px" @click="close">Close</b-button>
                    <b-button variant="primary" @click="download">Download</b-button>
                </div>
            </div>

            <div v-if="generating_report === 'downloading'">
                <h4 style="max-width: 70%;    display: table;margin-right: auto; margin-left: auto;">Notes</h4>
                <div v-html="share_text"  style="max-width: 70%;    display: table;margin-right: auto; margin-left: auto;"></div>
            </div>
            <!-- Main table element -->
            <div class="row" style="display: table;margin-right: auto; margin-left: auto;" >
                <b-table
                        :sticky-header="sticky_header"
                        striped hover
                        :items="messages"
                        :fields="fields"
                        :current-page="currenttestPage"
                        :filter="Testfilter"
                        :filter-included-fields="TestfilterOn"
                        :sort-by.sync="sorttestBy"
                        :sort-desc.sync="sorttestDesc"
                        :sort-direction="sorttestDirection"
                        @filtered="onTestFiltered"
                        small
                >
                    <!--            striped hover-->

                    <template #cell(subject)="row">
                        <!--                <b-button size="sm" @click="info(row.item, row.index, $event.target)" class="mr-1">-->

                        <b-button size="sm" @click="view_report(row.item)" class="mr-1" v-if="row.item.message !== undefined && row.item.postId !== ''">
                            + {{row.item.subject}}
                        </b-button>
                        <p v-if="row.item.latest === true&&row.item.message !== undefined && row.item.postId !== ''"><b class="text-primary"> NEW!</b></p>
                        <div v-if="row.item.subject === 'Avg Performance of All Communicators'"><b class="text-primary">{{row.item.subject}}</b></div>

                    </template>


                    <template #cell(date)="row">
                        <!--                <b-button size="sm" @click="info(row.item, row.index, $event.target)" class="mr-1">-->


                        <div v-if="(row.item.message !== undefined && row.item.postId !== '') || row.item.subject === 'Avg Performance of All Communicators'">{{row.item.date}}</div>

                    </template>

                    <template #head(subject)="data">
                <span v-b-tooltip.hover.righttop="'The email subject'">
                    {{ data.label}}
                    <font-awesome-icon
                            icon="fa-solid fa-question-circle"/>
                </span>
                    </template>
                    <template #head(message)="data">
                <span v-b-tooltip.hover.righttop="'The title of a message in that email'">
                    {{ data.label}}
                    <font-awesome-icon
                            icon="fa-solid fa-question-circle"/>
                </span>
                    </template>
                    <template #head(click_rate)="data">
                <span v-b-tooltip.hover.righttop="'The percentage of the implicit participants who at least clicked one link in this message.'">
                    {{ data.label}}
                    <font-awesome-icon
                            icon="fa-solid fa-question-circle"/>
                </span>
                    </template>
                    <template #head(read_rate)="data">
                <span v-b-tooltip.hover.righttop="'The percentage of the implicit participants who skimmed this message or read it in detail.'">
                    {{ data.label}}
                    <font-awesome-icon
                            icon="fa-solid fa-question-circle"/>
                </span>
                    </template>
                    <template #head(detail_rate)="data">
                <span v-b-tooltip.hover.righttop="'The percentage of the implicit participants who read this message in detail.'">
                    {{ data.label}}
                    <font-awesome-icon
                            icon="fa-solid fa-question-circle"/>
                </span>
                    </template>
                    <template #head(relevance_rate)="data">
                <span v-b-tooltip.hover.righttop="'The percentage of the explicit participants who indicated that this message is relevant to them.'">
                    {{ data.label}}
                    <font-awesome-icon
                            icon="fa-solid fa-question-circle"/>
                </span>
                    </template>
                    <template #head(n_comments)="data">
                <span v-b-tooltip.hover.righttop="'The number of comments the explicit participants left under this message.'">
                    {{ data.label}}
                    <font-awesome-icon
                            icon="fa-solid fa-question-circle"/>
                </span>
                    </template>
                    <template #head(reading_time)="data">
                <span v-b-tooltip.hover.righttop="'The average number of seconds the implicit participants who opened this email spent on reading this message.'">
                    {{ data.label}}
                    <font-awesome-icon
                            icon="fa-solid fa-question-circle"/>
                </span>
                    </template>
                    <template #head(est_cost)="data">
                <span v-b-tooltip.hover.righttop="'The estimated money cost of this message, defined as the average reading time * open rate * $40 hour rate * the actual number of recipients of this newsletter.'">
                    {{ data.label}}
                    <font-awesome-icon
                            icon="fa-solid fa-question-circle"/>
                </span>
                    </template>
                    <template #head(date)="data">
                <span v-b-tooltip.hover.righttop="'The date you sent this email on CommTool. For the first row (avg performance of all communicators), it refers to when the system updated the numbers of that row (we update daily).'">
                    {{ data.label}}
                    <font-awesome-icon
                            icon="fa-solid fa-question-circle"/>
                </span>
                    </template>

                </b-table>
            </div>
        </div>

        <div class="row" v-if="generating_report !== 'downloading'">
            <b-col sm="2" >
            </b-col>
            <b-col sm="8" >
                <h3 style="position: center">{{submit_msg}}</h3>
                <div class="row" style="margin-top:20px;" v-if="submit_msg==='Please take this survey each time:'">
                    <form @submit.prevent="handleSubmit">
                        <label>Q1. Which of these metrics we show on this page help you make future editing / sending decisions? <span style="color: #ff0000"><strong>* </strong></span></label>
                        <b-form-checkbox-group
                                id="metrics"
                                v-model="metrics"
                                name="radio-options3"
                                :state="state"
                        >

                            <b-form-checkbox
                                    v-for="metric_option in metric_options"
                                    :value="metric_option"
                                    :key="metric_option"
                            >
                                {{ metric_option }}
                            </b-form-checkbox>
                            <b-form-invalid-feedback :state="state">Please select at least one option.</b-form-invalid-feedback>
                        </b-form-checkbox-group>

                        <label style="margin-top: 20px">Q2. Which editing/sending decisions you have made / will make based on this report? <span style="color: red"><strong>* </strong></span></label>
                        <b-form-checkbox-group
                                id="decision"
                                v-model="decisions"
                                name="radio-options3"
                                :state="decision_state"
                        >

                            <b-form-checkbox
                                    v-for="decision_option in decision_options"
                                    :value="decision_option"
                                    :key="decision_option"
                            >
                                {{ decision_option }}
                            </b-form-checkbox>
                            <b-form-invalid-feedback :state="decision_state">Please select at least one option.</b-form-invalid-feedback>
                        </b-form-checkbox-group>

                        <label style="margin-top: 20px">Q3. Why certain metrics are helpful / unhelpful to you? Could you give an example of the editing / sending decisions you made based on this report?</label>
                        <b-form-textarea
                                id="textarea"
                                v-model="feedback"
                                placeholder="Enter here..."
                                rows="3"
                                max-rows="6"
                        ></b-form-textarea>

                        <div class="button">
                            <button class="submit" type="submit">submit</button>
                        </div>
                    </form>
                </div>
            </b-col>
        </div>
    </b-container>
</template>

<script>
    import {auth} from "@/firebaseConfig"
    import firebase from 'firebase'
    import {db} from "../firebaseConfig";
    import html2pdf from "html2pdf.js";
    import dateFormat from "dateformat";
    import Editor from '@tinymce/tinymce-vue'

    export default {
        name: "EmailAdmin",
        components: {
            // apexchart: VueApexCharts,
            Editor
        },
        props: ['newsletterId'],
        async created () {
            document.title = "Comm Tool: Message Dashboard";
            this.messages = [];
            var open_rate_dict = {};
            db.collection("perf_avg").where("type", "==", "message").orderBy("timestamp", "desc").limit(1).get().then(
                querySnapshot => {
                    querySnapshot.docs.map((doc) => {
                        var tmp = doc.data();
                        tmp['subject'] = 'Avg Performance of All Communicators'
                        tmp['click_rate'] = Math.round(tmp['click rate']).toString() + "%";
                        tmp['read_rate'] = Math.round(tmp['read rate']).toString() + "%";
                        tmp['detail_rate'] = Math.round(tmp['detail rate']).toString() + "%";
                        tmp['relevance_rate'] = Math.round(tmp['relevance rate']).toString() + "%";
                        tmp['est_cost'] = "$" + Math.round(tmp['est cost*']);
                        tmp['reading_time'] = Math.round(tmp['reading time']).toString() + "s";
                        tmp['n_comments'] = Math.round(tmp['# comments']).toString();
                        tmp['postId'] = '';
                        tmp['real_post_id'] = '';
                        tmp['date'] = dateFormat(new Date(tmp['timestamp']['seconds']*1000), "yyyy-mm-dd");
                        tmp['_rowVariant'] = 'secondary';
                        this.messages.push(tmp);
                    })
                    var seen_posts = new Set();
                    db.collection("newsletter").doc(this.newsletterId).get().then((document) => {

                        if (document.exists) {
                            this.newsletter_brand = document.data().newsletter_brand;
                        }
                        var audience = document.data().audience;
                        db.collection("users").where("recipient_checked", "==", true)
                            .where("demo","==",(this.$store.state.demo)).get().then(querySnapshot => {
                                this.users = {}
                                var explicit = 0;
                                var implicit = 0;

                                querySnapshot.docs.map((doc) => {
                                    // console.log('LOG 1', doc.data());
                                    if (!audience.includes(doc.data().category)) return;
                                    this.users[doc.data().userId] = doc.data().group;
                                    if (doc.data().group === "explicit") explicit += 1;
                                    else implicit += 1;
                                });
                                if (explicit === 0) explicit = 1;
                                if (implicit === 0) implicit = 1;
                                db.collection("messages").where("newsletterId", "==", this.newsletterId).orderBy('timestamp','desc').orderBy('index').get().then(querySnapshot => {
                                    if (querySnapshot.docs.length > 0) {
                                        var variant = false;
                                        querySnapshot.docs.map(doc => {
                                            // console.log('LOG 1', doc.data());
                                            var tmp = doc.data();

                                            if (tmp.data.data[1]) {
                                                tmp['# recipients'] = Object.keys(this.users).length;
                                                tmp['# clicked'] = 0;
                                                tmp['# relevance'] = 0;
                                                tmp['n_comments'] = 0;
                                                tmp['reading_time'] = 0;
                                                tmp['size'] = 0;
                                                tmp['reputation_change'] = 0;
                                                tmp['est_cost'] = 0;
                                                tmp['subject'] = ""
                                                tmp['read_rate'] = 0;
                                                tmp['detail_rate'] = 0;
                                                var promises = [];
                                                if (open_rate_dict[tmp["postId"]] === undefined) {
                                                    var open_rate = 0;

                                                    promises.push(db.collection("read_post").where("postId", "==", tmp["postId"]).get().then(querySnapshot => {

                                                            if (querySnapshot.docs.length > 0) {
                                                                querySnapshot.docs.map(doc => {
                                                                    // console.log('LOG 1', doc.data());
                                                                    var row = doc.data();
                                                                    if (this.users[row.userId] !== undefined) {
                                                                        if (this.users[row.userId] === "implicit") {
                                                                            open_rate += 1;
                                                                        }

                                                                    }

                                                                });
                                                                open_rate = open_rate / implicit;
                                                                open_rate_dict[tmp["postId"]] = open_rate;

                                                                return open_rate
                                                            }

                                                        })
                                                    )
                                                }
                                                var items = Promise.all(promises);
                                                var that = this;
                                                items.then(function () {

                                                    var collection = db.collection('post');
                                                    var mid_promises = [];
                                                    mid_promises.push(
                                                        collection.doc(tmp["postId"]).get().then((document) => {

                                                            if (document.exists) {
                                                                tmp['subject'] = document.data().subject;
                                                                tmp['size'] = parseInt(document.data()['n_recipient']);
                                                            } else {
                                                                tmp['subject'] = "";
                                                            }
                                                            if (document.data().delete!== true) {
                                                                tmp['message'] = tmp.data.data[3];
                                                                var index = tmp.index;

                                                                var inner_promises = [];
                                                                inner_promises.push(
                                                                    db.collection("read_message").where("postId", "==", tmp["postId"])
                                                                        .where("index", "==", index).get().then(querySnapshot => {

                                                                        if (querySnapshot.docs.length > 0) {
                                                                            querySnapshot.docs.map(doc => {
                                                                                // console.log('LOG 1', doc.data());
                                                                                var row = doc.data();
                                                                                if (that.users[row.userId] !== undefined) {
                                                                                    if (that.users[row.userId] === "explicit") {
                                                                                        tmp['n_comments'] += row.comments.length;
                                                                                        tmp['# relevance'] += row.relevant;
                                                                                    } else {
                                                                                        if (isNaN(row.time)) row.time =0;
                                                                                        tmp['reading_time'] += Math.min(row.time, 10*60);
                                                                                        tmp['# clicked'] += row.clicked;
                                                                                        if (row.read_level !== undefined){
                                                                                            tmp['read_rate'] += row.read_level>0?1:0;
                                                                                            tmp['detail_rate'] += row.read_level>1?1:0;
                                                                                        }
                                                                                    }
                                                                                }

                                                                            });
                                                                            tmp['click_rate'] = Math.round(tmp['# clicked'] / implicit*100).toString() + "%";
                                                                            tmp['read_rate'] = Math.round(tmp['read_rate'] / implicit*100).toString() + "%";
                                                                            tmp['detail_rate'] = Math.round(tmp['detail_rate'] / implicit*100).toString() + "%";
                                                                            tmp['relevance_rate'] = Math.round(tmp['# relevance'] / explicit * 100).toString() + "%";
                                                                            if (implicit > 0 && tmp['reading_time'] > 0.5) tmp['est_cost'] = "$" + Math.round(40 / 60.0 / 60 * (tmp['reading_time']) * parseInt(tmp['size']) / implicit);
                                                                            else tmp['est_cost'] = "$0"
                                                                            if (tmp['reading_time']> 0) tmp['reading_time'] = Math.round(tmp['reading_time'] / implicit / open_rate_dict[tmp["postId"]]).toString() + "s";
                                                                            else tmp['reading_time'] = "0s";
                                                                        } else {
                                                                            tmp['click_rate'] = "0%"
                                                                            tmp['relevance_rate'] = "0%"
                                                                            tmp['read_rate'] = "0%"
                                                                            tmp['detail_rate'] = "0%"
                                                                            tmp['est_cost'] = "$0";
                                                                            tmp['reading_time'] = "0s";
                                                                        }
                                                                        var date = dateFormat(new Date(tmp['timestamp']['seconds']*1000), "yyyy-mm-dd");
                                                                        tmp['real_post_id'] = tmp['postId'];

                                                                        if (seen_posts.has(tmp['postId'])){
                                                                            tmp['postId'] = '';
                                                                            tmp['date'] = date;
                                                                        }else{
                                                                            tmp['date'] = date;
                                                                            seen_posts.add(tmp['postId']);
                                                                            variant = !variant;
                                                                        }
                                                                        tmp['_rowVariant'] = variant?'light':'secondary';
                                                                        tmp['real_rowVariant'] = tmp['_rowVariant'];
                                                                        tmp['latest'] = seen_posts.size === 1;
                                                                        that.messages.push(tmp);
                                                                    })
                                                                )
                                                                var items = Promise.all(inner_promises);
                                                                items.then();
                                                            }
                                                        })
                                                    )
                                                    var items = Promise.all(mid_promises);
                                                    items.then();
                                                })

                                            }
                                        });
                                    }
                                })
                            }
                        )
                    })
                }
            )
        },
        beforeCreate: function() {
            auth.onAuthStateChanged(user => {
                console.log("user state:", user);
                // uncomment above to check out which user properties are available.
                if (!user) {
                    //this.$router.push("/"); this have already been done in router
                }else{
                    this.user = user;
                    var collection = db.collection('users');
                    var docID = this.user.uid;
                    collection.doc(docID).get().then((document) => {
                        if(document.exists){
                            if (!document.data().communicator_checked) this.$router.push("/thank_login")

                            // this.$router.go();
                        }else{
                            this.$router.push("/register");
                            // this.$router.go();
                        }
                    })

                }
            });

        },
        data : function() {
            return {
                text: "",
                messages: [],
                fields: [
                    { key: 'subject', class: 'text-center', sortable:true,thStyle: { width: "15%" }  },
                    { key: 'message', class: 'text-center', thStyle: { width: "20%" }   },
                    { key: 'click_rate', class: 'text-center', sortable:true },
                    { key: 'read_rate', class: 'text-center', sortable:true },
                    { key: 'detail_rate', class: 'text-center', sortable:true },
                    { key: "relevance_rate",class: 'text-center', sortable:true },
                    { key: "n_comments",class: 'text-center', sortable:true, label:'# comments' },
                    { key: "reading_time", class: 'text-center' , sortable:true },
                    { key: "est_cost", class: 'text-center' , sortable:true },
                    { key: "date", class: 'text-center' , sortable:true },
                    // { key: "reputation change**", class: 'text-center', sortable:true }
                ],
                totaltestRows: 1,
                currenttestPage: 1,
                sorttestBy: '',
                sorttestDesc: false,
                sorttestDirection: 'asc',
                Testgroup: null,
                TestgroupBy: [],
                TestfilterV: null,
                Testfilter: '',
                TestfilterOn: [],
                newsletter_brand: '',
                generating_report: '',
                share_text: '',
                time: 0,
                docid: "",
                metric_options: ["Average performance of all communicators", "Click Rate", "Read Rate", "Detail Rate","Relevance Rate", "# Comments", "Reading Time", "Est Cost",  "None of those are helpful."],
                decision_options: ["Send to more people", "Send to fewer people", "Share this report with my clients", "Add more contents", "Remove or shorten some contents", "Move some contents to other newsletter","None of those"],
                feedback: '',
                metrics: [],
                decisions: [],
                submit_msg: "Please take this survey each time:",
                sticky_header: "550px",
            }


        },

        computed: {
            sorttestOptions() {
                // Create an options list from our fields
                return this.test_fields
                    .filter(f => f.sortable)
                    .map(f => {
                        return { text: f.label, value: f.key }
                    })
            },
            state() {
                return this.metrics.length>=1
            },
            decision_state(){
                return this.decisions.length>=1
            }
        },

        mounted() {
            // Set the initial number of items
            this.totaltestRows = this.messages.length;
            window.setInterval(() => {
                this.updateTime();
            }, 1000);
        },

        firestore: function () {
            if (!firebase.auth().currentUser){
                return {}
            }else{
                return {
                    // mylists: db.collection("lists").where("shared_with", "array-contains", firebase.auth().currentUser.email).orderBy("created_at", "desc"),
                }
            }



        },

        methods:{
            handleSubmit() {
                if (this.decisions.length === 0 || this.metrics.length === 0) return;
                var data = [];
                this.messages.forEach((row)=>{
                        var tmp = {};
                        Object.keys(row).forEach(function(key) {
                            if (key!=="data"){
                                tmp[key] = row[key]
                            }
                        });
                        data.push(tmp);
                    }
                )
            },
            updateTime(){
                this.time += 1;
                if (this.time === 30){
                    var data = [];
                    this.messages.forEach((row)=>{
                            var tmp = {};
                            Object.keys(row).forEach(function(key) {
                                if (key!=="data"){
                                    tmp[key] = row[key]
                                }
                            });
                            data.push(tmp);
                        }
                    )
                }
            },
            close () {
                this.generating_report = '';
            },
            download () {
                this.generateReport()
            },
            generateReport () {
                this.sticky_header = false;
                this.generating_report = 'downloading';
                const me = this;
                me.dom = "#all"
                me.name = this.newsletter_brand+".pdf"

                const invoice = document.querySelector(me.dom);

                var opt = {
                    margin: 1,
                    filename: me.name,
                    html2canvas: {width: window.innerWidth/2}
                };
                html2pdf().from(invoice).set(opt).save().then(()=>{this.sticky_header = "550px"; this.generating_report = '';});
                var data = [];
                this.messages.forEach((row)=>{
                        var tmp = {};
                        Object.keys(row).forEach(function(key) {
                            if (key!=="data"){
                                tmp[key] = row[key]
                            }
                        });
                        data.push(tmp);
                    }
                )
            },
            updategroup: function(){
                var tmp = [];
                for (var group of this.TestgroupBy) tmp.push({key: group, label: group, sortable: true});
                tmp.push.apply(tmp, this.origin_test_fields);
                this.test_fields = tmp;

            },
            view_report(item){
                this.$router.push('/report_admin/'+this.user.uid+'/'+item.postId);
            },
            clear_sort(){
                this.sorttestBy = '';
                this.sorttestDesc = false;
            },
            // info(item, index, button) {
            //     this.infoModal.title = `Row index: ${index}`
            //     this.infoModal.content = JSON.stringify(item, null, 2)
            //     this.$root.$emit('bv::show::modal', this.infoModal.id, button)
            // },
            // resetInfoModal() {
            //     this.infoModal.title = ''
            //     this.infoModal.content = ''
            // },
            onTestFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                // if (filteredItems.length>0 && filteredItems[0].postId !== ''){
                //     if (this.messages.length>0){
                //         filteredItems.unshift(this.messages[0]);
                //         this.$emit('filtered', filteredItems)
                //     }
                // }

                this.totaltestRows = filteredItems.length

                this.currenttestPage = 1
            },
            onSubmit(){

            },
            onCreateEmail(){
                this.$router.push('/edit/new');
            },
            share(){
                this.generating_report = 'begin';
            },


        },
        watch: {
            sorttestBy(newSortBy, old) {
                console.log(old)
                if (newSortBy !== '' && newSortBy !== 'subject' && newSortBy !== 'date') {
                    this.messages.forEach((value) => {
                        value.postId = value.real_post_id;
                        value['_rowVariant'] = '';
                    });
                }else{
                    var last_post_id = '1';
                    if (!this.sorttestDesc) {
                        this.messages.forEach((value) => {
                            if (value.real_post_id !== last_post_id) {
                                value.postId = value.real_post_id;
                            } else {
                                value.postId = '';
                            }
                            value['_rowVariant'] = value['real_rowVariant'];
                            last_post_id = value.real_post_id;
                        });
                    }else{
                        var n = this.messages.length;
                        if (n>0){
                            this.messages[0].postId = this.messages[0].real_post_id;
                            this.messages[0]['_rowVariant'] = this.messages[0]['real_rowVariant'];
                        }
                        for (let i=1; i<n-1;i++){
                            this.messages[i]['_rowVariant'] = this.messages[i]['real_rowVariant'];
                            if (this.messages[i].real_post_id !== this.messages[i+1].real_post_id){
                                this.messages[i].postId = this.messages[i].real_post_id;
                            }else{
                                this.messages[i].postId = '';
                            }
                        }
                    }
                }
            },
            TestfilterV(newvalue) {
                if (!newvalue) this.Testfilter = null;
                else{
                    this.Testfilter = new RegExp("Avg Performance|" + newvalue, "i");
                }
            },
            sorttestDesc(newsorttestDesc, old){
                console.log(old)
                var last_post_id = '1';
                var n, value;
                if (this.sorttestBy !== '' && this.sorttestBy !== 'subject' && this.sorttestBy !== 'date') {
                    this.messages.forEach((value) => {
                        value.postId = value.real_post_id;
                        value['_rowVariant'] = '';
                    });
                }else{
                    if (!old) {

                        if (newsorttestDesc) {
                            this.messages.forEach((value) => {
                                if (value.real_post_id !== last_post_id) {
                                    value.postId = value.real_post_id;
                                } else {
                                    value.postId = '';
                                }
                                value['_rowVariant'] = value['real_rowVariant']
                                last_post_id = value.real_post_id;
                            });
                        } else {
                            n = this.messages.length;
                            for (let i = n - 1; i >= 0; i--) {
                                value = this.messages[i];
                                if (value.real_post_id !== last_post_id) {
                                    value.postId = value.real_post_id;
                                } else {
                                    value.postId = '';
                                }
                                last_post_id = value.real_post_id;
                                value['_rowVariant'] = value['real_rowVariant']
                            }
                        }
                    }else{
                        if (!newsorttestDesc) {
                            this.messages.forEach((value) => {
                                if (value.real_post_id !== last_post_id) {
                                    value.postId = value.real_post_id;
                                } else {
                                    value.postId = '';
                                }
                                last_post_id = value.real_post_id;
                                value['_rowVariant'] = value['real_rowVariant']
                            });
                        } else {
                            n = this.messages.length;
                            for (let i = n - 1; i >= 0; i--) {
                                value = this.messages[i];
                                if (value.real_post_id !== last_post_id) {
                                    value.postId = value.real_post_id;
                                } else {
                                    value.postId = '';
                                }
                                last_post_id = value.real_post_id;
                                value['_rowVariant'] = value['real_rowVariant']
                            }
                        }
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>