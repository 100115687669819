<template>
    <div class="home">
        <h1 style="margin-top: 10%"> Thank you for registering! </h1>
        <span><br><br>You will receive an email from us within a week if you are selected to participate in this study. Please email Ruoyan at kong0135@umn.edu if you have any questions.
        </span>
        <span>
        <br><br>Grouplens Lab,
            </span>
        <span>
        <br><br>University of Minnesota
            </span>
    </div>
</template>

<script>
    export default {
        name: "Thank"
    }
</script>

<style scoped>

</style>