<style scoped>

    form {
        max-width: 600px;
        margin: 30px auto;
        background: #fff;
        text-align: left;
        padding: 20px;
        border-radius: 10px;
    }

    label {
        color: #aaa;
        display:inline-block;
        margin: 25px 0 15px;
        text-transform: uppercase;
    }

    input, select {
        display: block;
        padding: 10px 6px;
        width: 100%;
        box-sizing: border-box;
        border: none;
        border-bottom: 1px solid #ddd;
        color: #555;
    }

    input[type="checkbox"] {
        display: inline-block;
        width:16px;
        margin: 0 10px 0;
        position: relative;
        top: 2px;
    }

    .pill {
        display: inline-block;
        margin: 20px 10px 0 0 ;
        padding: 6px 12px;
        border-radius: 20px;
        font-size: 12px;
        cursor: pointer;
        background: #eee;
    }

    button {
        background: rgb(7, 24, 7);
        border: 0;
        padding: 10px 20px;
        color: white;
        border-radius: 20px;
    }

    .submit {
        text-align: center;
    }

    .error {
        color: #ff0000;
        margin-top: 10px;
        font-size: 0.8em;
        font-weight: bold;
    }
</style>
<template>
    <b-container style="max-width: 1000px;">
        <div class="row" style="margin-top:20px;">
            <form @submit.prevent="handleSubmit">
                <label>Campus : <span style="color: red"><strong>* </strong></span></label>
                <select v-model="campus" required>
                    <option value="Crookston">Crookston</option>
                    <option value="Duluth">Duluth</option>
                    <option value="Morris">Morris</option>
                    <option value="Rochester">Rochester</option>
                    <option value="Twin Cities">Twin Cities</option>
                    <option value="System-Wide">System-Wide Offices (e.g., OVPR, OIT, Libraries)</option>
                </select>


                <label>Your Unit: <span style="color: red"><strong>* </strong></span></label>

                <b-form-input
                        id="input-1"
                         required
                        v-model="unit"
                        placeholder="e.g., college, department, office name"
                ></b-form-input>



                <div>
                    <input type="checkbox" v-model="terms" required>
                    <label>Please read the <a href="https://docs.google.com/document/d/10TWhhnSVD0CRxy4w3eI58exnGN5jZ6hs/edit?usp=sharing&ouid=116961651394772297273&rtpof=true&sd=true" target="_blank">consent form</a> and check here if you accept. <span style="color: red"><strong>* </strong></span></label>
                </div>
                <div class="button">
                    <button class="submit" type="submit">submit</button>
                </div>
            </form>
        </div>

    </b-container>
</template>

<script>
    import { db } from "../firebaseConfig.js"
    import { auth } from "@/firebaseConfig"
    // import firebase from 'firebase'
    export default {
        name: "CommunicatorRegister",
        components: {
        },
        created () {
            document.title = "Comm Tool: Communicator Registration";


        },
        beforeCreate: function() {
            auth.onAuthStateChanged(user => {
                console.log("user state:", user);
                // uncomment above to check out which user properties are available.
                if (!user) {
                    //this.$router.push("/"); this have already been done in router
                }else{
                    this.user = user;
                    // this.todos = this.$firestoreRefs['todos'].orderBy("timestamp", "desc").where("done", "==", false).where("userId", "==", this.user);

                }
            });


        },
        computed: {
            state() {
                return this.interest.length >=1
            }
        },

        data : function() {
            return {
                campus: '',
                unit: '',

                communicator: true,
                terms: false
            }
        },

        // firestore: function () {
        //     // if (!firebase.auth().currentUser){
        //     //     return {}
        //     // }else{
        //     //     return {
        //     //         // mylists: db.collection("lists").where("shared_with", "array-contains", firebase.auth().currentUser.email).orderBy("created_at", "desc"),
        //     //     }
        //     // }
        //
        //
        //
        // },

        methods:{
            handleSubmit() {
                db.collection("users").doc(this.user.uid).set({
                    displayName: this.user.displayName,
                    email: this.user.email,
                    campus: this.campus,
                    unit: this.unit,
                    communicator: this.communicator,
                    communicator_checked: false,
                    recipient_checked: false,
                    userId: this.user.uid,
                    timestamp: new Date(),
                    demo: this.$store.state.demo
                })
                    .then(() => {
                        console.log("Document successfully written!");
                        this.$router.push("/thank");
                        this.$router.go();
                    })
                    .catch((error) => {
                        console.error("Error writing document: ", error);
                    });

                // console.log(this.education);
                // console.log(this.terms);


            }
        },
    }
</script>
